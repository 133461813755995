import { Exception, LoadingStatusType } from "@/enums/enums";
import { CreateLogForRecordLotCostOfGoodsSoldRequest } from "@/model/activitylog/createLogForRecordLotCostOfGoodsSoldRequest";
import { CreateLogForUpdateProductTemplateForRecalculateCostofGoodsSoldByMerchantIdRequest } from "@/model/activitylog/createLogForUpdateProductTemplateForRecalculateCostofGoodsSoldByMerchantIdRequest";
import { validateProductStockList } from "@/model/product/validateProductStockDetailModel";
import API from "@/service/Index";
import { ConvertToValidateProductStockModelList } from "@/service/validateProductStock/validateProductStockFacade";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({ namespaced: true })
export default class ActivityLog extends VuexModule {
  public merchantDetailLoadingStatus: LoadingStatusType = LoadingStatusType.None;
  public merchantDetailList: Array<validateProductStockList> | null = null;
  public recordLotCostOfGoodsSoldLoadingStatus: LoadingStatusType = LoadingStatusType.None;
  public RecalculateProductTemplateByMerchantIdLoadingStatus: LoadingStatusType = LoadingStatusType.None;

  get getMerchantDetailLoadingStatus(): LoadingStatusType {
    return this.merchantDetailLoadingStatus;
  }
  get getMerchantDetaililListModel(): Array<validateProductStockList> | null {
    return this.merchantDetailList;
  }
  get getrecordLotCostOfGoodsSoldLoadingStatus(): LoadingStatusType {
    return this.recordLotCostOfGoodsSoldLoadingStatus;
  }
  get getRecalculateProductTemplateByMerchantIdLoadingStatus(): LoadingStatusType {
    return this.RecalculateProductTemplateByMerchantIdLoadingStatus;
  }

  @Mutation
  public MutationMerchantDetailLoadingStatus(status: LoadingStatusType) {
    this.merchantDetailLoadingStatus = status;
  }
  @Mutation
  public MutationGetMerchantDetaililListModel(input: any) {
    this.merchantDetailList = input.table;

    // this.countItem = input.count;
  }
  @Mutation
  public MutationRecordLotCostOfGoodsSoldLoadingStatus(status: LoadingStatusType) {
    this.recordLotCostOfGoodsSoldLoadingStatus = status;
  }
  @Mutation
  public MutationRecalculateProductTemplateByMerchantIdLoadingStatus(status: LoadingStatusType) {
    this.RecalculateProductTemplateByMerchantIdLoadingStatus = status;
  }

  @Action
  public DoGetValidateProductStock(input: any) {
    if (this.merchantDetailLoadingStatus !== LoadingStatusType.Loading) {
      this.context.commit("MutationMerchantDetailLoadingStatus", LoadingStatusType.Loading);
      API.ValidateProductStockService.DoGetValidateProductStock(input).then((res) => {
        if (res.status.IsSuccess()) {
          const table = ConvertToValidateProductStockModelList(res.data.validateProductStockList);
          const input = {
            table: table,
          };
          this.context.commit("MutationGetMerchantDetaililListModel", input);
          this.context.commit("MutationMerchantDetailLoadingStatus", LoadingStatusType.Success);
        }
      });
    }
  }
  @Action
  public async RecalculateProductTemplateByMerchantId(input: any) {
    if (this.RecalculateProductTemplateByMerchantIdLoadingStatus !== LoadingStatusType.Loading) {
      this.context.commit("MutationRecalculateProductTemplateByMerchantIdLoadingStatus", LoadingStatusType.Loading);
      this.context.dispatch("Loading/IsLoading", true, { root: true });
      API.ValidateProductStockService.RecalculateProductTemplateByMerchantId(input).then((res) => {
        let message = "";
        if (res.data.responseCode.IsSuccess()) {
          message = "RecordLotCostOfGoodsSold สำเร็จ";
        } else {
          if (res.data.exception.code == Exception.LockbyPeriod) {
            message = "RecordLotCostOfGoodsSold ไม่สำเร็จเนื่องจาก lock by period";
          } else {
            message = "RecordLotCostOfGoodsSold ไม่สำเร็จ";
          }
        }
        alert(message);
        this.context.commit("MutationRecalculateProductTemplateByMerchantIdLoadingStatus", LoadingStatusType.Success);
        this.context.dispatch("Loading/IsLoading", false, { root: true });
        const createLogForUpdateProductTemplateForRecalculateCostofGoodsSoldByMerchantIdRequest: CreateLogForUpdateProductTemplateForRecalculateCostofGoodsSoldByMerchantIdRequest = {
          merchantId: input.merchantId,
          message: message,
          responseCode: res.data.responseCode,
        };
        API.ValidateProductStockService.CreateLogForUpdateProductTemplateForRecalculateCostofGoodsSoldByMerchantId(
          createLogForUpdateProductTemplateForRecalculateCostofGoodsSoldByMerchantIdRequest
        ).then((response) => {
          if (response.data.responseCode.IsSuccess()) {
            alert(message);
            this.context.commit(
              "MutationRecalculateProductTemplateByMerchantIdLoadingStatus",
              LoadingStatusType.Success
            );
            this.context.dispatch("Loading/IsLoading", false, { root: true });
          }
        });
      });
    }
  }
  @Action
  public async RecordLotCostOfGoodsSold(input: any) {
    if (this.recordLotCostOfGoodsSoldLoadingStatus !== LoadingStatusType.Loading) {
      this.context.commit("MutationRecordLotCostOfGoodsSoldLoadingStatus", LoadingStatusType.Loading);
      this.context.dispatch("Loading/IsLoading", true, { root: true });
      API.ValidateProductStockService.RecordLotCostOfGoodsSold(input).then((res) => {
        let message = "";
        if (res.data.responseCode.IsSuccess()) {
          message = "Run Stock สำเร็จ";
        } else {
          message = "Run Stock ไม่สำเร็จ";
        }
        const createLogForRecordLotCostOfGoodsSoldRequest: CreateLogForRecordLotCostOfGoodsSoldRequest = {
          merchantId: input,
          message: message,
          responseCode: res.data.responseCode,
        };
        API.ValidateProductStockService.CreateLogForRecordLotCostOfGoodsSold(
          createLogForRecordLotCostOfGoodsSoldRequest
        ).then((response) => {
          if (response.data.responseCode.IsSuccess()) {
            alert(message);
            this.context.commit("MutationRecordLotCostOfGoodsSoldLoadingStatus", LoadingStatusType.Success);
            this.context.dispatch("Loading/IsLoading", false, { root: true });
          }
        });
      });
    }
  }
}
