import {
  ActionLog,
  AuthenticationAction,
  BannerAction,
  MerchantAction,
  Module,
  ProductAction,
  UserAction,
  AccountingFirmEventAction
} from "@/enums/enums";
import { ActivityLogDetailModel } from "@/model/activitylog/activitylogDetailModel";
import { ActivityLogTableViewModel } from "@/model/activitylog/activityLogTableViewModel";

export function ConvertToActivityLogTableRequest(activityLogTableViewModel: ActivityLogTableViewModel) {
  const values = GetModuleIdAndActionId(activityLogTableViewModel.actionLog);
  const activityLogTableRequest: any = {
    name: activityLogTableViewModel.name,
    pageNumber: activityLogTableViewModel.pageNo ?? 1,
    pageSize: activityLogTableViewModel.pageSize ?? 10,
    fromDate: activityLogTableViewModel.fromDate,
    toDate: activityLogTableViewModel.toDate,
    moduleId: values[0],
    actionEventId: values[1],
  };
  return activityLogTableRequest;
}
export function ConvertToActivityLogDetailModelList(data: any) {
  const activitylogTable: any = {
    tableList: [],
  };
  if (data && data.loggingDetails && data.loggingDetails.length > 0) {
    data.loggingDetails.forEach((tableItem: any) => {
      const activitylogTableModel: ActivityLogDetailModel = {
        id: tableItem.id,
        name: tableItem.peakerModel.name,
        action: GetActionLog(tableItem.loggingAction.action.module, tableItem.loggingAction.action.logActionModule),
        actionDateTime: tableItem.loggingAction.actionDateTime,
        actionStatus: tableItem.loggingAction.actionStatus,
        description: tableItem.message,
        remark: tableItem.remark,
        asana: tableItem.asana,
      };
      activitylogTable.tableList.push(activitylogTableModel);
    });
  }
  return activitylogTable;
}
function GetModuleIdAndActionId(actionLog: number) {
  let moduleId: number | undefined = undefined;
  let actionEventId: number | undefined = undefined;
  if (actionLog === ActionLog.Login) {
    moduleId = Module.Authentication;
    actionEventId = AuthenticationAction.LogIn;
  } else if (actionLog === ActionLog.Swap) {
    moduleId = Module.Merchant;
    actionEventId = MerchantAction.SwapMerchantPeakPackage;
  } else if (actionLog === ActionLog.Cancel) {
    moduleId = Module.Merchant;
    actionEventId = MerchantAction.CancelPeakPayment;
  }
  return [moduleId, actionEventId];
}
function GetActionLog(moduleId: number, actionEventId: number) {
  let actionText = "";
  switch (moduleId) {
    case Module.Authentication:
      if (actionEventId === AuthenticationAction.LogIn) {
        actionText = "Log in";
      } else if (actionEventId === AuthenticationAction.LogIn) {
        actionText = "Log out";
      }
      break;
    case Module.Merchant:
      if (actionEventId === MerchantAction.SwapMerchantPeakPackage) {
        actionText = "Swap package";
      } else if (actionEventId === MerchantAction.CancelPeakPayment) {
        actionText = "Cancel package";
      } else if (actionEventId === MerchantAction.ConvertToAFP) {
        actionText = "Convert AFP";
      } else if (actionEventId === MerchantAction.ConvertToNormal) {
        actionText = "Convert Normal";
      }
      break;
    case Module.Product:
      if (actionEventId === ProductAction.UpdateProductTemplateForRecalculateCostofGoodsSoldByMerchantId) {
        actionText = "คำนวนต้นทุนขาย";
      } else if (actionEventId === ProductAction.RecordLotCostOfGoodsSold) {
        actionText = "Run Stock";
      }
      break;
    case Module.User:
      if (actionEventId === UserAction.ConvertUserPackageAfp) {
        actionText = "Convert to AFP";
      } else if (actionEventId === UserAction.ConvertUserPackageNormal) {
        actionText = "Convert to Normal user";
      }
      break;
    case Module.Banner:
      if (actionEventId === BannerAction.Create) {
        actionText = "Create Banner";
      } else if (actionEventId === BannerAction.Edit) {
        actionText = "Edit Banner";
      } else if (actionEventId === BannerAction.Delete) {
        actionText = "Delete Banner";
      } else {
        actionText = "Sort Banner";
      }
      break;
    case Module.AccountingFirm:
        actionText = GetAccountingFirmActionLog(actionEventId);
        break;
  }
  return actionText;
}
function GetAccountingFirmActionLog(actionEventId: number) {
  let actionText = "";
  switch (actionEventId) {
    case AccountingFirmEventAction.Create:
      actionText = "Create AFP";
      break;
    case AccountingFirmEventAction.Edit:
      actionText = "Edit AFP";
      break;
    case AccountingFirmEventAction.Delete:
      actionText = "Delete AFP";
      break;
    
  }
  return actionText;
}
