import {
  ChangeSuperUserRequest,
  UserTable,
  UserTableDetail
} from "@/model/user/userDetailModel";

export function ConvertToUserModelList(data: UserTable) {
  const userTable: UserTable = {
    alluserCount: data.alluserCount,
    isHaveSupportUser: data.isHaveSupportUser,
    userTableDetails: [],
  };
  if (data !== null && data.userTableDetails.length > 0) {
    data.userTableDetails.forEach((tableItem: UserTableDetail) => {
      const userTableModel: UserTableDetail = {
        userProfileMerchantId: tableItem.userProfileMerchantId,
        userprofileMerchantMerchantId: tableItem.userprofileMerchantMerchantId,
        id: tableItem.id,
        displayName: tableItem.displayName,
        firstName: tableItem.firstName,
        lastName: tableItem.lastName,
        isSupport: tableItem.isSupport,
        isSuperUser: tableItem.isSuperUser,
        authorityRole: tableItem.authorityRole,
        role: tableItem.role,
        logoUrl: tableItem.logoUrl,
        lastestLoginDateTime: tableItem.lastestLoginDateTime,
        userName: tableItem.userName,
      };
      userTable.userTableDetails.push(userTableModel);
    });
  }
  return userTable;
}
export function ConvertToUpdateSuperUserRequestModel(data: any) {
  const merchantActionOption = { asanaNumber: data.asana, remark: data.remark };
  const ChangeSuperUserRequest: ChangeSuperUserRequest = {
    merchantId: data.merchantId,
    oldSuperUser: data.oldSuperUser,
    newSuperUser: data.newSuperUser,
    merchantActionOption: data.merchantActionOption,
  };
  return ChangeSuperUserRequest;
}
