import moment from "moment";
import { AdminExceptionResponseCode, PaymentGatewayType, PeakPackage } from "./enums/enums";

enum HttpStatusCode {
  Continue = 100,
  SwitchingProtocols = 101,
  Processing = 102,
  EarlyHints = 103,
  OK = 200,
  Created = 201,
  Accepted = 202,
  NonAuthoritativeInformation = 203,
  NoContent = 204,
  ResetContent = 205,
  PartialContent = 206,
  MultiStatus = 207,
  AlreadyReported = 208,
  IMUsed = 226,
  Ambiguous = 300,
  MultipleChoices = 300,
  Moved = 301,
  MovedPermanently = 301,
  Found = 302,
  Redirect = 302,
  RedirectMethod = 303,
  SeeOther = 303,
  NotModified = 304,
  UseProxy = 305,
  Unused = 306,
  RedirectKeepVerb = 307,
  TemporaryRedirect = 307,
  PermanentRedirect = 308,
  BadRequest = 400,
  Unauthorized = 401,
  PaymentRequired = 402,
  Forbidden = 403,
  NotFound = 404,
  MethodNotAllowed = 405,
  NotAcceptable = 406,
  ProxyAuthenticationRequired = 407,
  RequestTimeout = 408,
  Conflict = 409,
  Gone = 410,
  LengthRequired = 411,
  PreconditionFailed = 412,
  RequestEntityTooLarge = 413,
  RequestUriTooLong = 414,
  UnsupportedMediaType = 415,
  RequestedRangeNotSatisfiable = 416,
  ExpectationFailed = 417,
  MisdirectedRequest = 421,
  UnprocessableEntity = 422,
  Locked = 423,
  FailedDependency = 424,
  UpgradeRequired = 426,
  PreconditionRequired = 428,
  TooManyRequests = 429,
  RequestHeaderFieldsTooLarge = 431,
  UnavailableForLegalReasons = 451,
  InternalServerError = 500,
  NotImplemented = 501,
  BadGateway = 502,
  ServiceUnavailable = 503,
  GatewayTimeout = 504,
  HttpVersionNotSupported = 505,
  VariantAlsoNegotiates = 506,
  InsufficientStorage = 507,
  LoopDetected = 508,
  NotExtended = 510,
  NetworkAuthenticationRequired = 511,
}

Number.prototype.IsSuccess = function (this: number): boolean {
  return this === HttpStatusCode.OK;
};
String.prototype.IsSuccess = function (this: string): boolean {
  return this.ToNumber() === HttpStatusCode.OK;
};
Number.prototype.IsProcessing = function (this: number): boolean {
  return this === HttpStatusCode.Processing;
};
String.prototype.IsProcessing = function (this: string): boolean {
  return this.ToNumber() === HttpStatusCode.Processing;
};
Number.prototype.IsUnauthorized = function (this: number): boolean {
  return this === HttpStatusCode.Unauthorized;
};
String.prototype.IsUnauthorized = function (this: string): boolean {
  return this.ToNumber() === HttpStatusCode.Unauthorized;
};
Number.prototype.IsInternalServerError = function (this: number): boolean {
  return this === HttpStatusCode.InternalServerError;
};
String.prototype.IsInternalServerError = function (this: string): boolean {
  return this.ToNumber() === HttpStatusCode.InternalServerError;
};
String.prototype.IsNumber = function (this: string): boolean {
  return RegExpNumber(this);
};
Number.prototype.GetPaymentGatewayNameByPaymentGatewayTypeId = function (this: PeakPackage): string {
  return GetPaymentGatewayNameByPaymentGatewayTypeId((this as unknown) as PaymentGatewayType);
};
Number.prototype.GetPeakPackageName = function (this: PeakPackage): string {
  return GetPeakPackageName((this as unknown) as PeakPackage);
};
String.prototype.ToNumber = function (this: string): number {
  return StringToNumber(this);
};

String.prototype.ConvertToDateFormat = function (this: string): string {
  return ToStringFormat(this);
};

String.prototype.ConvertToDate = function (this: string): Date | null {
  return ToDateTime(this);
};

String.prototype.GetPeakPackageNumber = function (this: string): number {
  return GetPeakPackageNumber(this);
};

String.prototype.IsExpired = function (this: string): boolean {
  return CheckIsExpired(this);
};

Number.prototype.GetAdminExceptionResponseMessage = function (this: AdminExceptionResponseCode): string {
  return GetAdminExceptionResponseMessageText((this as unknown) as AdminExceptionResponseCode);
};

String.prototype.Add30Days = function (this: string): string {
  return Add30Days(this);
};

Number.prototype.GetPeakPackageClassColor = function (this: PeakPackage): string {
  return GetPeakPackageClassColorName((this as unknown) as PeakPackage);
};

function RegExpNumber(str: string) {
  str = str.replace(/,/g, "");
  const RegExr = /^[0-9]*$/g;
  const text = str.toLowerCase().match(RegExr);
  return text == null ? false : true;
}

function StringToNumber(string: string): number {
  string = string.replace(/,/g, "");
  string = string.replace(/\(/g, "");
  string = string.replace(/\)/g, "");
  string = string.trim();
  const number = parseFloat(string);
  return GetNumber(number);
}
function GetNumber(number: number): number {
  number = isNaN(number) ? 0 : number;
  return number;
}

function GetPaymentGatewayNameByPaymentGatewayTypeId(paymentGatewayTypeInput: PaymentGatewayType): string {
  let paymentGatewayName = "";
  const paymentGatewayType = (paymentGatewayTypeInput as unknown) as PaymentGatewayType;
  switch (paymentGatewayType) {
    case PaymentGatewayType.None:
      paymentGatewayName = "Peak";
      break;
    case PaymentGatewayType.SCB:
      paymentGatewayName = "SCB";
      break;
    case PaymentGatewayType.Omise:
      paymentGatewayName = "Omise";
      break;
    case PaymentGatewayType.SCBQR:
      paymentGatewayName = "SCBQR";
      break;
    case PaymentGatewayType.Swap:
      paymentGatewayName = "Swap";
      break;
    case PaymentGatewayType.Tranfer:
      paymentGatewayName = "Transfer";
      break;
  }
  return paymentGatewayName;
}

function GetPeakPackageName(peakPackageInput: PeakPackage): string {
  let peakPackageName = "";
  const peakPackageType = (peakPackageInput as unknown) as PeakPackage;
  switch (peakPackageType) {
    case PeakPackage.Trial:
      peakPackageName = "Trial";
      break;
    case PeakPackage.Free:
      peakPackageName = "Free";
      break;
    case PeakPackage.Basic:
      peakPackageName = "Basic";
      break;
    case PeakPackage.Pro:
      peakPackageName = "Pro";
      break;
    case PeakPackage.ProPlus:
      peakPackageName = "Pro+";
      break;
    case PeakPackage.AFPAccountant:
      peakPackageName = "Acct";
      break;
    case PeakPackage.AFPFree:
      peakPackageName = "AFP";
      break;
    case PeakPackage.AFPLedger:
      peakPackageName = "LEDGER";
      break;
    case PeakPackage.Premium:
      peakPackageName = "Premium";
      break;
    default:
      peakPackageName = "-";
      break;
  }
  return peakPackageName;
}

function GetPeakPackageNumber(peakPackageInput: string): number {
  let peakPackageNumber = 0;
  const peakPackageType = peakPackageInput;
  switch (peakPackageType) {
    case "Trial":
      peakPackageNumber = 0;
      break;
    case "Free":
      peakPackageNumber = 1;
      break;
    case "Basic":
      peakPackageNumber = 2;
      break;
    case "Pro":
      peakPackageNumber = 3;
      break;
    case "Pro+":
      peakPackageNumber = 4;
      break;
    default:
      peakPackageNumber = -1;
      break;
  }
  return peakPackageNumber;
}

function ToStringFormat(date: string): string {
  const minDate = moment.utc("0001-01-01");
  let dateString: string | null | undefined = "";
  dateString = moment.utc(date).isAfter(minDate) ? moment(date).format("DD/MM/YYYY") : "-";
  return dateString;
}

function ToDateTime(data: string): Date | null {
  let dateValue = null;
  if (data) {
    dateValue = data !== "-" && data != "" ? moment(data, "DD/MM/YYYY").toDate() : null;
  }
  return dateValue;
}

function GetDateFormat(date: string | Date) {
  const start = new Date(date);
  let day = start.getDate().toString();
  let month = (parseInt(start.getMonth().toString(), 10) + 1).toString();
  const year = start.getFullYear();

  day = day.length == 2 ? day : "0" + day;
  month = month.length == 2 ? month : "0" + month;

  const dateFormat = day + "/" + month + "/" + year;

  if (date == null || isNaN(day.ToNumber()) || isNaN(month.ToNumber()) || isNaN(year)) {
    return "";
  } else {
    return dateFormat;
  }
}

function Add30Days(data: string): string {
  const newDate = new Date(moment(data).year(), moment(data).month(), moment(data).date() + 30);
  const newDateValue = moment(newDate).toDate();
  return newDateValue.toString();
}

function CheckIsExpired(data: string): boolean {
  let isExpired = false;
  const todayValue = new Date();
  const valueDate = new Date(moment(data).year(), moment(data).month(), moment(data).date());
  isExpired = todayValue > valueDate;
  return isExpired;
}

function GetAdminExceptionResponseMessageText(adminExceptionResponseCode: AdminExceptionResponseCode): string {
  let adminExceptionResponseMessage = "";
  const adminExceptionResponseCodeValue = (adminExceptionResponseCode as unknown) as AdminExceptionResponseCode;
  switch (adminExceptionResponseCodeValue) {
    case AdminExceptionResponseCode.SwapMerchantPeakPackageConnectV2NotMatch:
      adminExceptionResponseMessage = "ไม่สามารถสลับกิจการได้เนื่องจาก การชำระเงิน package ยังไม่ได้ยืนยัน";
      break;
    case AdminExceptionResponseCode.CanNotDeleteAccountingFirmBecauseAccountingFirmDeleted:
      adminExceptionResponseMessage = "ไม่สามารถลบ Accounting firm ได้ เนื่องจากถูกลบไปแล้ว";
      break;
    case AdminExceptionResponseCode.CanNotDeleteAccountingFirmBecauseAccountingFirmRelateMerchant:
      adminExceptionResponseMessage = "ไม่สามารถลบ Accounting firm ได้ เนื่องจากผูกกับกิจการไว้";
      break;
    case AdminExceptionResponseCode.CanNotCancelPeakPayment:
      adminExceptionResponseMessage = "ไม่สามารถยกเลิกการชำระเงินได้";
      break;
    case AdminExceptionResponseCode.MerchantSourcePeakPackageIsTrial:
      adminExceptionResponseMessage = "ไม่สามารถโอนย้ายกิจการได้เนื่องจาก กิจการเป็นแพ็กเกจ Trial";
      break;
    case AdminExceptionResponseCode.MerchantSourcePeakPackageIsFree:
      adminExceptionResponseMessage = "ไม่สามารถโอนย้ายกิจการได้เนื่องจาก กิจการเป็นแพ็กเกจ Free";
      break;
    case AdminExceptionResponseCode.MerchantSourcePeakPackageMoreThanMerchantDestinationPackage:
      adminExceptionResponseMessage = "ไม่สามารถโอนย้ายกิจการได้เนื่องจาก แพ็กเกจกิจการต้นทางสูงกว่ากิจการปลายทาง";
      break;
    case AdminExceptionResponseCode.PeakPackageTypeInvalid:
      adminExceptionResponseMessage = "ไม่สามารถโอนย้ายกิจการได้เนื่องจาก ประเภทของแพ็กเกจไม่ถูกต้อง";
      break;
    case AdminExceptionResponseCode.MerchantIsHavePaymentNotSuscess:
      adminExceptionResponseMessage = "ไม่สามารถทำรายการได้เนื่องจาก กิจการมีการค้างชำระการซื้อแพ็กเกจ";
      break;
    case AdminExceptionResponseCode.AddPeakSupport:
      adminExceptionResponseMessage = "กิจการมี PEAK Support แล้ว";
      break;
    case AdminExceptionResponseCode.BannerCodeDuplicate:
      adminExceptionResponseMessage = "Code ของ Banner ซ้ำ";
      break;
    case AdminExceptionResponseCode.BannerCodeHasWhiteSpace:
      adminExceptionResponseMessage = "Code ของ Banner มีช่องว่าง";
      break;
    case AdminExceptionResponseCode.BannerHasNotCode:
      adminExceptionResponseMessage = "กรุณาใส่ Code ของ Banner ให้ถูกต้อง";
      break;
    case AdminExceptionResponseCode.BannerCodeHasSpecialCharNotAllow:
      adminExceptionResponseMessage = "Code ของ Banner รองรับอักขระรองรับเฉพาะ ( ) , - , _";
      break;
    case AdminExceptionResponseCode.SuperUserIsNotAccontingFirm:
      adminExceptionResponseMessage = "Super user ไม่ใช่ Accounting firm";
      break;
    default:
      adminExceptionResponseMessage = "";
      break;
  }
  return adminExceptionResponseMessage;
}

function GetPeakPackageClassColorName(peakPackageName: PeakPackage): string {
  let colorClassName = "";
  switch (peakPackageName) {
    case PeakPackage.Trial:
      colorClassName = "trial-package";
      break;
    case PeakPackage.Free:
    case PeakPackage.AFPFree:
      colorClassName = "free-package";
      break;
    case PeakPackage.Basic:
      colorClassName = "basic-package";
      break;
    case PeakPackage.Pro:
      colorClassName = "pro-package";
      break;
    case PeakPackage.ProPlus:
      colorClassName = "proplus-package";
      break;
    case PeakPackage.AFPAccountant:
      colorClassName = "acct-package";
      break;
    case PeakPackage.AFPLedger:
      colorClassName = "ledger-package";
      break;
    case PeakPackage.Premium:
      colorClassName = "premium-package";
      break;
    default:
      colorClassName = "";
      break;
  }
  return colorClassName;
}
function ToAccountingRoundUp(number: number, _decimalNumber: number): string {
  _decimalNumber = isNaN(_decimalNumber) ? 2 : _decimalNumber;
  const numberRemoveExponential = number.ToRemoveExponential();
  const numberString = numberRemoveExponential.toString().split(".")[1];
  if (numberString != null && numberString.length > _decimalNumber) {
    const lastNumberDecimalToRoundUp = parseInt(numberString.substring(_decimalNumber, _decimalNumber + 1));
    let numberCalculated = parseFloat(
      numberRemoveExponential.toString().split(".")[0] + "." + numberString.substring(0, _decimalNumber + 1)
    );
    if (lastNumberDecimalToRoundUp == 5) {
      numberCalculated += 1 / Math.pow(10, _decimalNumber + 1);
    }
    return numberCalculated.toFixed(_decimalNumber);
  } else {
    return parseFloat(numberRemoveExponential).toFixed(_decimalNumber);
  }
}
function ToRemoveExponential(number: number): string {
  let numberRemoveExponential = number + "";
  if (Math.abs(number) < 1.0) {
    const e = parseInt(number.toString().split("e-")[1]);
    if (e) {
      number *= Math.pow(10, e - 1);
      numberRemoveExponential = "0." + new Array(e).join("0") + number.toString().substring(2);
    }
  } else {
    let e = parseInt(number.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      number /= Math.pow(10, e);
      numberRemoveExponential = number + "";
      numberRemoveExponential += new Array(e + 1).join("0");
    }
  }

  return numberRemoveExponential;
}
function ToNumberStringFormat(
  amount: number,
  _decimalNumber: number,
  _deciamlSymbol: string,
  _separateSymbol: string
): string {
  let total: number = amount;
  const decimalNumber = isNaN((_decimalNumber = Math.abs(_decimalNumber))) ? 2 : _decimalNumber;
  const decimalSymbol = _deciamlSymbol == undefined ? "." : _deciamlSymbol;
  const separateSymbol = _separateSymbol == undefined ? "," : _separateSymbol;
  let isNegative = total < 0 ? "-" : "";
  total = ToAccountingRoundUp(Math.abs(+total || 0), decimalNumber).ToNumber();
  const roundingAmount = parseInt(total.toString()) + "";
  let separateCount = roundingAmount.length;
  separateCount = separateCount > 3 ? separateCount % 3 : 0;
  const decimalAmount = ToAccountingRoundUp(total, decimalNumber).split(".")[1];
  if (_decimalNumber !== 0) {
    return (
      isNegative +
      (separateCount ? roundingAmount.substr(0, separateCount) + separateSymbol : "") +
      roundingAmount.substr(separateCount).replace(/(\d{3})(?=\d)/g, "$1" + separateSymbol) +
      decimalSymbol +
      decimalAmount
    );
  } else {
    isNegative = parseInt(amount.toString()) < 0 ? "-" : "";
    return (
      isNegative +
      (separateCount ? roundingAmount.substr(0, separateCount) + separateSymbol : "") +
      roundingAmount.substr(separateCount).replace(/(\d{3})(?=\d)/g, "$1" + separateSymbol)
    );
  }
}

String.prototype.PeakEncryption = function (this: string): string {
  return btoa(this);
};

Number.prototype.PeakEncryption = function (this: number): string {
  const str = this.toString();
  return btoa(str);
};
String.prototype.PeakDecryption = function (this: string): number {
  let value = 0;
  try {
    value = atob(this).ToNumber();
  } catch {
    value = 0;
  }
  return value;
};
String.prototype.GetDateFormat = function (this: string): string {
  return GetDateFormat(this);
};
Date.prototype.GetDateFormat = function (this: Date): string {
  return GetDateFormat(this);
};
String.prototype.FormatMoney = function (this: string, decimalNumber?: number): string {
  if (decimalNumber == undefined) {
    decimalNumber = 2;
  }
  return ToNumberStringFormat(this.ToNumber(), decimalNumber, ".", ",");
};

Number.prototype.FormatMoney = function (this: number, decimalNumber?: number): string {
  if (decimalNumber == undefined) {
    decimalNumber = 2;
  }
  return ToNumberStringFormat(this, decimalNumber, ".", ",");
};
String.prototype.ToAccountingRoundUp = function (this: string): string {
  return ToAccountingRoundUp(this.ToNumber(), 2);
};

Number.prototype.ToAccountingRoundUp = function (this: number): string {
  return ToAccountingRoundUp(this, 2);
};

Number.prototype.ToRemoveExponential = function (this: number): string {
  return ToRemoveExponential(this);
};

declare global {
  interface String {
    IsSuccess(this: string): boolean;
    IsProcessing(this: string): boolean;
    IsUnauthorized(this: string): boolean;
    IsInternalServerError(this: string): boolean;
    ToNumber(this: string): number;
    IsNumber(this: string): boolean;
    ConvertToDateFormat(this: string): string;
    ConvertToDate(this: string): Date | null;
    GetPeakPackageNumber(this: string): number;
    IsExpired(this: string): boolean;
    Add30Days(this: string): string;

    PeakEncryption(this: string): string;
    PeakDecryption(this: string): number;
    GetDateFormat(this: string): string;
    FormatMoney(this: string, decimalNumber?: number): string;
    ToAccountingRoundUp(this: string): string;
  }
  interface Number {
    IsSuccess(this: number): boolean;
    IsProcessing(this: number): boolean;
    IsUnauthorized(this: number): boolean;
    IsInternalServerError(this: number): boolean;
    ToNumber(this: number): number;
    GetPaymentGatewayNameByPaymentGatewayTypeId(this: number): string;
    GetPeakPackageName(this: number): string;
    GetAdminExceptionResponseMessage(this: number): string;
    PeakEncryption(this: number): string;
    FormatMoney(this: number, decimalNumber?: number): string;
    ToAccountingRoundUp(this: number): string;
    ToRemoveExponential(this: number): string;
    GetPeakPackageClassColor(this: number): string;
  }
  interface Date {
    GetDateFormat(this: Date): string;
  }
}
