import { ValidateProductStockAccount, validateProductStockList } from "@/model/product/validateProductStockDetailModel";

export function ConvertToValidateProductStockModelList(data: Array<validateProductStockList>) {
  const ProductStock = { accountList: Array<validateProductStockList>() };
  if (data !== null && data.length > 0) {
    data.forEach((tableItem: validateProductStockList) => {
      const validateProductStock: validateProductStockList = {
        id: tableItem.id,
        code: tableItem.code,
        purchaseAccountId: tableItem.purchaseAccountId,
        purchaseAccountCode: tableItem.purchaseAccountCode,
        sellAccountId: tableItem.sellAccountId,
        sellAccountCode: tableItem.sellAccountCode,
        costOfGoodsSoldAccountId: tableItem.costOfGoodsSoldAccountId,
        costOfGoodsSoldAccountCode: tableItem.costOfGoodsSoldAccountCode,
        isRecordJournalCostOfGoodsSold: tableItem.isRecordJournalCostOfGoodsSold,
        remainingInventoryValue: tableItem.remainingInventoryValue,
        remainingInventoryUnit: tableItem.remainingInventoryUnit,
        remainingCostOfGoodsSoldAccount: tableItem.remainingCostOfGoodsSoldAccount,
        totalBalanceValue: tableItem.totalBalanceValue,
        totalBalanceUnit: tableItem.totalBalanceUnit,
        totalStock: tableItem.totalStock,
        isError: tableItem.isError,
        isErrorTotalStock: tableItem.isErrorTotalStock,
        accountList: [],
      };
      if (tableItem.accountList && tableItem.accountList.length > 0) {
        tableItem.accountList.forEach((item: ValidateProductStockAccount) => {
          const ValidateProductStocksModel: ValidateProductStockAccount = {
            accountId: item.accountId,
            accountCode: item.accountCode,
            total: item.total,
            debit: item.debit,
            credit: item.credit,
          };
          validateProductStock.accountList.push(ValidateProductStocksModel);
        });
      }
      ProductStock.accountList.push(validateProductStock);
    });
  }

  return ProductStock;
}
