export enum PaymentGatewayType {
  None = 0,
  SCB = 1,
  Omise = 2,
  SCBQR = 3,
  Swap = 4,
  Tranfer = 5,
  ConvertToAfp = 6,
  ConvertToNormal = 7,
}
export enum PeakPackage {
  None = -1,
  Trial = 0,
  Free = 1,
  Basic = 2,
  Pro = 3,
  ProPlus = 4,
  AFPFree = 5,
  AFPLedger = 6,
  AFPAccountant = 7,
  Premium = 8,
}

export enum LoadingStatusType {
  None = 0,
  Loading = 1,
  Success = 2,
  Fail = 3,
}

export enum LanguageType {
  Th = 0,
  En = 1,
}

export enum AdminExceptionResponseCode {
  None = 0,
  SwapMerchantPeakPackageConnectV2NotMatch = 1,
  CanNotCancelPeakPayment = 2,
  OldSuperUserIsDuplicateWithNewSuperUser = 3,
  OldSuperuserNotInMerchant = 4,
  NewSuperUserNotInMerchant = 5,
  CanNotDeleteAccountingFirmBecauseAccountingFirmDeleted = 6,
  CanNotDeleteAccountingFirmBecauseAccountingFirmRelateMerchant = 7,
  CanNotFoundPeakPaymentConnectV2 = 8,
  PeakPaymentConnectV2IsDelete = 9,
  PeakPaymentConnectV2IsApprove = 10,
  MerchantSourcePeakPackageIsTrial = 11,
  MerchantSourcePeakPackageIsFree = 12,
  MerchantSourcePeakPackageMoreThanMerchantDestinationPackage = 13,
  PeakPackageTypeInvalid = 14,
  MerchantIsHavePaymentNotSuscess = 15,
  SupportIsDeleted = 16,
  CannotDeleteUserNotSupport = 17,
  AddPeakSupport = 18,
  BannerHaveNoteThumbNailFile = 19,
  InvalidAppConnectConfigData = 20,
  BannerCodeDuplicate = 21,
  BannerCodeHasWhiteSpace = 22,
  BannerHasNotCode = 23,
  MerchantNotHasSuperAdmin = 24,
  SuperUserIsNotAfp = 25,
  MerchantIsAfpAlready = 26,
  MerchantIsNormalAlready = 27,
  BannerCodeHasSpecialCharNotAllow = 28,
  MerchantIsPackageRecurring = 29,
  AddOnLessThan = 30,
  SuperUserIsNotAccontingFirm = 31,
  PleaseRefreshPageOrSearchAgain = 32,
  InformationIsNotCurrent = 33,
  PeakRealTimeRequestError = 34,
  PromoRequestNumberExist = 35,
  DuplicatePromoCampaign = 36,
  InvalidPromoRequestData = 37,
  DuplicatePromoCode = 38,
  CanNotConvertPackage = 46,
  CanNotSwapBetweenNormalPackageAndAfpPackage = 48,
}
export enum Module {
  None = 0,
  Authentication = 1,
  Merchant = 2,
  AccountingFirm = 3,
  PromoCode = 4,
  Contact = 5,
  Product = 6,
  Transaction = 7,
  User = 8,
  AppConnect = 9,
  Banner = 10,
}
export enum AuthenticationAction {
  None = 0,
  LogIn = 1,
  LogOut = 2,
  Register = 3,
}
export enum MerchantAction {
  None = 0,
  CancelPeakPayment = 1,
  SwapMerchantPeakPackage = 2,
  ChangeSuperAdmin = 3,
  UpdateVerifyMerchant = 4,
  ApprovePackage = 5,
  TranferPackage = 6,
  AddPeakSupport = 7,
  RemovePeakSupport = 8,
  ConvertToAFP = 9,
  ConvertToNormal = 10,
}
export enum ProductAction {
  None = 0,
  UpdateProductTemplateForRecalculateCostofGoodsSoldByMerchantId = 1,
  RecordLotCostOfGoodsSold = 2,
}
export enum ActionLog {
  None = 0,
  Login = 1,
  Swap = 2,
  Cancel = 3,
  Logout = 4,
}
export enum AuthorityRole {
  None = 0,
  ViewOnly = 1,
  PartialRights = 2,
  StandardRights = 3,
  Admin = 4,
}
export enum Role {
  None = 0,
  ViewOnly = 1,
  PartialRightsDraftOnly = 2,
  PartialRightsApproveAndReceivePayment = 3,
  PartialRightsRevenueOnly = 4,
  PartialRightsExpenseOnly = 5,
  PartialRightsQuotationOnly = 6,
  StandardRightsViewReport = 7,
  StandardRightsNotViewReport = 8,
  Admin = 9,
  PartialRightsCreateRevenueOnly = 10,
  PartialRightsCreateExpenseOnly = 11,
}
export enum UserOrderBy {
  Id = 1,
  DisplayName = 2,
  LastestLogin = 3,
}
export enum TableSorting {
  DESC,
  ASC,
}
export enum PromoCampaignType {
  Discount = 1,
  Package = 2,
  Extra = 3,
}

export enum UserRegisterType {
  PeakEngine = 0,
  SCB = 1,
  JabJai = 2,
  Digiplus = 3,
  DbdSme = 4,
  ApartmenteryDeal = 5,
  UOB = 6,
  KBOL = 7,
  PeakAccount = 8,
  PeakTax = 9,
}

export enum PromoCampaignDiscountType {
  None = 0,
  Amount = 1,
  Percent = 2,
}

export enum PackagePeriodOption {
  None = 0,
  Package = 1,
  Period = 2,
}

export enum PackageMonth {
  None = 0,
  OneMonth = 1,
  ThreeMonth = 3,
  TwelveMonth = 12,
}

export enum PackagePeriodType {
  Month = 1,
  Day = 2,
  ExpiredDate = 3,
}

export enum CreatePromoCodeType {
  Advance = 1,
  List = 2,
}

export enum VerifiedStatus {
  None = 0,
  Processing = 1,
  Success = 2,
}

export enum Exception {
  LockbyPeriod = 5,
}

export enum CustomerType {
  None = 0,
  Organic = 1,
  AFP = 2,
  BD = 3,
  CSR = 4,
  Enterprise = 5,
  PARC = 6,
  Test = 7,
  AFPFree = 8,
  BDFree = 9,
  APIServices = 10,
  ClientSupports = 11,
  OtherInternalUses = 12,
  Gifts = 13,
}

export enum IsCommercial {
  No = 0,
  Yes = 1,
}

export enum PaymentStatus {
  OnProcessing = -1,
  Waiting = 0,
  Approve = 1,
}

export enum PeakPaymentStatus {
  Deleted = -1,
  None = 0,
  WaitingApprove = 1,
  Approved = 2,
}

export enum MerchantActionMenu {
  SwapPackage = 1,
  CancelPackage = 2,
  User = 3,
  ApprovePackage = 4,
  ViewReceipt = 5,
  ViewWitholdingTax = 6,
  TransferPackage = 7,
  ConvertPackageAFP = 8,
}

export enum PeakPaymentConnectV2Id {
  None = 0,
}

export enum PuunReceiptId {
  None = 0,
}

export enum WhtId {
  None = 0,
}

export enum DocumentSearchType {
  None = 0,
  Quotation = 1,
  Invoice = 2,
  Receipt = 3,
  PurchaseOrder = 4,
  Expense = 5,
  Journal = 6,
}

export enum SearchingDuplicateType {
  None = 0,
  Number = 1,
  Data = 2,
}
export enum UserPackageType {
  None = 0,
  Normal = 1,
  AFP = 2,
}
export enum UserActionMenu {
  ConvertToAFP = 0,
  ConvertToNormal = 1,
}
export enum UserAction {
  None = 0,
  ConvertUserPackageAfp = 1,
  ConvertUserPackageNormal = 2,
}
export enum ChangeSuperUserAction {
  ChangeAdmin = 1,
  DeleteSupport = 2,
}
export enum BannerActionMenu {
  Edit = 1,
  Delete = 2,
}
export enum BannerStatus {
  None = 0,
  Pending = 1,
  Live = 2,
  Expired = 3,
}
export enum BannerOpenType {
  None = 0,
  Modal = 1,
  Link = 2,
}
export enum BannerUrlType {
  None = 0,
  Peak = 1,
  Other = 2,
}
export enum BannerStep {
  StepThumbnail = 1,
  StepDetail = 2,
  StepRule = 3,
}
export enum BannerMediaType {
  Image = 1,
  Link = 2,
}
export enum BannerAction {
  None = 0,
  Create = 1,
  Edit = 2,
  Sort = 3,
  Delete = 4,
}
export enum ConvertMerchantPackage {
  notHaveSuperAdmin = 24,
  canNotConvert = 25,
}
export enum AccountingFirmAcction {
  Detail = 1,
  Edit = 2,
  Delete = 3,
}
export enum AccountingFirmStatus {
  None = 0,
  Active = 1,
  Inactive = 2,
}
export enum AccountingFirmEventAction {
  None = 0,
  Create = 1,
  Edit = 2,
  Delete = 3,
  GenAfpRefCode = 4,
}

export enum SortType {
  Order = 0,
  Select = 1,
}

export enum PackageStatus {
  All = 0,
  Draft = 1,
  Approved = 2,
  Canceled = 3,
  OnProcess = 4,
}
export enum ReceiptStatus {
  None = 0,
  Voided = 1,
  Draft = 2,
  AwaitApproval = 3,
  Approved = 4,
}
export enum PeakPaymentConnectStatus {
  None = 0,
  Draft = 1,
  Approved = 2,
  Canceled = 3,
  OnProcess = 4,
}
export enum ShippingVatType {
  None = 1,
  ZeroPercent = 2,
  SevenPercent = 3,
}
export enum WhtAmountType {
  NotSpecify = -2,
  None = -1,
  Custom = 0,
  ZeroPointSevenFive = 1,
  OnePercent = 2,
  TwoPercent = 3,
  ThreePercent = 4,
  FivePercent = 5,
  TenPercent = 7,
  FifteenPercent = 8,
  OnePointFivePercent = 9,
}

export enum PromoStatus {
  None = 0,
  Draft = 1,
  WaitingApprove = 2,
  Approve = 3,
  Void = 4,
}

export enum CreatePromoCodeRequestType {
  Select = 1,
  Create = 2,
}

export enum PromoCodeCreateLevelType {
  Basic = 1,
  Advance = 2,
}

export enum EnvironmentType {
  Dev = 0,
  SIT = 1,
  UAT = 2,
  Staging = 3,
  Production = 4,
}

export enum SendPromoRequestType {
  CreateDraft = 1,
  SendEdit = 2,
}
