










import { Component, Vue, Watch } from "vue-property-decorator";
import Layout from "./Layout.vue";
import IsLoading from "@/components/element/IsLoading.vue";

@Component({
  components: { Layout, IsLoading },
})
export default class Home extends Vue {
  private useLayout = false;

  @Watch("$route", { immediate: true })
  routeChanged(newVal: any) {
    if (newVal && newVal.meta && newVal.meta.auth) {
      this.useLayout = true;
    }
  }
}
