import { SetHeaderService } from "@/helper/merchantHelper";
import {
  AddPeakSupportRequest,
  ChangeSuperUserRequest,
  DeletePeakSupportRequest,
  UserTableRequest,
} from "@/model/user/userDetailModel";
import service from "../baseHttpService";

class ChangeSuperService {
  async DoGetUserTable(UserTableRequest: UserTableRequest) {
    return service.peakAdminAPI
      .post("/api/Merchant/GetUserTable", UserTableRequest, await SetHeaderService())
      .then((res) => {
        return res;
      });
  }
  async DoUpdateSuperUser(ChangeSuperUserRequest: ChangeSuperUserRequest) {
    return service.peakAdminAPI
      .post("/api/Merchant/UpdateSuperUser", ChangeSuperUserRequest, await SetHeaderService())
      .then((res) => {
        return res;
      });
  }
  async DoAddPeakSupport(AddPeakSupportRequest: AddPeakSupportRequest) {
    return service.peakAdminAPI
      .post("/api/Merchant/AddPeakSupport", AddPeakSupportRequest, await SetHeaderService())
      .then((res) => {
        return res;
      });
  }
  async DoDeletePeakSupport(DeletePeakSupportRequest: DeletePeakSupportRequest) {
    return service.peakAdminAPI
      .post("/api/Merchant/RemovePeakSupport", DeletePeakSupportRequest, await SetHeaderService())
      .then((res) => {
        return res;
      });
  }
}

export default new ChangeSuperService();
