import { AdminExceptionResponseCode, LoadingStatusType } from "@/enums/enums";
import {
  CreatelatestUpdateRequestModel,
  DeleteLatestUpdateRequestModel,
  LatestUpdateTableViewModel,
  SortOrderLatestUpdateRequest,
} from "@/model/latestUpdate/latestUpdateModel";
import API from "@/service/Index";
import { ConvertToLatestUpdateTableModelList } from "@/service/latestUpdate/latestUpdateFacade";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({ namespaced: true })
export default class LatestUpdate extends VuexModule {
  public bannerTableModels: Array<any> | null = null;
  public bannerTableLoadingStatus: LoadingStatusType = LoadingStatusType.None;
  public createBannerRequestLoadingStatus: LoadingStatusType = LoadingStatusType.None;
  public editBannerRequestLoadingStatus: LoadingStatusType = LoadingStatusType.None;
  public sortOrderBannerRequestLoadingStatus: LoadingStatusType = LoadingStatusType.None;
  public countItem: number | null = 0;
  public bannerDetail: any = null;
  public bannerDetailLoadingStatus: LoadingStatusType = LoadingStatusType.None;
  public deleteBannerLoadingStatus: LoadingStatusType = LoadingStatusType.None;
  public bannerCode = "";
  public bannerCodeLoadingStatus: LoadingStatusType = LoadingStatusType.None;

  get getBannerTableListModels(): Array<any> | null {
    return this.bannerTableModels;
  }
  get getBannerTableLoadingStatus(): LoadingStatusType {
    return this.bannerTableLoadingStatus;
  }
  get getCountItemTable(): number | null {
    return this.countItem;
  }
  get getcreateBannerRequestLoadingStatus(): LoadingStatusType {
    return this.createBannerRequestLoadingStatus;
  }
  get getsortOrderBannerRequestLoadingStatus(): LoadingStatusType {
    return this.sortOrderBannerRequestLoadingStatus;
  }
  get getBannerDetail(): any | null {
    return this.bannerDetail;
  }
  get getBannerDetailLoadingStatus(): LoadingStatusType {
    return this.bannerDetailLoadingStatus;
  }
  get getDeleteBannerLoadingStatus(): LoadingStatusType {
    return this.deleteBannerLoadingStatus;
  }
  get getBannerCode() {
    return this.bannerCode;
  }
  get getBannerCodeLoadingStatus(): LoadingStatusType {
    return this.bannerCodeLoadingStatus;
  }
  get getEditBannerRequestLoadingStatus(): LoadingStatusType {
    return this.editBannerRequestLoadingStatus;
  }

  @Mutation
  public MutationGetBannerTableListModels(input: any) {
    this.bannerTableModels = input.table.tableList;
    this.countItem = input.count;
  }
  @Mutation
  public MutationGetBannerDetail(bannerDetail: any) {
    this.bannerDetail = bannerDetail;
  }
  @Mutation
  public MutationGetBannerDetailLoadingStatus(status: LoadingStatusType) {
    this.bannerDetailLoadingStatus = status;
  }
  @Mutation
  public MutationBannerTableLoadingStatus(status: LoadingStatusType) {
    this.bannerTableLoadingStatus = status;
  }
  @Mutation
  public MutationCreateBannerRequestLoadingStatus(status: LoadingStatusType) {
    this.createBannerRequestLoadingStatus = status;
  }
  @Mutation
  public MutationSortOrderBannerRequestLoadingStatus(status: LoadingStatusType) {
    this.sortOrderBannerRequestLoadingStatus = status;
  }
  @Mutation
  public MutationEditBannerRequestLoadingStatus(status: LoadingStatusType) {
    this.editBannerRequestLoadingStatus = status;
  }
  @Mutation
  public MutationDeleteBannerLoadingStatus(status: LoadingStatusType) {
    this.deleteBannerLoadingStatus = status;
  }

  @Mutation
  public MutationGetBannerCode(code: string) {
    this.bannerCode = code;
  }
  @Mutation
  public MutationGetBannerCodeLoadingStatus(status: LoadingStatusType) {
    this.bannerCodeLoadingStatus = status;
  }

  @Action
  public DoGetBannerTable(latestUpdateTableViewModel: LatestUpdateTableViewModel) {
    if (this.bannerTableLoadingStatus !== LoadingStatusType.Loading) {
      this.context.commit("MutationBannerTableLoadingStatus", LoadingStatusType.Loading);
      API.LatestUpdateService.DoGetBannerTable(latestUpdateTableViewModel).then((res) => {
        if (res.status.IsSuccess()) {
          const table = ConvertToLatestUpdateTableModelList(res.data.table);
          const countItem = res.data.table.count;
          const input = {
            tableViewModel: latestUpdateTableViewModel,
            table: table,
            count: countItem,
          };
          this.context.commit("MutationGetBannerTableListModels", input);
          this.context.commit("MutationBannerTableLoadingStatus", LoadingStatusType.Success);
        }
      });
    }
  }
  @Action
  public DoGetBannerCode() {
    if (this.bannerDetailLoadingStatus !== LoadingStatusType.Loading) {
      this.context.commit("MutationGetBannerCodeLoadingStatus", LoadingStatusType.Loading);
      API.LatestUpdateService.DoGetBannerCode().then((res) => {
        if (res.status.IsSuccess()) {
          this.context.commit("MutationGetBannerCode", res.data.code);
          this.context.commit("MutationGetBannerCodeLoadingStatus", LoadingStatusType.Success);
        } else {
          this.context.commit("MutationGetBannerCodeLoadingStatus", LoadingStatusType.None);
        }
      });
    }
  }
  @Action
  public DoGetBannerDetail(code: string) {
    if (this.bannerDetailLoadingStatus !== LoadingStatusType.Loading) {
      this.context.commit("MutationGetBannerDetailLoadingStatus", LoadingStatusType.Loading);
      API.LatestUpdateService.DoGetBannerDetail(code).then((res) => {
        if (res.status.IsSuccess()) {
          this.context.commit("MutationGetBannerDetail", res.data.banner);
          this.context.commit("MutationGetBannerDetailLoadingStatus", LoadingStatusType.Success);
        } else {
          this.context.commit("MutationGetBannerDetailLoadingStatus", LoadingStatusType.None);
        }
      });
    }
  }
  @Action
  public DoDeleteBanner(input: DeleteLatestUpdateRequestModel) {
    if (this.bannerDetailLoadingStatus !== LoadingStatusType.Loading) {
      this.context.commit("MutationDeleteBannerLoadingStatus", LoadingStatusType.Loading);
      API.LatestUpdateService.DoDeleteBanner(input).then((res) => {
        if (res.data.voided) {
          this.context.commit("MutationDeleteBannerLoadingStatus", LoadingStatusType.Success);
        } else {
          let message = "";
          if (res.data.exception != null) {
            message = res.data.exception.code.GetAdminExceptionResponseMessage();
            alert(message);
          }
          this.context.commit("MutationDeleteBannerLoadingStatus", LoadingStatusType.None);
        }
      });
    }
  }
  @Action
  public DoInsertBanner(createlatestUpdateRequestModel: CreatelatestUpdateRequestModel) {
    if (this.createBannerRequestLoadingStatus !== LoadingStatusType.Loading) {
      this.context.commit("MutationCreateBannerRequestLoadingStatus", LoadingStatusType.Loading);
      API.LatestUpdateService.DoInsertBanner(createlatestUpdateRequestModel).then((res) => {
        if (res.data.responseCode.IsSuccess()) {
          this.context.commit("MutationCreateBannerRequestLoadingStatus", LoadingStatusType.Success);
        } else {
          let message = "";
          if (res.data.exception != null) {
            message = res.data.exception.code.GetAdminExceptionResponseMessage();
            alert(message);
            const bannerCodeExceptionList = [
              AdminExceptionResponseCode.BannerCodeDuplicate,
              AdminExceptionResponseCode.BannerCodeHasWhiteSpace,
              AdminExceptionResponseCode.BannerHasNotCode,
            ];
            if (bannerCodeExceptionList.includes(res.data.exception.code)) {
              this.context.commit("MutationCreateBannerRequestLoadingStatus", LoadingStatusType.Fail);
            } else {
              this.context.commit("MutationCreateBannerRequestLoadingStatus", LoadingStatusType.None);
            }
          }
        }
      });
    }
  }
  @Action
  public DoEditBanner(editlatestUpdateRequestModel: CreatelatestUpdateRequestModel) {
    if (this.createBannerRequestLoadingStatus !== LoadingStatusType.Loading) {
      this.context.commit("MutationEditBannerRequestLoadingStatus", LoadingStatusType.Loading);
      API.LatestUpdateService.DoEditBanner(editlatestUpdateRequestModel).then((res) => {
        if (res.status.IsSuccess()) {
          this.context.commit("MutationEditBannerRequestLoadingStatus", LoadingStatusType.Success);
        } else {
          let message = "";
          if (res.data.exception != null) {
            message = res.data.exception.code.GetAdminExceptionResponseMessage();
            alert(message);
          }
          this.context.commit("MutationEditBannerRequestLoadingStatus", LoadingStatusType.None);
        }
      });
    }
  }
  @Action
  public DoSortBanner(sortOrderLatestUpdateRequest: SortOrderLatestUpdateRequest[]) {
    if (this.createBannerRequestLoadingStatus !== LoadingStatusType.Loading) {
      this.context.commit("MutationSortOrderBannerRequestLoadingStatus", LoadingStatusType.Loading);
      API.LatestUpdateService.DoSortBanner(sortOrderLatestUpdateRequest).then((res) => {
        if (res.status.IsSuccess()) {
          this.context.commit("MutationSortOrderBannerRequestLoadingStatus", LoadingStatusType.Success);
        } else {
          let message = "";
          if (res.data.exception != null) {
            message = res.data.exception.code.GetAdminExceptionResponseMessage();
            alert(message);
          }
          this.context.commit("MutationSortOrderBannerRequestLoadingStatus", LoadingStatusType.None);
        }
      });
    }
  }
}
