import { SetHeaderService } from "@/helper/merchantHelper";
import service from "../baseHttpService";

class PackageService {
  async DoGetPackageTable(input: any) {
    return service.peakAdminAPI
      .get(
        `/api/PeakPayment/GetpeakPaymentConnectTable?pageSize=${input.pageSize}&pageNo=${input.pageNo}${
          input.name ? `&name=${input.name}` : ""
        }${input.code ? `&internalCode=${input.code}` : ""}${
          input.sortItem ? `&sort=${input.sortItem}:${input.sortBy}` : ""
        }&peakPaymentConnectTableStatus=${input.peakPaymentConnectTableStatus}${
          input.merchantCode ? `&merchantCode=${input.merchantCode}` : ""
        }${input.fromDate ? `&fromDate=${input.fromDate}` : ""}${input.toDate ? `&toDate=${input.toDate}` : ""}`,
        await SetHeaderService()
      )
      .then((res) => {
        return res;
      });
  }
  async DoGetPeakPaymentConnectPackageInfo(input: any) {
    return service.peakAdminAPI
      .get(
        `/api/PeakPayment/GetpeakPaymentConnectPackageInfo?peakPaymentConnectV2Id=${input.peakPaymentConnectV2Id}&merchantId=${input.merchantId}`,
        await SetHeaderService()
      )
      .then((res) => {
        return res;
      });
  }
  async DoGetPeakPaymentConnectDocumentInfo(input: any) {
    return service.peakAdminAPI
      .get(
        `/api/PeakPayment/GetpeakPaymentConnectDocumentInfo?peakPaymentConnectV2Id=${input.peakPaymentConnectV2Id}&merchantId=${input.merchantId}&receiptId=${input.receiptId}`,
        await SetHeaderService()
      )
      .then((res) => {
        return res;
      });
  }
}

export default new PackageService();
