import { SetHeaderService } from "@/helper/merchantHelper";
import {
  CreatelatestUpdateRequestModel,
  DeleteLatestUpdateRequestModel,
  LatestUpdateTableViewModel,
  SortOrderLatestUpdateRequest,
} from "@/model/latestUpdate/latestUpdateModel";
import { ConvertToLatestUpdateTableRequest } from "@/service/latestUpdate/latestUpdateFacade";
import service from "../baseHttpService";

class LatestUpdateService {
  async DoGetBannerTable(latestUpdateTableViewModel: LatestUpdateTableViewModel) {
    const input = ConvertToLatestUpdateTableRequest(latestUpdateTableViewModel);

    return service.peakAdminAPI
      .get(
        `/api/Banner/GetBannerTable?pageSize=${input.pageSize}&pageNumber=${input.pageNumber}${
          input.name ? `&name=${input.name}` : ""
        }${input.code ? `&code=${input.code}` : ""}${
          input.bannerTabStatus ? `&bannerTabStatus=${input.bannerTabStatus}` : ""
        }${input.startDatetime ? `&startDatetime=${input.startDatetime}` : ""}${
          input.endDatetime ? `&endDatetime=${input.endDatetime}` : ""
        }`,
        await SetHeaderService()
      )
      .then((res) => {
        return res;
      });
  }
  async DoGetBannerDetail(code: string) {
    return service.peakAdminAPI
      .get(`/api/Banner/GetBannerDetail?code=${code}`, await SetHeaderService())
      .then((res) => {
        return res;
      });
  }
  async DoInsertBanner(input: CreatelatestUpdateRequestModel) {
    return service.peakAdminAPI.post(`/api/Banner/InsertBanner`, input, await SetHeaderService()).then((res) => {
      return res;
    });
  }
  async DoEditBanner(input: CreatelatestUpdateRequestModel) {
    return service.peakAdminAPI.post(`/api/Banner/EditBanner`, input, await SetHeaderService()).then((res) => {
      return res;
    });
  }
  async DoDeleteBanner(input: DeleteLatestUpdateRequestModel) {
    return service.peakAdminAPI.post(`/api/Banner/VoidBanner`, input, await SetHeaderService()).then((res) => {
      return res;
    });
  }
  async DoGetBannerCode() {
    return service.peakAdminAPI.get(`/api/Banner/GetBannerCode`, await SetHeaderService()).then((res) => {
      return res;
    });
  }
  async DoSortBanner(input: SortOrderLatestUpdateRequest[]) {
    return service.peakAdminAPI.post(`/api/Banner/Sort`, input, await SetHeaderService()).then((res) => {
      return res;
    });
  }
}

export default new LatestUpdateService();
