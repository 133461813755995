import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";

@Module({ namespaced: true })
export default class Test extends VuexModule {
  public id: any = null;

  get getId(): any {
    return this.id;
  }

  @Mutation
  public MutationSetId(input: any) {
    this.id = input;
  }

  @Action
  public SetId(input: any) {
    setTimeout(() => {
      this.context.commit("MutationSetId", input);
    }, 50);
  }
}
