































import AuthService from "@/service/authService";
import { Component, Vue, Watch } from "vue-property-decorator";
const auth = new AuthService();
@Component
export default class NavBar extends Vue {
  private drawer = false;
  private active: any = { name: this.$route.meta.title };
  private menuList: any = [];
  profileName = "";

  constructor() {
    super();
    this.initializeMenuList();
  }

  public async initializeMenuList(): Promise<void> {
    const merchantPermission = await this.getAccessPermissions("merchant", "view");
    const promoCampaignPermission = await this.getAccessPermissions("promoCampain", "view");
    const promoCodePermission = await this.getAccessPermissions("promoCode", "view");
    const promoCodeRequestPermission = await this.getAccessPermissions("promoCodeRequest", "view");
    const verifyMerchantPermission = await this.getAccessPermissions("verify", "verify");
    const updateLogoContactPermission = await this.getAccessPermissions("verify", "uploadLogo");
    const userProfilePermission = await this.getAccessPermissions("userProfile", "view");
    const bannerPermission = await this.getAccessPermissions("banner", "view");
    const accountingFirmPermission = await this.getAccessPermissions("accountingFirm", "view");

    this.menuList = [
      {
        name: "Activity Log",
        icon: "mdi-math-log",
        linkName: "home",
        permission: true,
        method: () => this.RedirectToActivityLog(),
      },
      {
        name: "User",
        icon: "mdi-account",
        linkName: "user",
        permission: userProfilePermission,
        method: () => this.RedirectToUser(),
      },
      {
        name: "Merchant",
        icon: "mdi-office-building",
        linkName: "merchant",
        permission: merchantPermission,
        method: () => this.RedirectToMerchant(),
      },
      {
        name: "Package",
        icon: "fas fa-shopping-cart",
        linkName: "package",
        permission: true,
        method: () => this.RedirectToPackage(),
      },
      {
        name: "Accounting Firm",
        icon: "mdi-wrench",
        linkName: "accountingFirmTable",
        permission: accountingFirmPermission,
        method: () => this.AccountingFirm(),
      },
      {
        name: "Promo Campaign",
        icon: "mdi-file-table-box-multiple-outline",
        linkName: "promoCampaign",
        permission: promoCampaignPermission,
        method: () => this.RedirectToPromoCampaign(),
      },
      {
        name: "Promo Code",
        icon: "mdi-sale",
        linkName: "promoCode",
        permission: promoCodePermission,
        method: () => this.RedirectToPromoCode(),
      },
      {
        name: "Promo Code (Request)",
        icon: "mdi-sale",
        linkName: "promoCodeRequest",
        permission: promoCodePermission,
        method: () => this.RedirectToPromoCodeRequest(),
      },
      {
        name: "Verify Merchant",
        icon: "mdi-checkbox-marked-circle",
        linkName: "verifyMerchant",
        permission: verifyMerchantPermission,
        method: () => this.RedirectToVerifyMerchant(),
      },
      {
        name: "Update Logo",
        icon: "mdi-image-edit",
        linkName: "updateLogoContact",
        permission: updateLogoContactPermission,
        method: () => this.RedirectToUpdateLogoContact(),
      },
      {
        name: "Latest Update",
        icon: "mdi-bullhorn",
        linkName: "latestUpdate",
        permission: bannerPermission,
        method: () => this.RedirectToLatestUpdate(),
      },
      {
        name: "Logout",
        icon: "mdi-logout",
        linkName: "logout",
        permission: true,
        method: () => this.Logout(),
      },
    ];
  }

  created() {
    this.checkRedirectPermission(this.$route.name || "");
  }

  public async checkRedirectPermission(route: string) {
    switch (route) {
      case "merchant":
        if (!(await this.getAccessPermissions("merchant", "view"))) {
          this.RedirectToCannotAccess();
        }
        break;
      case "accountingFirmTable":
        if (!(await this.getAccessPermissions("accountingFirm", "view"))) {
          this.RedirectToCannotAccess();
        }
        break;
      case "accountingFirmCreate":
        if (!(await this.getAccessPermissions("accountingFirm", "create"))) {
          this.RedirectToCannotAccess();
        }
        break;
      case "accountingFirmEdit":
        if (!(await this.getAccessPermissions("accountingFirm", "edit"))) {
          this.RedirectToCannotAccess();
        }
        break;
      case "accountingFirmDetail":
        if (!(await this.getAccessPermissions("accountingFirm", "detail"))) {
          this.RedirectToCannotAccess();
        }
        break;
      case "promoCampaign":
        if (!(await this.getAccessPermissions("promoCampaign", "view"))) {
          this.RedirectToCannotAccess();
        }
        break;
      case "promoCode":
        if (!(await this.getAccessPermissions("promoCode", "view"))) {
          this.RedirectToCannotAccess();
        }
        break;
      case "promoCodeRequest":
        if (!(await this.getAccessPermissions("promoCode", "view"))) {
          this.RedirectToCannotAccess();
        }
        break;
      case "verifyMerchant":
        if (!(await this.getAccessPermissions("verify", "verify"))) {
          this.RedirectToCannotAccess();
        }
        break;
      case "package":
        if (!(await this.getAccessPermissions("peakpaymentConnect", "view"))) {
          this.RedirectToCannotAccess();
        }
        break;
      case "packageDetail":
        if (!(await this.getAccessPermissions("peakpaymentConnect", "detail"))) {
          this.RedirectToCannotAccess();
        }
        break;
      case "updateLogoContact":
        if (!(await this.getAccessPermissions("verify", "uploadLogo"))) {
          this.RedirectToCannotAccess();
        }
        break;
      case "latestUpdate":
        if (!(await this.getAccessPermissions("banner", "view"))) {
          this.RedirectToCannotAccess();
        }
        break;
      case "sortOrderLatestUpdate":
        if (!(await this.getAccessPermissions("banner", "sort"))) {
          this.RedirectToCannotAccess();
        }
        break;
      case "createLatestUpdate":
        if (!(await this.getAccessPermissions("banner", "create"))) {
          this.RedirectToCannotAccess();
        }
        break;
      case "editLatestUpdate":
        if (!(await this.getAccessPermissions("banner", "edit"))) {
          this.RedirectToCannotAccess();
        }
        break;
      case "detailLatestUpdate":
        if (!(await this.getAccessPermissions("banner", "detail"))) {
          this.RedirectToCannotAccess();
        }
        break;
      case "changeSuperuser":
        if (!(await this.getAccessPermissions("merchant", "user"))) {
          this.RedirectToCannotAccess();
        }
        break;
      case "user":
        if (!(await this.getAccessPermissions("userProfile", "view"))) {
          this.RedirectToCannotAccess();
        }
        break;
    }
  }

  @Watch("$route")
  changeRoute() {
    this.checkRedirectPermission(this.$route.name || "");
    this.active = { name: this.$route.meta.title };
  }

  public async getAccessPermissions(page: string, view: string): Promise<boolean> {
    const accessPermission = await auth.getPermissions();
    const permission = accessPermission[page][view];
    return permission;
  }

  public changeGroup() {
    this.drawer = false;
  }

  public AccountingFirm() {
    this.checkRedirectPermission("accountingFirmTable");
    this.$router.push({
      name: "accountingFirmTable",
    });
  }
  public Logout() {
    auth.logout();
  }

  public RedirectToActivityLog() {
    this.$router.push({
      name: "activityLog",
    });
  }

  public RedirectToPromoCampaign() {
    this.checkRedirectPermission("promoCampaign");
    this.$router.push({
      name: "promoCampaign",
    });
  }

  public RedirectToPromoCode() {
    this.checkRedirectPermission("promoCode");
    this.$router.push({
      name: "promoCode",
    });
  }

  public RedirectToPromoCodeRequest() {
    this.checkRedirectPermission("promoCodeRequest");
    this.$router.push({
      name: "promoCodeRequest",
    });
  }

  public RedirectToVerifyMerchant() {
    this.checkRedirectPermission("verifyMerchant");
    this.$router.push({
      name: "verifyMerchant",
    });
  }

  public RedirectToUpdateLogoContact() {
    this.checkRedirectPermission("updateLogoContact");
    this.$router.push({
      name: "updateLogoContact",
    });
  }
  public RedirectToLatestUpdate() {
    this.checkRedirectPermission("banner");
    this.$router.push({
      name: "latestUpdate",
    });
  }

  public RedirectToMerchant() {
    this.checkRedirectPermission("merchant");
    this.$router.push({
      name: "merchant",
    });
  }

  public RedirectToUser() {
    this.checkRedirectPermission("userProfile");
    this.$router.push({
      name: "user",
    });
  }

  public RedirectToPackage() {
    this.checkRedirectPermission("package");
    this.$router.push({
      name: "package",
    });
  }

  public RedirectToCannotAccess() {
    this.$router.push({
      name: "cannotAccess",
    });
  }
}
