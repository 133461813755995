import AccountingFirmService from "./accountingFirm/accountingFirmService";
import ActivityLogService from "./activitylog/activitylogService";
import AppConnectService from "./appConnect/appConnectService";
import ChangeSuperuserService from "./changeSuperuser/changeSuperuserService";
import ContactService from "./contact/contactService";
import LatestUpdateService from "./latestUpdate/latestUpdateService";
import MerchantService from "./merchant/merchantService";
import PackageService from "./package/packageService";
import PromoCodeService from "./promoCode/promoCodeService";
import TransactionService from "./transaction/transactionService";
import UserService from "./user/userService";
import ValidateProductStockService from "./validateProductStock/validateProductStockService";

export default {
  MerchantService,
  ActivityLogService,
  ChangeSuperuserService,
  ValidateProductStockService,
  PromoCodeService,
  ContactService,
  AccountingFirmService,
  TransactionService,
  UserService,
  AppConnectService,
  LatestUpdateService,
  PackageService,
};
