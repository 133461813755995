import { SetHeaderService } from "@/helper/merchantHelper";
import { ActivityLogTableViewModel } from "@/model/activitylog/activityLogTableViewModel";
import { ConvertToActivityLogTableRequest } from "@/service/activitylog/activitylogFacade";
import service from "../baseHttpService";

class ActivityLogService {
  async DoGetActivityLogTable(activityLogTableViewModel: ActivityLogTableViewModel) {
    const input = ConvertToActivityLogTableRequest(activityLogTableViewModel);
    return service.peakAdminAPI.post("/api/ActivityLog/GetLog", input, await SetHeaderService()).then((res) => {
      return res;
    });
  }
}

export default new ActivityLogService();
