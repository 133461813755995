import { LoadingStatusType, UserPackageType } from "@/enums/enums";
import { ConvertUserPackageType, UserAFPTable } from "@/model/user/userDetailModel";
import API from "@/service/Index";
import { ConvertToUserModelList } from "@/service/user/userFacade";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({ namespaced: true })
export default class User extends VuexModule {
  public userTableLoadingStatus: LoadingStatusType = LoadingStatusType.None;
  public userTableList: Array<UserAFPTable> | null = null;
  public updateUserLoadingStatus: LoadingStatusType = LoadingStatusType.None;
  public convertUserPackageTypeLoadingStatus: LoadingStatusType = LoadingStatusType.None;

  public countItem: number | null = 0;

  get getUserTableLoadingStatus(): LoadingStatusType {
    return this.userTableLoadingStatus;
  }
  get getUserDetailListModel(): Array<UserAFPTable> | null {
    return this.userTableList;
  }
  get getUpdateUserLoadingStatus(): LoadingStatusType {
    return this.updateUserLoadingStatus;
  }
  get getConvertUserPackageTypeLoadingStatus(): LoadingStatusType {
    return this.convertUserPackageTypeLoadingStatus;
  }
  get getCountItemTable(): number | null {
    return this.countItem;
  }

  @Mutation
  public MutationUserTableLoadingStatus(status: LoadingStatusType) {
    this.userTableLoadingStatus = status;
  }
  @Mutation
  public MutationGetUserDetailListModel(input: any) {
    this.userTableList = input.table;
    this.countItem = input.count;
  }
  @Mutation
  public MutationUserLoadingStatus(status: LoadingStatusType) {
    this.updateUserLoadingStatus = status;
  }
  @Mutation
  public MutationConvertUserPackageTypeLoadingStatus(status: LoadingStatusType) {
    this.convertUserPackageTypeLoadingStatus = status;
  }

  @Action
  public DoGetUserTable(userTableViewModel: any) {
    if (this.userTableLoadingStatus !== LoadingStatusType.Loading) {
      this.context.commit("MutationUserTableLoadingStatus", LoadingStatusType.Loading);
      API.UserService.DoGetUserTable(userTableViewModel).then((res) => {
        if (res.status.IsSuccess()) {
          const table = ConvertToUserModelList(res.data.peakUserTable);
          const countItem = res.data.peakUserTable?.countItem || 0;
          const input = {
            table: table,
            count: countItem,
          };
          this.context.commit("MutationGetUserDetailListModel", input);
          this.context.commit("MutationUserTableLoadingStatus", LoadingStatusType.Success);
        }
      });
    }
  }

  @Action
  public async DoConvertUserPackageType(input: ConvertUserPackageType) {
    if (this.convertUserPackageTypeLoadingStatus !== LoadingStatusType.Loading) {
      this.context.commit("MutationConvertUserPackageTypeLoadingStatus", LoadingStatusType.Loading);
      API.UserService.DoConvertUserPackageType(input).then((res) => {
        let message = "";
        if (res.data.responseCode.IsSuccess()) {
          if (res.data.convertUserPackage) {
            message =
              input.userPackageType === UserPackageType.AFP
                ? "Successfully converted to AFP."
                : "Successfully converted to normal user.";
            alert(message);
          } else {
            message = "convert failed";
            alert(message);
          }
          this.context.commit("MutationConvertUserPackageTypeLoadingStatus", LoadingStatusType.Success);
        } else {
          message = "";
          if (res.data.exception != null) {
            message = res.data.exception.code.GetAdminExceptionResponseMessage();
            alert(message);
          }
          this.context.commit("MutationConvertUserPackageTypeLoadingStatus", LoadingStatusType.None);
        }
      });
    }
  }
}
