import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";

@Module({ namespaced: true })
export default class Loading extends VuexModule {
  public isLoadingStatus = false;

  @Mutation
  public MutationSetIsLoadingStatus(loadingStatus: boolean) {
    this.isLoadingStatus = loadingStatus;
  }

  @Action
  public IsLoading(loadingStatus: boolean) {
    this.context.commit("MutationSetIsLoadingStatus", loadingStatus);
  }

  get getIsLoadingStatus(): boolean {
    return this.isLoadingStatus;
  }
}
