import { UserAFPTable, UserAFPTableDetail } from "@/model/user/userDetailModel";

export function ConvertToUserModelList(data: UserAFPTable) {
  const userTable: UserAFPTable = {
    countItem: data ? data.countItem : 0,
    peakUserTableDetails: [],
  };
  if (data !== null && data.peakUserTableDetails.length > 0) {
    data.peakUserTableDetails.forEach((tableItem: UserAFPTableDetail) => {
      const userTableModel: UserAFPTableDetail = {
        id: tableItem.id,
        userName: tableItem.userName,
        firstName: tableItem.firstName,
        lastName: tableItem.lastName,
        registerDatetime: tableItem.registerDatetime,
        lastLoginDatetime: tableItem.lastLoginDatetime,
        afpConvertDatetime: tableItem.afpConvertDatetime,
        afpDetail: tableItem.afpDetail,
        userPackageType: tableItem.userPackageType,
        affiliateCampaignCode: tableItem.affiliateCampaignCode,
        affiliateCampaignName: tableItem.affiliateCampaignName,
      };
      userTable.peakUserTableDetails.push(userTableModel);
    });
  }
  return userTable;
}
