import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

// const originalPush: any = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location: any) {
//   return originalPush.call(this, location).catch((err: any) => err);
// };

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Login",
    component: () => import("@/views/login/Login.vue"),
    meta: { auth: false },
  },
  {
    path: "/cannotAccess",
    name: "cannotAccess",
    component: () => import("@/views/cannotAccess/CannotAccess.vue"),
    meta: { title: "Cannot Access", auth: false },
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("@/views/home/Home.vue"),
    meta: { title: "Home", auth: false },
  },
  {
    path: "/user",
    name: "user",
    component: () => import("@/views/user/User.vue"),
    meta: { title: "User", auth: true },
  },
  {
    path: "/merchant",
    name: "merchant",
    component: () => import("@/views/merchant/Merchant.vue"),
    meta: { title: "Merchant", auth: true },
  },
  {
    path: "/activityLog",
    name: "activityLog",
    component: () => import("@/views/merchant/ActivityLog.vue"),
    meta: { title: "Activity Log", auth: true },
  },
  {
    path: "/changeSuperuser",
    name: "changeSuperuser",
    component: () => import("@/views/merchant/ChangeSuperuser.vue"),
    meta: { title: "Merchant - User", auth: true },
  },
  {
    path: "/merchantDetail",
    name: "merchantDetail",
    component: () => import("@/views/merchant/MerchantDetail.vue"),
    meta: { title: "Merchant Detail", auth: true },
  },
  {
    path: "/accountingFirmCreate",
    name: "accountingFirmCreate",
    component: () => import("@/views/accountingFirm/AccountingFirmCreate.vue"),
    meta: { title: "Create Accounting Firm Partner", auth: true },
  },
  {
    path: "/accountingFirmEdit",
    name: "accountingFirmEdit",
    component: () => import("@/views/accountingFirm/AccountingFirmEdit.vue"),
    meta: { title: "Edit Accounting Firm Partner", auth: true },
  },
  {
    path: "/accountingFirmDetail",
    name: "accountingFirmDetail",
    component: () => import("@/views/accountingFirm/AccountingFirmDetail.vue"),
    meta: { title: "Accounting Firm Partner", auth: true },
  },
  {
    path: "/accountingFirmTable",
    name: "accountingFirmTable",
    component: () => import("@/views/accountingFirm/AccountingFirmTable.vue"),
    meta: { title: "Accounting Firm Partner", auth: true },
  },
  {
    path: "/promoCampaign",
    name: "promoCampaign",
    component: () => import("@/views/promoCode/PromoCampaign.vue"),
    meta: { title: "Promo Campaign", auth: true },
  },
  {
    path: "/promoCode",
    name: "promoCode",
    component: () => import("@/views/promoCode/PromoCode.vue"),
    meta: { title: "Promo Code", auth: true },
  },
  {
    path: "/promoCodeRequest",
    name: "promoCodeRequest",
    component: () => import("@/views/promoCode/PromoCodeRequest.vue"),
    meta: { title: "Promo Code (Request)", auth: true },
  },
  {
    path: "/promoCodeRequestCreate",
    name: "promoCodeRequestCreate",
    component: () => import("@/views/promoCode/promoCodeRequest/PromoCodeRequestCreate.vue"),
    meta: { title: "Promo Code Request Create", auth: true },
  },
  {
    path: "/promoCodeRequestStatus",
    name: "promoCodeRequestStatus",
    component: () => import("@/views/promoCode/promoCodeRequestStatus/PromoCodeRequestStatus.vue"),
    meta: { title: "Promo Code Request Status", auth: true },
  },
  {
    path: "/promoCodeRequestDetail",
    name: "promoCodeRequestDetail",
    component: () => import("@/views/promoCode/promoCodeRequestDetail/PromoCodeRequestDetail.vue"),
    meta: { title: "Promo Code Request Detail", auth: true },
  },
  {
    path: "/verifyMerchant",
    name: "verifyMerchant",
    component: () => import("@/views/merchant/verifyMerchant/VerifyMerchant.vue"),
    meta: { title: "Verify Merchant", auth: true },
  },
  {
    path: "/package",
    name: "package",
    component: () => import("@/views/package/Package.vue"),
    meta: { title: "Package", auth: true },
  },
  {
    path: "/packageDetail",
    name: "packageDetail",
    component: () => import("@/views/package/PackageDetail.vue"),
    meta: { title: "Package Detail", auth: true },
  },
  {
    path: "/updateLogoContact",
    name: "updateLogoContact",
    component: () => import("@/views/contact/UpdateLogoUrlContactTemplate.vue"),
    meta: { title: "Update Logo Contact", auth: true },
  },
  {
    path: "/latestUpdate",
    name: "latestUpdate",
    component: () => import("@/views/latestUpdate/LatestUpdate.vue"),
    meta: { title: "Latest Update", auth: true },
  },
  {
    path: "/detailLatestUpdate",
    name: "detailLatestUpdate",
    component: () => import("@/views/latestUpdate/DetailLatestUpdate.vue"),
    meta: { title: "Detail Latest Update", auth: true },
  },
  {
    path: "/createLatestUpdate",
    name: "createLatestUpdate",
    component: () => import("@/views/latestUpdate/CreateLatestUpdate.vue"),
    meta: { title: "Create Latest Update", auth: true },
  },
  {
    path: "/editLatestUpdate",
    name: "editLatestUpdate",
    component: () => import("@/views/latestUpdate/EditLatestUpdate.vue"),
    meta: { title: "Edit Latest Update", auth: true },
  },
  {
    path: "/sortOrderLatestUpdate",
    name: "sortOrderLatestUpdate",
    component: () => import("@/views/latestUpdate/SortOrderLatestUpdate.vue"),
    meta: { title: "Sort Order Latest Update", auth: true },
  },
  {
    path: "/userNotFound",
    name: "userNotFound",
    component: () => import("@/views/login/UserNotFound.vue"),
    meta: { title: "User Not Found", auth: true },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
