import { AccountingFirm } from "@/model/accountingFrim/accountingFrimModel";

export function ConvertToInsertAccountingFirmModel(data: any) {
  const InsertAccountingFirmRequest: AccountingFirm = {
    name: data.name,
    nameEn: data.nameEn,
    afpRefCode: data.afpRefCode,
    internalCode: data.internalCode,
    contactName: data.contactName,
    address: data.address,
    phoneNumber: data.phoneNumber,
    email: data.email,
    logoUrl: data.logoUrl
      ? process.env.VUE_APP_PeakBlobStorage + "peakengine" + data.logoUrl + "?" + new Date().getTime()
      : "",
    color: data.color,
    isDeleted: data.isDeleted,
  };
  return InsertAccountingFirmRequest;
}
export function ConvertToUpdateAccountingFirmModel(data: any) {
  const InsertAccountingFirmRequest: AccountingFirm = {
    name: data.name,
    nameEn: data.nameEn,
    afpRefCode: data.afpRefCode,
    internalCode: data.internalCode,
    contactName: data.contactName,
    address: data.address,
    phoneNumber: data.phoneNumber,
    email: data.email,
    deleteLogo: data.deleteLogo,
    logoUrl: data.logoUrl
      ? process.env.VUE_APP_PeakBlobStorage + "peakengine" + data.logoUrl + "?" + new Date().getTime()
      : "",
    color: data.color,
    isDeleted: data.isDeleted,
  };
  return InsertAccountingFirmRequest;
}

export function ConvertToAccountingFirmTableModelList(data: any) {
  const accountingFirmTable: any = {
    tableList: [],
  };
  if (data && data.accountingFirmTableDatas && data.accountingFirmTableDatas.length > 0) {
    data.accountingFirmTableDatas.forEach((tableItem: any) => {
      const accountingFirmTableModel: any = {
        id: tableItem.id,
        internalCode: tableItem.internalCode,
        name: tableItem.name,
        nameEn: tableItem.nameEn,
        phone: tableItem.phone,
        accountingFirmStatus: tableItem.accountingFirmStatus,
        afpRefCode: tableItem.afpRefCode,
      };
      accountingFirmTable.tableList.push(accountingFirmTableModel);
    });
  }
  return accountingFirmTable;
}
