import { ConnectionConfigModel } from "@/model/appConnect/appConnectModel";

export function ConvertToConnectionConfig(merchantConnectionConfig: any) {
  const connectionConfigModel: ConnectionConfigModel = {
    merchantId: merchantConnectionConfig.merchantId,
    shopeeConnectionConfig: {
      maximumConnection: merchantConnectionConfig.shopeeConnectionConfig.maximumConnection,
    },
    lazadaConnectionConfig: {
      maximumConnection: merchantConnectionConfig.lazadaConnectionConfig.maximumConnection,
    },
  };
  return connectionConfigModel;
}
