import { AdminExceptionResponseCode, LoadingStatusType } from "@/enums/enums";
import { SetHeaderMerchantForVerifyTable, SetHeaderMerchantTable } from "@/helper/merchantHelper";
import { MerchantDetailModel } from "@/model/merchant/merchantDetailModel";
import { MerchantDropdownModel } from "@/model/merchant/merchantDropdownModel";
import {
  MerchantDetailForVerifyMerchantRequest,
  MerchantForVerifyList,
  UpdateVerificationStatusMerchantRequest,
} from "@/model/merchant/merchantModel";
import { MerchantTableViewModel } from "@/model/merchant/merchantTableViewModel";
import AuthService from "@/service/authService";
import API from "@/service/Index";
import {
  ConvertToApprovePackageRequestModel,
  ConvertToCancelPeakPaymentConnectByIdRequestModel,
  ConvertToMerchantDetailModelList,
  ConvertToMerchantDropdownModelList,
  ConvertToMerchantListForVerify,
  ConvertToMerchantPeakPackageModel,
} from "@/service/merchant/merchantFacade";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

const auth = new AuthService();

@Module({ namespaced: true })
export default class Merchant extends VuexModule {
  public headerMerchantTable: any = [];
  public merchantTableLoadingStatus: LoadingStatusType = LoadingStatusType.None;
  public merchantDetailModelList: Array<MerchantDetailModel> | null = null;
  public headerMerchantForVerifyTable: any = [];
  public merchantListForVerifyLoadingStatus: LoadingStatusType = LoadingStatusType.None;
  public merchantListForVerify: Array<MerchantForVerifyList> | null = null;
  public countMerchantListForVerify: number | null = 0;
  public merchantListBySuperUserIdLoadingStatus: LoadingStatusType = LoadingStatusType.None;
  public merchantListBySuperUserId: Array<MerchantDropdownModel> | null = null;
  public countItem: number | null = 0;
  public swapMerchantPackageLoadingStatus: LoadingStatusType = LoadingStatusType.None;
  public transferPackageLoadingStatus: LoadingStatusType = LoadingStatusType.None;
  public cancelPeakPackageLoadingStatus: LoadingStatusType = LoadingStatusType.None;
  public updateVerificationStatusLoadingStatus: LoadingStatusType = LoadingStatusType.None;
  public approveUpgradePackageLoadingStatus: LoadingStatusType = LoadingStatusType.None;
  public convertMerchantPackageData: any = null;
  public convertMerchantPackageDataLoadingStatus: LoadingStatusType = LoadingStatusType.None;
  public convertMerchantPackageToAFPLoadingStatus: LoadingStatusType = LoadingStatusType.None;

  public merchantTableView: MerchantTableViewModel = {
    pageSize: 10,
    pageNo: 1,
    languageType: undefined,
    searchText: "",
    promotionCodeSearch: "",
  };

  get getHeaderMerchantTable(): any {
    return this.headerMerchantTable;
  }

  get getMerchantDetailListModel(): Array<MerchantDetailModel> | null {
    return this.merchantDetailModelList;
  }

  get getMerchantTableLoadingStatus(): LoadingStatusType {
    return this.merchantTableLoadingStatus;
  }

  get getHeaderMerchantForVerifyTable(): any {
    return this.headerMerchantForVerifyTable;
  }

  get getMerchantForVerifyList(): Array<MerchantForVerifyList> | null {
    return this.merchantListForVerify;
  }

  get getMerchantListForVerifyLoadingStatus(): LoadingStatusType {
    return this.merchantListForVerifyLoadingStatus;
  }

  get getCountMerchantListForVerify(): number | null {
    return this.countMerchantListForVerify;
  }

  get getMerchantListBySuperUserIdStatus(): LoadingStatusType {
    return this.merchantListBySuperUserIdLoadingStatus;
  }

  get getSwapMerchantPackageLoadingStatus(): LoadingStatusType {
    return this.swapMerchantPackageLoadingStatus;
  }

  get getTransferPackageLoadingStatus(): LoadingStatusType {
    return this.transferPackageLoadingStatus;
  }

  get getCancelPeakPackageLoadingStatus(): LoadingStatusType {
    return this.cancelPeakPackageLoadingStatus;
  }

  get getMerchantListBySuperUserId(): Array<MerchantDropdownModel> | null {
    return this.merchantListBySuperUserId;
  }

  get getCountItemTable(): number | null {
    return this.countItem;
  }

  get getUpdateVerificationStatusLoadingStatus(): LoadingStatusType {
    return this.updateVerificationStatusLoadingStatus;
  }

  get getApproveUpgradePackageLoadingStatus(): LoadingStatusType {
    return this.approveUpgradePackageLoadingStatus;
  }

  get getMerchantTableView(): any {
    return this.merchantTableView;
  }

  get getConvertMerchantPackageData(): any {
    return this.convertMerchantPackageData;
  }

  get getConvertMerchantPackageDataLoadingStatus(): LoadingStatusType {
    return this.convertMerchantPackageDataLoadingStatus;
  }
  get getConvertMerchantPackageToAFPLoadingStatus(): LoadingStatusType {
    return this.convertMerchantPackageToAFPLoadingStatus;
  }

  @Mutation
  public MutationGetMerchantDetailListModel(input: any) {
    this.headerMerchantTable = SetHeaderMerchantTable(input.tableViewModel);
    this.merchantDetailModelList = input.table.tableList;
    this.countItem = input.count;
  }

  @Mutation
  public MutationMerchantTableLoadingStatus(status: LoadingStatusType) {
    this.merchantTableLoadingStatus = status;
  }

  @Mutation
  public SetMerchantForVerifyTable(input: any) {
    this.headerMerchantForVerifyTable = SetHeaderMerchantForVerifyTable(input.tableViewModel);
    this.merchantListForVerify = input.table.tableList;
    this.countMerchantListForVerify = input.count;
  }

  @Mutation
  public SetMerchantListForVerifyLoadingStatus(status: LoadingStatusType) {
    this.merchantListForVerifyLoadingStatus = status;
  }

  @Mutation
  public MutationGetMerchantListBySuperUserIdModel(input: any) {
    this.merchantListBySuperUserId = input;
  }

  @Mutation
  public MutationGetMerchantListBySuperUserIdLoadingStatus(status: LoadingStatusType) {
    this.merchantListBySuperUserIdLoadingStatus = status;
  }

  @Mutation
  public MutationSwapMerchantPackageLoadingStatus(status: LoadingStatusType) {
    this.swapMerchantPackageLoadingStatus = status;
  }

  @Mutation
  public MutationTransferPackageLoadingStatus(status: LoadingStatusType) {
    this.transferPackageLoadingStatus = status;
  }

  @Mutation
  public MutationCancelPeakPackageLoadingStatus(status: LoadingStatusType) {
    this.cancelPeakPackageLoadingStatus = status;
  }

  @Mutation
  public SetUpdateVerificationStatusLoadingStatus(status: LoadingStatusType) {
    this.updateVerificationStatusLoadingStatus = status;
  }

  @Mutation
  public MutationApproveUpgradePackageLoadingStatus(status: LoadingStatusType) {
    this.approveUpgradePackageLoadingStatus = status;
  }

  @Mutation
  public UpdateMerchantTableView(input: MerchantTableViewModel) {
    this.merchantTableView = input;
  }

  @Mutation
  public MutationGetConvertMerchantPackageData(input: any) {
    this.convertMerchantPackageData = input;
  }
  @Mutation
  public MutationConvertMerchantPackageDataLoadingStatus(status: LoadingStatusType) {
    this.convertMerchantPackageDataLoadingStatus = status;
  }
  @Mutation
  public MutationConvertMerchantPackageToAFPLoadingStatus(status: LoadingStatusType) {
    this.convertMerchantPackageToAFPLoadingStatus = status;
  }

  @Action
  public DoGetMerchantTable(merchantTableViewModel: MerchantTableViewModel) {
    if (this.merchantTableLoadingStatus !== LoadingStatusType.Loading) {
      this.context.commit("UpdateMerchantTableView", merchantTableViewModel);
      this.context.commit("MutationMerchantTableLoadingStatus", LoadingStatusType.Loading);
      API.MerchantService.DoGetMerchantTable(merchantTableViewModel).then((res) => {
        if (res.status.IsSuccess()) {
          const table = ConvertToMerchantDetailModelList(res.data.merchantDetailModelList);
          const countItem = res.data.countItem;
          const input = {
            tableViewModel: merchantTableViewModel,
            table: table,
            count: countItem,
          };
          this.context.commit("MutationGetMerchantDetailListModel", input);
          this.context.commit("MutationMerchantTableLoadingStatus", LoadingStatusType.Success);
        }
      });
    }
  }

  @Action
  public DoGetMerchantListForVerify(verifyMerchantRequest: MerchantDetailForVerifyMerchantRequest) {
    if (this.merchantListForVerifyLoadingStatus !== LoadingStatusType.Loading) {
      this.context.commit("SetMerchantListForVerifyLoadingStatus", LoadingStatusType.Loading);
      API.MerchantService.DoGetMerchantListForVerify(verifyMerchantRequest).then((res) => {
        if (res.status.IsSuccess()) {
          const table = ConvertToMerchantListForVerify(res.data.verifyMerchantModelList);
          const countItem = res.data.countItem;
          const input = {
            tableViewModel: verifyMerchantRequest,
            table: table,
            count: countItem,
          };
          this.context.commit("SetMerchantForVerifyTable", input);
          this.context.commit("SetMerchantListForVerifyLoadingStatus", LoadingStatusType.Success);
        }
      });
    }
  }

  @Action
  public async DoUpdateVerificationStatusMerchant(updateVerificationStatus: UpdateVerificationStatusMerchantRequest) {
    if (this.updateVerificationStatusLoadingStatus !== LoadingStatusType.Loading) {
      this.context.commit("SetUpdateVerificationStatusLoadingStatus", LoadingStatusType.Loading);
      this.context.dispatch("Loading/IsLoading", true, { root: true });
      API.MerchantService.DoUpdateVerificationStatusMerchant(updateVerificationStatus).then((res) => {
        let message = "";
        if (res.data.responseCode.IsSuccess()) {
          message = "Verification status has been updated successfully.";
        } else {
          message = "Verification status has been updated failed.";
        }
        alert(message);
        this.context.commit("SetUpdateVerificationStatusLoadingStatus", LoadingStatusType.Success);
        this.context.dispatch("Loading/IsLoading", false, { root: true });
      });
    }
  }

  @Action
  public async DoGetMerchantListBySuperUserId(superUserId: number) {
    if (this.merchantListBySuperUserIdLoadingStatus !== LoadingStatusType.Loading) {
      this.context.commit("MutationGetMerchantListBySuperUserIdLoadingStatus", LoadingStatusType.Loading);
      API.MerchantService.DoGetMerchantListBySuperUserId(superUserId).then((res) => {
        if (res.status.IsSuccess()) {
          const data = ConvertToMerchantDropdownModelList(res.data.merchantDropdownModelList);
          this.context.commit("MutationGetMerchantListBySuperUserIdModel", data);
          this.context.commit("MutationGetMerchantListBySuperUserIdLoadingStatus", LoadingStatusType.Success);
        }
      });
    }
  }

  @Action
  public async DoSwapMerchantPackage(input: any) {
    if (this.swapMerchantPackageLoadingStatus !== LoadingStatusType.Loading) {
      this.context.commit("MutationSwapMerchantPackageLoadingStatus", LoadingStatusType.Loading);
      const merchantPeakPackageModelFrom = ConvertToMerchantPeakPackageModel(input.merchantDetailModel);
      const merchantPeakPackageModelTo = ConvertToMerchantPeakPackageModel(input.merchantDropdownModel);
      const merchantActionOption = { asanaNumber: input.asana, remark: input.remark };
      const swapMerchantPeakPackageRequestModel = {
        merchantPeakPackageModelFrom: merchantPeakPackageModelFrom,
        merchantPeakPackageModelTo: merchantPeakPackageModelTo,
        merchantActionOption: merchantActionOption,
      };
      API.MerchantService.DoSwapMerchantPackage(swapMerchantPeakPackageRequestModel).then((res) => {
        if (res.data.responseCode.IsSuccess()) {
          this.context.commit("MutationSwapMerchantPackageLoadingStatus", LoadingStatusType.Success);
        } else {
          let message = "";
          if (res.data.exception != null) {
            const exceptionCodes = [
              AdminExceptionResponseCode.MerchantIsPackageRecurring,
              AdminExceptionResponseCode.CanNotSwapBetweenNormalPackageAndAfpPackage,
            ];
            message = exceptionCodes.includes(res.data.exception.code)
              ? res.data.exception.errorMessage
              : res.data.exception.code.GetAdminExceptionResponseMessage();
            alert(message);
          }
          this.context.commit("MutationSwapMerchantPackageLoadingStatus", LoadingStatusType.None);
        }
      });
    }
  }

  @Action
  public async DoTransferPackage(input: any) {
    if (this.transferPackageLoadingStatus !== LoadingStatusType.Loading) {
      this.context.commit("MutationTransferPackageLoadingStatus", LoadingStatusType.Loading);
      const merchantPeakPackageModelFrom = ConvertToMerchantPeakPackageModel(input.merchantDetailModel);
      const merchantPeakPackageModelTo = ConvertToMerchantPeakPackageModel(input.merchantDropdownModel);
      const merchantActionOption = { asanaNumber: input.asana, remark: input.remark };
      const transferPeakPackageRequestModel = {
        merchantPeakPackageModelFrom: merchantPeakPackageModelFrom,
        merchantPeakPackageModelTo: merchantPeakPackageModelTo,
        merchantActionOption: merchantActionOption,
      };
      API.MerchantService.DoTransferPackage(transferPeakPackageRequestModel).then((res) => {
        if (res.data.responseCode.IsSuccess()) {
          this.context.commit("MutationTransferPackageLoadingStatus", LoadingStatusType.Success);
          alert("โอนย้ายแพ็กเกจสำเร็จ");
        } else {
          let message = "";
          if (res.data.exception != null) {
            message =
              res.data.exception.code === AdminExceptionResponseCode.MerchantIsPackageRecurring
                ? res.data.exception.errorMessage
                : res.data.exception.code.GetAdminExceptionResponseMessage();
            alert(message);
          }
          this.context.commit("MutationTransferPackageLoadingStatus", LoadingStatusType.None);
        }
      });
    }
  }

  @Action
  public async DoCancelPeakPackage(input: any) {
    if (this.cancelPeakPackageLoadingStatus !== LoadingStatusType.Loading) {
      this.context.commit("MutationCancelPeakPackageLoadingStatus", LoadingStatusType.Loading);
      const convertToCancelPeakPaymentConnectByIdRequestModel = ConvertToCancelPeakPaymentConnectByIdRequestModel(
        input
      );
      API.MerchantService.DoCancelPeakPackage(convertToCancelPeakPaymentConnectByIdRequestModel).then((res) => {
        if (res.data.responseCode.IsSuccess()) {
          this.context.commit("MutationCancelPeakPackageLoadingStatus", LoadingStatusType.Success);
        } else {
          let message = "";
          if (res.data.exception != null) {
            message =
              res.data.exception.code === AdminExceptionResponseCode.PleaseRefreshPageOrSearchAgain
                ? res.data.exception.errorMessage
                : res.data.exception.code.GetAdminExceptionResponseMessage();

            alert(message);
          }
          this.context.commit("MutationCancelPeakPackageLoadingStatus", LoadingStatusType.None);
        }
      });
    }
  }
  @Action
  public async DoApproveUpgradePackage(input: any) {
    if (this.approveUpgradePackageLoadingStatus !== LoadingStatusType.Loading) {
      this.context.commit("MutationApproveUpgradePackageLoadingStatus", LoadingStatusType.Loading);
      const convertToApprovePackageRequestModel = ConvertToApprovePackageRequestModel(input);
      API.MerchantService.DoApproveUpgradePackage(convertToApprovePackageRequestModel).then((res) => {
        if (res.data.responseCode.IsSuccess()) {
          this.context.commit("MutationApproveUpgradePackageLoadingStatus", LoadingStatusType.Success);
        } else {
          let message = "";
          if (!res.data.responseCode.IsInternalServerError()) {
            message = res.data.exception.code.GetAdminExceptionResponseMessage();
            alert(message);
          } else {
            alert("Can not approve package. Please contact the staff.");
          }
          this.context.commit("MutationApproveUpgradePackageLoadingStatus", LoadingStatusType.None);
        }
      });
    }
  }
  @Action
  public async DoGetConvertMerchantPackageType(input: any) {
    if (this.convertMerchantPackageDataLoadingStatus !== LoadingStatusType.Loading) {
      this.context.commit("MutationConvertMerchantPackageDataLoadingStatus", LoadingStatusType.Loading);
      API.MerchantService.DoGetConvertMerchantPackageType(input).then((res) => {
        if (res.data.responseCode.IsSuccess() || res.data.responseCode.IsProcessing()) {
          this.context.commit("MutationGetConvertMerchantPackageData", res.data.convertPackageTypeData);
          this.context.commit("MutationConvertMerchantPackageDataLoadingStatus", LoadingStatusType.Success);
        } else {
          let message = "";
          if (res.data.exception != null) {
            message = res.data.exception.code.GetAdminExceptionResponseMessage();
            alert(message);
          }
          this.context.commit("MutationConvertMerchantPackageDataLoadingStatus", LoadingStatusType.None);
        }
      });
    }
  }

  @Action
  public async DoConvertMerchantToAFP(input: any) {
    if (this.convertMerchantPackageDataLoadingStatus !== LoadingStatusType.Loading) {
      this.context.commit("MutationConvertMerchantPackageToAFPLoadingStatus", LoadingStatusType.Loading);
      API.MerchantService.DoConvertMerchantToAFP(input).then((res) => {
        if (res.data.responseCode.IsSuccess()) {
          this.context.commit("MutationConvertMerchantPackageToAFPLoadingStatus", LoadingStatusType.Success);
        } else {
          let message = "";
          const exceptionCodes = [
            AdminExceptionResponseCode.MerchantIsPackageRecurring,
            AdminExceptionResponseCode.CanNotConvertPackage,
            AdminExceptionResponseCode.CanNotSwapBetweenNormalPackageAndAfpPackage,
          ];
          if (res.data.exception != null) {
            message = exceptionCodes.includes(res.data.exception.code)
              ? res.data.exception.errorMessage
              : res.data.exception.code.GetAdminExceptionResponseMessage();
            alert(message);
          }
          this.context.commit("MutationConvertMerchantPackageToAFPLoadingStatus", LoadingStatusType.None);
        }
      });
    }
  }
}
