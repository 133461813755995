import { LoadingStatusType } from "@/enums/enums";
import API from "@/service/Index";
import { ConvertToPackageTableModelList } from "@/service/package/packageFacade";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({ namespaced: true })
export default class Package extends VuexModule {
  public packageTableLoadingStatus: LoadingStatusType = LoadingStatusType.None;
  public packageTable: Array<any> | null = null;
  public countItem: number | null = 0;
  public packageDetailLoadingStatus: LoadingStatusType = LoadingStatusType.None;
  public packageDetail: any | null = null;
  public packageDocumentDetailLoadingStatus: LoadingStatusType = LoadingStatusType.None;
  public packageDocumentDetail: any | null = null;

  get getPackageTableLoadingStatus(): LoadingStatusType {
    return this.packageTableLoadingStatus;
  }
  get getPackageTable(): any {
    return this.packageTable;
  }
  get getCountItemTable(): number | null {
    return this.countItem;
  }
  get getPackageDetail(): any {
    return this.packageDetail;
  }
  get getPackageDetailLoadingStatus(): LoadingStatusType {
    return this.packageDetailLoadingStatus;
  }
  get getPackageDocumentDetail(): any {
    return this.packageDocumentDetail;
  }
  get getPackageDocumentDetailLoadingStatus(): LoadingStatusType {
    return this.packageDocumentDetailLoadingStatus;
  }

  @Mutation
  public MutationGetPackageTableLoadingStatus(status: LoadingStatusType) {
    this.packageTableLoadingStatus = status;
  }
  @Mutation
  public MutationGetPackageTable(input: any) {
    this.countItem = input.count;
    this.packageTable = input.table.tableList;
  }
  @Mutation
  public MutationGetPackageDetailLoadingStatus(status: LoadingStatusType) {
    this.packageDetailLoadingStatus = status;
  }
  @Mutation
  public MutationGetPackageDetail(input: any) {
    this.packageDetail = input;
  }
  @Mutation
  public MutationGetPackageDocumentDetailLoadingStatus(status: LoadingStatusType) {
    this.packageDocumentDetailLoadingStatus = status;
  }
  @Mutation
  public MutationGetPackageDocumentDetail(input: any) {
    this.packageDocumentDetail = input;
  }

  @Action
  public DoGetPackageTable(_input: any) {
    if (this.packageTableLoadingStatus !== LoadingStatusType.Loading) {
      this.context.commit("MutationGetPackageTableLoadingStatus", LoadingStatusType.Loading);
      API.PackageService.DoGetPackageTable(_input).then((res) => {
        if (res.status.IsSuccess()) {
          const table = ConvertToPackageTableModelList(res.data.data);
          const countItem = res.data.data.count;
          const input = {
            tableViewModel: _input,
            table: table,
            count: countItem,
          };
          this.context.commit("MutationGetPackageTable", input);
          this.context.commit("MutationGetPackageTableLoadingStatus", LoadingStatusType.Success);
        } else {
          let message = "";
          if (res.data.exception != null) {
            message = res.data.exception.code.GetAdminExceptionResponseMessage();
            alert(message);
          }
          this.context.commit("MutationGetPackageTableLoadingStatus", LoadingStatusType.None);
        }
      });
    }
  }
  @Action
  public DoGetPeakPaymentConnectPackageInfo(_input: any) {
    if (this.packageTableLoadingStatus !== LoadingStatusType.Loading) {
      this.context.commit("MutationGetPackageDetailLoadingStatus", LoadingStatusType.Loading);
      API.PackageService.DoGetPeakPaymentConnectPackageInfo(_input).then((res) => {
        if (res.status.IsSuccess()) {
          this.context.commit("MutationGetPackageDetail", res.data.data);
          this.context.commit("MutationGetPackageDetailLoadingStatus", LoadingStatusType.Success);
        } else {
          let message = "";
          if (res.data.exception != null) {
            message = res.data.exception.code.GetAdminExceptionResponseMessage();
            alert(message);
          }
          this.context.commit("MutationGetPackageDetailLoadingStatus", LoadingStatusType.None);
        }
      });
    }
  }
  @Action
  public DoGetPeakPaymentConnectDocumentInfo(_input: any) {
    if (this.packageTableLoadingStatus !== LoadingStatusType.Loading) {
      this.context.commit("MutationGetPackageDocumentDetailLoadingStatus", LoadingStatusType.Loading);
      API.PackageService.DoGetPeakPaymentConnectDocumentInfo(_input).then((res) => {
        if (res.status.IsSuccess()) {
          this.context.commit("MutationGetPackageDocumentDetail", res.data.data.peakPaymentConnectDocumentInfo);
          this.context.commit("MutationGetPackageDocumentDetailLoadingStatus", LoadingStatusType.Success);
        } else {
          let message = "";
          if (res.data.exception != null) {
            message = res.data.exception.code.GetAdminExceptionResponseMessage();
            alert(message);
          }
          this.context.commit("MutationGetPackageDocumentDetailLoadingStatus", LoadingStatusType.None);
        }
      });
    }
  }
}
