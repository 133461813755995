import { SetHeaderService } from "@/helper/merchantHelper";
import {
  PromoCampaignRequest,
  PromoCodeCreateListRequest,
  PromoCodeCreatePatternRequest,
  PromoCodeCreateRequest,
  PromoCodeListRequestModel,
  PromoCodeReferenceTransactionRequest,
  PromoDraftingIndexRequest,
  PromoDraftingRequest,
  PromoRequestElementListRequest,
  PromoRequestTableList,
} from "@/model/promoCode/promoCodeModel";
import service from "../baseHttpService";

class PromoCodeService {
  async DoGetPromoCampaignList() {
    return service.peakAdminAPI
      .post("/api/PromoCode/GetPromoCampaignList", "", await SetHeaderService())
      .then((res) => {
        return res;
      });
  }
  async DoGetPromoCodeList(input: PromoCodeListRequestModel) {
    return service.peakAdminAPI.post("/api/PromoCode/GetPromoCodeList", input, await SetHeaderService()).then((res) => {
      return res;
    });
  }
  async DoCreatePromoCampaign(input: PromoCampaignRequest) {
    return service.peakAdminAPI
      .post("/api/PromoCode/CreatePromoCampaign", input, await SetHeaderService())
      .then((res) => {
        return res;
      });
  }
  async DoCreatePromoCode(input: PromoCodeCreateRequest) {
    return service.peakAdminAPI.post("/api/PromoCode/CreatePromoCode", input, await SetHeaderService()).then((res) => {
      return res;
    });
  }
  async DoCreateListPromoCode(input: PromoCodeCreateListRequest) {
    return service.peakAdminAPI
      .post("/api/PromoCode/CreateListPromoCode", input, await SetHeaderService())
      .then((res) => {
        return res;
      });
  }

  async DoGetPromoRequestTableList(input: PromoRequestTableList) {
    return service.peakAdminAPI
      .post("/api/PromoCode/GetPromoRequestList", input, await SetHeaderService())
      .then((res) => {
        return res;
      });
  }
  async DoGetPromoRequestNumber() {
    return service.peakAdminAPI
      .post("/api/PromoCode/GetPromoRequestNumber", {}, await SetHeaderService())
      .then((res) => {
        return res;
      });
  }
  async DoGeneratePromoCodeRequest(input: PromoCodeCreatePatternRequest) {
    return service.peakAdminAPI
      .post("/api/PromoCode/GeneratePromoCodeRequest", input, await SetHeaderService())
      .then((res) => {
        return res;
      });
  }
  async DoGetReferenceQuotation(input: PromoCodeReferenceTransactionRequest) {
    return service.peakAdminAPI
      .post("/api/PromoCode/GetReferenceQuotation", input, await SetHeaderService())
      .then((res) => {
        return res;
      });
  }
  async DoDraftPromoRequest(input: PromoDraftingRequest) {
    return service.peakAdminAPI
      .post("/api/PromoCode/DraftPromoRequest", input, await SetHeaderService())
      .then((res) => {
        return res;
      });
  }
  async DoGetPromoRequest(input: PromoDraftingIndexRequest) {
    return service.peakAdminAPI.post("/api/PromoCode/GetPromoRequest", input, await SetHeaderService()).then((res) => {
      return res;
    });
  }
  async DoEditPromoRequest(input: PromoDraftingRequest) {
    return service.peakAdminAPI.post("/api/PromoCode/EditPromoRequest", input, await SetHeaderService()).then((res) => {
      return res;
    });
  }
  async DoApprovePromoRequest(input: PromoDraftingIndexRequest) {
    return service.peakAdminAPI
      .post("/api/PromoCode/ApprovePromoRequest", input, await SetHeaderService())
      .then((res) => {
        return res;
      });
  }
  async DoVoidPromoRequest(input: PromoDraftingIndexRequest) {
    return service.peakAdminAPI.post("/api/PromoCode/VoidPromoRequest", input, await SetHeaderService()).then((res) => {
      return res;
    });
  }
  async DoGetPromoCampaignTransactionData(input: PromoRequestElementListRequest) {
    return service.peakAdminAPI
      .post("/api/PromoCode/GetPromoCampaignTransactionData", input, await SetHeaderService())
      .then((res) => {
        return res;
      });
  }
}

export default new PromoCodeService();
