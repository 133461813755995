









import { Component, Vue } from "vue-property-decorator";
import NavBar from "@/components/NavBar.vue";

@Component({ components: { NavBar } })
export default class Layout extends Vue {}
