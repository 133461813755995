import { SetHeaderService } from "@/helper/merchantHelper";
import service from "../baseHttpService";

class TransactionService {
  async GetDuplicateDocumentNumber(input: any) {
    return service.peakAdminAPI
      .post("/api/Transaction/GetDuplicateDocumentNumber", input, await SetHeaderService())
      .then((res) => {
        return res;
      });
  }
  async GetDuplicateDocumentData(input: any) {
    return service.peakAdminAPI
      .post("/api/Transaction/GetDuplicateDocumentData", input, await SetHeaderService())
      .then((res) => {
        return res;
      });
  }
}

export default new TransactionService();
