/* eslint-disable */
import jwt from "jsonwebtoken";
import { User, UserManager, WebStorageStateStore } from "oidc-client";

export default class AuthService {
  private userManager: UserManager;

  constructor() {
    const authDomain: string = process.env.VUE_APP_PeakAuthenService || "";
    const appDomain = process.env.VUE_APP_PeakAdmin;

    const settings: any = {
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      authority: authDomain,
      client_id: "adminJs",
      redirect_uri: appDomain + "callback.html",
      automaticSilentRenew: true,
      silent_redirect_uri: appDomain + "silent-renew.html",
      response_type: "code",
      scope: "openid profile admin.full admin.api",
      post_logout_redirect_uri: appDomain,
      filterProtocolClaims: true,
    };

    this.userManager = new UserManager(settings);
  }

  public getUser(): Promise<User | null> {
    return this.userManager.getUser();
  }

  public login(): Promise<void> {
    return this.userManager.signinRedirect();
  }

  public logout(): Promise<void> {
    return this.userManager.signoutRedirect();
  }

  public getAccessToken(): Promise<string> {
    return this.userManager.getUser().then((data: any) => {
      return data.access_token;
    });
  }

  public getPermissions(): Promise<any> {
    return this.userManager.getUser().then((data: any) => {
      const decodedToken: any = jwt.decode(data.access_token);
      const merchantPermissions = decodedToken["merchant.permissions"] || "";
      const promoCampainPermissions = decodedToken["promoCampain.permissions"] || "";
      const promoCodePermissions = decodedToken["promoCode.permissions"] || "";
      const promoCodeRequestPermissions = decodedToken["promoCodeRequest.permissions"] || "";
      const verifyPermissions = decodedToken["verify.permissions"] || "";
      const userProfilePermissions = decodedToken["userProfile.permissions"] || "";
      const multiShopConfigPermissions = decodedToken["multiShopConfig.permissions"] || "";
      const bannerPermissions = decodedToken["banner.permissions"] || "";
      const accountingFirmPermissions = decodedToken["accountingFirm.permissions"] || "";
      const peakpaymentConnectPermissions = decodedToken["peakpaymentConnect.permissions"] || "";
      const accessPermission: any = {
        merchant: {
          view: false,
          recheckProduct: false,
          swapPackage: false,
          tranferPackage: false,
          costOfSales: false,
          approvePackage: false,
          stock: false,
          superUser: false,
          user: false,
          viewReceipt: false,
          viewWitholdingTax: false,
          cancelPackage: false,
          addSupport: false,
          removeSupport: false,
          convertMerchantPackageType: false,
        },
        userProfile: {
          view: false,
          convertUserPackageType: false,
        },
        promoCampain: {
          view: false,
          create: false,
        },
        promoCode: {
          view: false,
          create: false,
          draftRequest: false,
          viewRequest: false,
          approveRequest: false,
          voidRequest: false,
        },
        promoCodeRequest: {
          view: false,
          create: false,
        },
        verify: {
          verify: false,
          uploadLogo: false,
        },
        multiShopConfig: {
          get: false,
          create: false,
          update: false,
          delete: false,
        },
        banner: {
          view: false,
          create: false,
          edit: false,
          sort: false,
          delete: false,
          detail: false,
        },
        accountingFirm: {
          view: false,
          create: false,
          edit: false,
          delete: false,
          detail: false,
        },
        peakpaymentConnect: {
          view: false,
          detail: false,
        },
      };

      // Parse merchant permissions
      const merchantPermissionsArr = merchantPermissions.split(",").map((item: string) => item.trim().split(".")[1]);
      merchantPermissionsArr.forEach((permission: any) => {
        if (accessPermission.merchant.hasOwnProperty(permission)) {
          accessPermission.merchant[permission] = true;
        }
      });

      // Parse promoCampain permissions
      const promoCampainPermissionsArr = promoCampainPermissions
        .split(",")
        .map((item: string) => item.trim().split(".")[1]);
      promoCampainPermissionsArr.forEach((permission: any) => {
        if (accessPermission.promoCampain.hasOwnProperty(permission)) {
          accessPermission.promoCampain[permission] = true;
        }
      });

      // Parse promoCode permissions
      const promoCodePermissionsArr = promoCodePermissions.split(",").map((item: string) => item.trim().split(".")[1]);
      promoCodePermissionsArr.forEach((permission: any) => {
        if (accessPermission.promoCode.hasOwnProperty(permission)) {
          accessPermission.promoCode[permission] = true;
        }
      });

      // Parse promoCode permissions
      // const promoCodeRequestPermissionsArr = promoCodeRequestPermissions.split(",").map((item: string) => item.trim().split(".")[1]);
      // promoCodePermissionsArr.forEach((permission: any) => {
      //   if (accessPermission.promoCode.hasOwnProperty(permission)) {
      //     accessPermission.promoCode[permission] = true;
      //   }
      // });

      // Parse verify permissions
      const verifyPermissionsArr = verifyPermissions.split(",").map((item: string) => item.trim().split(".")[1]);
      verifyPermissionsArr.forEach((permission: any) => {
        if (accessPermission.verify.hasOwnProperty(permission)) {
          accessPermission.verify[permission] = true;
        }
      });

      // Parse user Profile permissions
      const userProfilePermissionsArr = userProfilePermissions
        .split(",")
        .map((item: string) => item.trim().split(".")[1]);
      userProfilePermissionsArr.forEach((permission: any) => {
        if (accessPermission.userProfile.hasOwnProperty(permission)) {
          accessPermission.userProfile[permission] = true;
        }
      });

      // Parse multiShopConfig permissions
      const multiShopConfigPermissionsArr = multiShopConfigPermissions
        .split(",")
        .map((item: string) => item.trim().split(".")[1]);
      multiShopConfigPermissionsArr.forEach((permission: any) => {
        if (accessPermission.multiShopConfig.hasOwnProperty(permission)) {
          accessPermission.multiShopConfig[permission] = true;
        }
      });

      // Parse bannerPermissions permissions
      const bannerPermissionsArr = bannerPermissions.split(",").map((item: string) => item.trim().split(".")[1]);
      bannerPermissionsArr.forEach((permission: any) => {
        if (accessPermission.banner.hasOwnProperty(permission)) {
          accessPermission.banner[permission] = true;
        }
      });
      // Parse accountingFirmPermissions permissions
      const accountingFirmPermissionsArr = accountingFirmPermissions
        .split(",")
        .map((item: string) => item.trim().split(".")[1]);
      accountingFirmPermissionsArr.forEach((permission: any) => {
        if (accessPermission.accountingFirm.hasOwnProperty(permission)) {
          accessPermission.accountingFirm[permission] = true;
        }
      });

      // Parse peakpaymentConnect permissions
      const multiPeakpaymentConnectPermissionsArr = peakpaymentConnectPermissions
        .split(",")
        .map((item: string) => item.trim().split(".")[1]);
      multiPeakpaymentConnectPermissionsArr.forEach((permission: any) => {
        if (accessPermission.peakpaymentConnect.hasOwnProperty(permission)) {
          accessPermission.peakpaymentConnect[permission] = true;
        }
      });
      return accessPermission;
    });
  }
}
