import { LoadingStatusType } from "@/enums/enums";
import { ActivityLogDetailModel } from "@/model/activitylog/activitylogDetailModel";
import { ActivityLogTableViewModel } from "@/model/activitylog/activityLogTableViewModel";
import { ConvertToActivityLogDetailModelList } from "@/service/activitylog/activitylogFacade";
import API from "@/service/Index";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({ namespaced: true })
export default class ActivityLog extends VuexModule {
  public headerMerchantTable: any = [];
  public activityLogTableLoadingStatus: LoadingStatusType = LoadingStatusType.None;
  public activityLogDetailModels: Array<ActivityLogDetailModel> | null = null;
  public countItem: number | null = 0;

  get getHeaderMerchantTable(): any {
    return this.headerMerchantTable;
  }

  get getActivityLogDetailListModel(): Array<ActivityLogDetailModel> | null {
    return this.activityLogDetailModels;
  }

  get getActivityLogTableLoadingStatus(): LoadingStatusType {
    return this.activityLogTableLoadingStatus;
  }

  get getCountItemTable(): number | null {
    return this.countItem;
  }

  @Mutation
  public MutationGetActivityLogDetailListModel(input: any) {
    //this.headerMerchantTable = SetHeaderMerchantTable(input.tableViewModel);
    this.activityLogDetailModels = input.table.tableList;
    this.countItem = input.count;
  }

  @Mutation
  public MutationActivityLogTableLoadingStatus(status: LoadingStatusType) {
    this.activityLogTableLoadingStatus = status;
  }

  @Action
  public DoGetActivityLogTable(activityLogTableViewModel: ActivityLogTableViewModel) {
    if (this.activityLogTableLoadingStatus !== LoadingStatusType.Loading) {
      this.context.commit("MutationActivityLogTableLoadingStatus", LoadingStatusType.Loading);
      API.ActivityLogService.DoGetActivityLogTable(activityLogTableViewModel).then((res) => {
        if (res.status.IsSuccess()) {
          const table = ConvertToActivityLogDetailModelList(res.data.loggingTable);
          const countItem = res.data.loggingTable.countItem;
          const input = {
            tableViewModel: activityLogTableViewModel,
            table: table,
            count: countItem,
          };
          this.context.commit("MutationGetActivityLogDetailListModel", input);
          this.context.commit("MutationActivityLogTableLoadingStatus", LoadingStatusType.Success);
        }
      });
    }
  }
}
