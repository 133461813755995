import { SetHeaderService } from "@/helper/merchantHelper";
import { ConvertUserPackageType, UserAFPTableRequest } from "@/model/user/userDetailModel";
import service from "../baseHttpService";

class UserService {
  async DoGetUserTable(UserAFPTableRequest: UserAFPTableRequest) {
    return service.peakAdminAPI
      .post("/api/User/GetUserProfile", UserAFPTableRequest, await SetHeaderService())
      .then((res) => {
        return res;
      });
  }
  async DoConvertUserPackageType(input: ConvertUserPackageType) {
    return service.peakAdminAPI
      .post("/api/User/ConvertUserPackageType", input, await SetHeaderService())
      .then((res) => {
        return res;
      });
  }
}

export default new UserService();
