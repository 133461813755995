import {
  CreatePromoCodeRequestType,
  LoadingStatusType,
  PromoCampaignType,
  PromoCodeCreateLevelType,
  SendPromoRequestType,
} from "@/enums/enums";
import {
  ConvertPromoRequestToPromoRequestCreate,
  ConvertToPromoDraftRequest,
  GetPromoCodeConditionText,
  GetPromoCodeRequestCreateByCreateTemplate,
  GetPromoCodeRequestCreateBySelectTemplate,
  GetPromoCodeRequestObjectTemplate,
  ValidateCampaignSelect,
  ValidateCreatePromoCampaignForRequest,
  ValidateIsPromoCodeDuplicate,
  ValidatePromoCodeRequest,
} from "@/helper/promoCodeHelper";
import { NewObject } from "@/helper/shareHelper";
import {
  PromoCampaignModel,
  PromoCodeRequestCreateByCreateModel,
  PromoCodeRequestCreateBySelectModel,
  PromoCodeRequestCreateListModel,
  PromoCodeRequestDetailModel,
  PromoCodeRequest as PromoCodeRequestModel,
} from "@/model/promoCode/promoCodeModel";
import store from "@/store";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
@Module({ namespaced: true })
export default class PromoCodeRequest extends VuexModule {
  private campaignList: PromoCodeRequestCreateListModel = [];
  private promoCodeItemTemplate: PromoCodeRequestModel = GetPromoCodeRequestObjectTemplate();
  private promoCodeRequestCreateBySelectTemplate: PromoCodeRequestCreateBySelectModel = GetPromoCodeRequestCreateBySelectTemplate();
  private promoCodeRequestCreateByCreateTemplate: PromoCodeRequestCreateByCreateModel = GetPromoCodeRequestCreateByCreateTemplate();
  private isCreatePromoCodeError = false;
  private convertPromoCodeLoadingStatus: LoadingStatusType = LoadingStatusType.None;

  get getCampaignList() {
    return this.campaignList;
  }

  get getIsCreatePromoCodeError() {
    return this.isCreatePromoCodeError;
  }

  get getCampaignListLength() {
    return this.campaignList.length;
  }

  get getPromoCodeList() {
    return (campaignIndex: number) => this.campaignList[campaignIndex].promoCodeList;
  }

  get getPromoCodeCreateLevel() {
    return (campaignIndex: number) => this.campaignList[campaignIndex].promoCodeCreateLevel;
  }
  get getPromoCodeListLength() {
    return (campaignIndex: number) => this.campaignList[campaignIndex].promoCodeList.length;
  }

  get getPromoCampaignRequest() {
    return (campaignIndex: number) => {
      return this.campaignList[campaignIndex].campaignRequest;
    };
  }

  get getConvertPromoCodeLoadingStatus() {
    return this.convertPromoCodeLoadingStatus;
  }

  get getPromoCampaignType() {
    return (campaignIndex: number) => {
      return this.campaignList[campaignIndex].campaignRequest.type;
    };
  }

  get getPromoCampaignSelectedDetail() {
    return (campaignIndex: number) => {
      return this.campaignList[campaignIndex].campaign.value;
    };
  }

  get getConditionPromoCampaignSelectedDetail() {
    return (campaignIndex: number) => {
      if (this.campaignList[campaignIndex].campaign.value.id == 0) {
        return "";
      }
      return GetPromoCodeConditionText(this.campaignList[campaignIndex].campaign.value.conditionList);
    };
  }

  @Mutation
  private UpdatePromoCodeCreateLevel({
    campaignIndex,
    type,
  }: {
    campaignIndex: number;
    type: PromoCodeCreateLevelType;
  }) {
    this.campaignList[campaignIndex].promoCodeCreateLevel = type;
  }

  @Mutation
  private SetConvertPromoCodeLoadingStatus(status: LoadingStatusType) {
    this.convertPromoCodeLoadingStatus = status;
  }

  @Mutation
  private UpdateIsCreatePromoCodeError(value: boolean) {
    this.isCreatePromoCodeError = value;
  }

  @Mutation
  private UpdateCampaignRequestType({ campaignIndex, type }: { campaignIndex: number; type: PromoCampaignType }) {
    this.campaignList[campaignIndex].campaignRequest.type = type;
  }

  @Mutation
  private SetStartCampaignList() {
    this.campaignList = [NewObject(this.promoCodeRequestCreateBySelectTemplate)];
  }

  @Mutation
  private PushOneCampaignList() {
    this.campaignList.push(NewObject(this.promoCodeRequestCreateBySelectTemplate));
  }

  @Mutation
  private RemoveSelectedCampaignList(index: number) {
    this.campaignList.splice(index, 1);
  }

  @Mutation
  private AddOnePromoCode(campaignIndex: number) {
    this.campaignList[campaignIndex].promoCodeList.push(NewObject(this.promoCodeItemTemplate));
  }

  @Mutation
  private ResetPromoCodeCheckboxes(campaignIndex: number) {
    const checkboxNames = ["fixUserId", "fixMerchantId", "fixFirmId", "fixClientId"];
    this.campaignList[campaignIndex].promoCodeList.forEach((item: Record<string, any>) => {
      checkboxNames.forEach((key: string) => {
        if (item[key].isCheck) {
          item[key].isCheck = false;
          item[key].id.value = "";
        }
      });
    });
  }

  @Mutation
  private UpdateCheckboxValue({
    campaignIndex,
    promoCodeListIndex,
    key,
    value,
  }: {
    campaignIndex: number;
    promoCodeListIndex: number;
    key: string;
    value: any;
  }) {
    this.campaignList[campaignIndex].promoCodeList[promoCodeListIndex][key].id.value = value;
  }

  @Mutation
  private RemoveSelectedPromoCode({
    campaignIndex,
    promoCodeIndex,
  }: {
    campaignIndex: number;
    promoCodeIndex: number;
  }) {
    if (this.campaignList[campaignIndex].promoCodeList.length > 1) {
      this.campaignList[campaignIndex].promoCodeList.splice(promoCodeIndex, 1);
    }
  }

  @Mutation
  private UpdateCreatePromoCodeRequestType({
    type,
    campaignIndex,
  }: {
    type: CreatePromoCodeRequestType;
    campaignIndex: number;
  }) {
    const oldPromoCodeList = [...this.campaignList[campaignIndex].promoCodeList];
    switch (type) {
      case CreatePromoCodeRequestType.Select:
        this.campaignList.splice(campaignIndex, 1, NewObject(this.promoCodeRequestCreateBySelectTemplate));
        this.campaignList[campaignIndex].promoCodeList = oldPromoCodeList;
        break;
      case CreatePromoCodeRequestType.Create:
        this.campaignList.splice(campaignIndex, 1, NewObject(this.promoCodeRequestCreateByCreateTemplate));
        this.campaignList[campaignIndex].promoCodeList = oldPromoCodeList;
        break;
    }
  }

  @Mutation
  UpdateCampaignSelectByCreate({ campaignIndex, value }: { campaignIndex: number; value: PromoCampaignModel }) {
    this.campaignList[campaignIndex].campaign.value = value;
  }

  @Mutation
  UpdatePromoCodeValue({
    campaignIndex,
    promoCodeListIndex,
    key,
    value,
  }: {
    campaignIndex: number;
    promoCodeListIndex: number;
    key: string;
    value: any;
  }) {
    this.campaignList[campaignIndex].promoCodeList[promoCodeListIndex][key].value = value;
  }

  @Mutation
  UpdatePromoCodeCampaignCreateDetail({
    campaignIndex,
    key,
    value,
  }: {
    campaignIndex: number;
    key: string;
    value: boolean;
  }) {
    this.campaignList[campaignIndex].campaignRequest[key] = value;
  }

  @Mutation
  UpdatePromoCodeCampaignCreateValue({
    campaignIndex,
    key,
    value,
  }: {
    campaignIndex: number;
    key: string;
    value: any;
  }) {
    if (key == "packageId") {
      this.campaignList[campaignIndex].campaignRequest.packageId = value;
    } else {
      this.campaignList[campaignIndex].campaignRequest[key].value = value;
    }
  }

  @Mutation
  UpdatePromoCodeCampaignCreateValueFormatMoney({
    campaignIndex,
    key,
  }: {
    campaignIndex: number;
    key: "discountNumber" | "discountPercent" | "packagePrice";
  }) {
    this.campaignList[campaignIndex].campaignRequest[key].value = this.campaignList[campaignIndex].campaignRequest[
      key
    ].value.FormatMoney();
  }

  @Mutation
  UpdateCheckBoxIsCheck({
    campaignIndex,
    promoCodeListIndex,
    key,
    value,
  }: {
    campaignIndex: number;
    promoCodeListIndex: number;
    key: string;
    value: any;
  }) {
    this.campaignList[campaignIndex].promoCodeList[promoCodeListIndex][key].isCheck = value;
  }

  @Mutation
  ResetOneCampaignPrivilegeAndDiscount({
    campaignIndex,
    isPeriodTypeChange,
  }: {
    campaignIndex: number;
    isPeriodTypeChange: boolean;
  }) {
    const resetStringTemplate = {
      value: "0".FormatMoney(),
      isValidateError: false,
    };
    const resetNumberTemplate = {
      value: "0",
      isValidateError: false,
    };
    const resetNullTemplate = {
      value: "",
      isValidateError: false,
    };

    this.campaignList[campaignIndex].campaignRequest.discountNumber = { ...resetStringTemplate };
    this.campaignList[campaignIndex].campaignRequest.discountPercent = { ...resetStringTemplate };
    this.campaignList[campaignIndex].campaignRequest.packagePrice = { ...resetStringTemplate };
    this.campaignList[campaignIndex].campaignRequest.packagePeriod = { ...resetNumberTemplate, isDisable: false };
    this.campaignList[campaignIndex].campaignRequest.packagePeriodDay = { ...resetNumberTemplate };
    this.campaignList[campaignIndex].campaignRequest.packagePeriodExtra = { value: "0", isValidateError: false };
    this.campaignList[campaignIndex].campaignRequest.packageExpiredDate = { ...resetNullTemplate, isShowModal: false };
  }

  @Mutation
  ValidatePromoCodeOneCampaignOnePromoCode({
    campaignIndex,
    promoCodeListIndex,
  }: {
    campaignIndex: number;
    promoCodeListIndex: number;
  }) {
    const isValidateErrorPromoCodeError = ValidatePromoCodeRequest(
      this.campaignList[campaignIndex].promoCodeList[promoCodeListIndex]
    );
    const isPromoCodeDuplicate = ValidateIsPromoCodeDuplicate(this.campaignList);
    const isError = isValidateErrorPromoCodeError || isPromoCodeDuplicate;

    this.isCreatePromoCodeError = isError;
  }

  @Mutation
  ValidateCampaignRequest({ campaignIndex }: { campaignIndex: number }) {
    const isError = ValidateCreatePromoCampaignForRequest(
      this.campaignList[campaignIndex].campaignRequest,
      store.getters["PromoCode/getPromoCampaignList"]
    );
    this.isCreatePromoCodeError = isError;
  }

  @Mutation
  ValidateAllCampaigns() {
    let isAllError = false;
    this.campaignList.forEach((item: any, campaignIndex: number) => {
      let isError = false;
      switch (item.promoCodeCampaignCreateType) {
        case CreatePromoCodeRequestType.Select:
          isError = ValidateCampaignSelect(item.campaign);
          if (isError) {
            isAllError = true;
          }
          break;
        case CreatePromoCodeRequestType.Create:
          store.commit("PromoCodeRequest/ValidateCampaignRequest", { campaignIndex });
          break;
      }
    });
    this.isCreatePromoCodeError = isAllError;
  }

  @Mutation
  ValidatePromoCodeAllCampaignAllPromoCode() {
    let isAllError = false;
    this.campaignList.forEach((item: any, campaignIndex: number) => {
      let isError = false;
      switch (item.promoCodeCampaignCreateType) {
        case CreatePromoCodeRequestType.Select:
          isError = ValidateCampaignSelect(item.campaign);
          if (isError) {
            isAllError = true;
          }
          break;
        case CreatePromoCodeRequestType.Create:
          isError = ValidateCreatePromoCampaignForRequest(
            this.campaignList[campaignIndex].campaignRequest,
            store.getters["PromoCode/getPromoCampaignList"]
          );
          if (isError) {
            isAllError = true;
          }
          break;
      }
    });
    this.campaignList.forEach((campaign: Record<string, any>) => {
      campaign.promoCodeList.forEach((promoCode: any) => {
        const isError = ValidatePromoCodeRequest(promoCode);
        if (isError) {
          isAllError = isError;
        }
      });
    });
    const isDuplicatePromoCode = ValidateIsPromoCodeDuplicate(this.campaignList);
    isAllError = isAllError || isDuplicatePromoCode;
    this.isCreatePromoCodeError = isAllError;
  }

  @Mutation
  UpdateDateModalValue({
    campaignIndex,
    promoCodeListIndex,
    key,
    value,
  }: {
    campaignIndex: number;
    promoCodeListIndex: number;
    key: "startDate" | "expireDate";
    value: boolean;
  }) {
    this.campaignList[campaignIndex].promoCodeList[promoCodeListIndex][key].modal = value;
  }

  @Mutation
  UpdatePackageExpireDateValue({
    campaignIndex,
    key,
    value,
  }: {
    campaignIndex: number;
    key: string;
    value: string | boolean;
  }) {
    this.campaignList[campaignIndex].campaignRequest.packageExpiredDate[key] = value;
  }

  @Mutation
  UpdateCampaignListFromPromoRequest(promoRequest: PromoCodeRequestDetailModel) {
    this.campaignList = ConvertPromoRequestToPromoRequestCreate(
      promoRequest,
      store.getters["PromoCode/getPromoCampaignList"]
    );
  }

  @Mutation
  MutationResetAllPromoCodeRequest() {
    this.campaignList = [];
  }

  @Mutation
  MutationSetPromoCodeDuplicateError(duplicateCode: string[]) {
    this.campaignList.forEach((campaign) => {
      campaign.promoCodeList.forEach((code) => {
        if (duplicateCode.includes(code.promoCode.value)) {
          code.promoCode.isValidateError = true;
        }
      });
    });
  }

  @Action
  public SetDefaultCampaignList() {
    this.context.commit("SetStartCampaignList");
  }

  @Action AddOneCampaignList() {
    this.context.commit("PushOneCampaignList");
  }

  @Action
  public RemoveCampaignList(index: number) {
    this.context.commit("RemoveSelectedCampaignList", index);
  }

  @Action
  public SetCampaignRequestType({ type, campaignIndex }: { type: CreatePromoCodeRequestType; campaignIndex: number }) {
    this.context.commit("UpdateCreatePromoCodeRequestType", { type, campaignIndex });
  }

  @Action
  public AddPromoCode(campaignIndex: number) {
    this.context.commit("AddOnePromoCode", campaignIndex);
  }

  @Action
  public RemovePromoCode({ campaignIndex, promoCodeIndex }: { campaignIndex: number; promoCodeIndex: number }) {
    this.context.commit("RemoveSelectedPromoCode", { campaignIndex, promoCodeIndex });
  }

  @Action
  public ResetPromoCodeCheckbox(campaignIndex: number) {
    this.context.commit("ResetPromoCodeCheckboxes", campaignIndex);
  }

  @Action
  public ResetAllPromoCodeRequest() {
    this.context.commit("MutationResetAllPromoCodeRequest");
  }

  @Action
  UpdatePromoCode({
    campaignIndex,
    promoCodeListIndex,
    key,
    value,
  }: {
    campaignIndex: number;
    promoCodeListIndex: number;
    key: string;
    value: string;
  }) {
    const _input = { campaignIndex, promoCodeListIndex, key, value };
    this.context.commit("UpdatePromoCodeValue", _input);
  }

  @Action
  UpdateCampaignRequestDetail({ campaignIndex, key, value }: { campaignIndex: number; key: string; value: boolean }) {
    const _input = {
      campaignIndex,
      key,
      value,
    };
    this.context.commit("UpdatePromoCodeCampaignCreateDetail", _input);
  }

  @Action
  UpdateCampaignRequestValue({ campaignIndex, key, value }: { campaignIndex: number; key: string; value: boolean }) {
    const _input = {
      campaignIndex,
      key,
      value,
    };
    this.context.commit("UpdatePromoCodeCampaignCreateValue", _input);
  }

  @Action
  UpdateCampaignType({ campaignIndex, type }: { campaignIndex: number; type: PromoCampaignType }) {
    this.context.commit("UpdateCampaignRequestType", { campaignIndex, type });
  }

  @Action
  UpdatePromoCodeStartDate({
    campaignIndex,
    promoCodeListIndex,
    value,
  }: {
    campaignIndex: number;
    promoCodeListIndex: number;
    value: string;
  }) {
    const _input = {
      campaignIndex,
      promoCodeListIndex,
      value,
      key: "startDate",
    };
    this.context.commit("UpdatePromoCodeValue", _input);
  }

  @Action
  UpdatePromoCodeExpireDate({
    campaignIndex,
    promoCodeListIndex,
    value,
  }: {
    campaignIndex: number;
    promoCodeListIndex: number;
    value: string;
  }) {
    const _input = {
      campaignIndex,
      promoCodeListIndex,
      value,
      key: "expireDate",
    };
    this.context.commit("UpdatePromoCodeValue", _input);
  }

  @Action
  SetCheckBoxIsCheck({
    campaignIndex,
    promoCodeListIndex,
    key,
    value,
  }: {
    campaignIndex: number;
    promoCodeListIndex: number;
    key: string;
    value: any;
  }) {
    const _input = {
      campaignIndex,
      promoCodeListIndex,
      key,
      value,
    };
    this.context.commit("UpdateCheckBoxIsCheck", _input);
  }
  @Action
  SetCheckboxValue({
    campaignIndex,
    promoCodeListIndex,
    key,
    value,
  }: {
    campaignIndex: number;
    promoCodeListIndex: number;
    key: string;
    value: any;
  }) {
    const _input = {
      campaignIndex,
      promoCodeListIndex,
      key,
      value,
    };
    this.context.commit("UpdateCheckboxValue", _input);
  }

  @Action({ rawError: true })
  SetDateModalOpen({
    campaignIndex,
    promoCodeListIndex,
    key,
  }: {
    campaignIndex: number;
    promoCodeListIndex: number;
    key: "startDate" | "expireDate";
  }) {
    const input = {
      campaignIndex,
      promoCodeListIndex,
      key,
      value: true,
    };
    this.context.commit("UpdateDateModalValue", input);
  }
  @Action
  SetDateModalClose({
    campaignIndex,
    promoCodeListIndex,
    key,
  }: {
    campaignIndex: number;
    promoCodeListIndex: number;
    key: "startDate" | "expireDate";
  }) {
    const input = {
      campaignIndex,
      promoCodeListIndex,
      key,
      value: false,
    };
    this.context.commit("UpdateDateModalValue", input);
  }

  @Action
  DoSendDraftPromoRequest(input: any) {
    if (!this.isCreatePromoCodeError) {
      store.dispatch(
        "PromoCode/DoDraftPromoRequest",
        ConvertToPromoDraftRequest(this.campaignList, input, SendPromoRequestType.CreateDraft)
      );
    }
  }

  @Action
  DoSendEditPromoRequest(input: any) {
    if (!this.isCreatePromoCodeError) {
      store.dispatch(
        "PromoCode/DoEditPromoRequest",
        ConvertToPromoDraftRequest(this.campaignList, input, SendPromoRequestType.SendEdit)
      );
    }
  }

  @Action
  UpdateAmountDiscount({ discountValue, campaignIndex }: { discountValue: number; campaignIndex: number }) {
    this.campaignList[campaignIndex].promoCodeList.forEach((item: any, index: number) => {
      const input = {
        campaignIndex,
        promoCodeListIndex: index,
        key: "amount",
        value: discountValue,
      };
      this.context.commit("UpdatePromoCodeValue", input);
    });
  }

  @Action
  UpdateFormatMoney({
    campaignIndex,
    key,
  }: {
    campaignIndex: number;
    key: "discountNumber" | "discountPercent" | "packagePrice";
  }) {
    const _input = {
      campaignIndex,
      key,
    };
    this.context.commit("UpdatePromoCodeCampaignCreateValueFormatMoney", _input);
  }

  @Action
  DoUpdateCampaignListFromPromoRequest(promoRequest: PromoCodeRequestDetailModel) {
    this.context.commit("SetConvertPromoCodeLoadingStatus", LoadingStatusType.Loading);
    this.context.commit("UpdateCampaignListFromPromoRequest", promoRequest);
    this.context.commit("SetConvertPromoCodeLoadingStatus", LoadingStatusType.Success);
  }

  @Action
  SetUpdatePromoCodeCreateLevel(input: { type: PromoCodeCreateLevelType; campaignIndex: number }) {
    this.context.commit("UpdatePromoCodeCreateLevel", input);
  }

  @Action
  SetPromoCodeDuplicateError(duplicateCode: string[]) {
    this.context.commit("MutationSetPromoCodeDuplicateError", duplicateCode);
  }
}
