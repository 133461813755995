import axios from "axios";

// axios.defaults.headers.common['Authorization'] = 'Bearer ' + storeMerchant.state.token

const peakExportAPI = axios.create({
  baseURL: process.env.VUE_APP_PeakExportService,
  headers: {
    "Content-Type": "application/json;charset=utf-8",
  },
});
const peakRealTimeAPI = axios.create({
  baseURL: process.env.VUE_APP_PeakRealTimeService,
  headers: {
    "Content-Type": "application/json;charset=utf-8",
  },
});
const peakAdminAPI = axios.create({
  baseURL: process.env.VUE_APP_PeakAdminService,
  headers: {
    "Content-Type": "application/json;charset=utf-8",
  },
});
const peakAdminAPIForUpload = axios.create({
  baseURL: process.env.VUE_APP_PeakAdminService,
  headers: {
    "Content-Type": "multipart/form-data;charset=utf-8",
  },
});
const peakAuthen = axios.create({
  baseURL: process.env.VUE_APP_PeakAuthenService,
});

export default { peakExportAPI, peakRealTimeAPI, peakAdminAPI, peakAdminAPIForUpload, peakAuthen };
