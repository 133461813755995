import { ContactTemplateModel } from "@/model/contact/contactModel";

export function ConvertToContactTemplateModel(contactTemplateResponse: ContactTemplateModel) {
  const contactTemplateModel = {
    id: contactTemplateResponse.id ? contactTemplateResponse.id : 0,
    taxId: contactTemplateResponse.taxId ? contactTemplateResponse.taxId : "",
    name: contactTemplateResponse.name ? contactTemplateResponse.name : "",
    logoUrl: contactTemplateResponse.logoUrl
      ? process.env.VUE_APP_PeakBlobStorage +
        "peakengine" +
        contactTemplateResponse.logoUrl +
        "?" +
        new Date().getTime()
      : "",
  };
  return contactTemplateModel;
}
