import { SetHeaderService } from "@/helper/merchantHelper";
import service from "../baseHttpService";
import { UserTableRequest, ChangeSuperUserRequest } from "@/model/user/userDetailModel";
import { CreateLogForRecordLotCostOfGoodsSoldRequest } from "@/model/activitylog/createLogForRecordLotCostOfGoodsSoldRequest";
import { CreateLogForUpdateProductTemplateForRecalculateCostofGoodsSoldByMerchantIdRequest } from "@/model/activitylog/createLogForUpdateProductTemplateForRecalculateCostofGoodsSoldByMerchantIdRequest";

class ValidateProductStockService {
  async DoGetValidateProductStock(input: number) {
    const _input = {
      merchantId: input,
    };
    return service.peakExportAPI
      .post("/api/DashBoard/GetValidateProductStock", _input, await SetHeaderService())
      .then((res) => {
        return res;
      });
  }
  async RecordLotCostOfGoodsSold(input: any) {
    const _input = {
      merchantId: input,
    };
    return service.peakRealTimeAPI
      .post("/api/Product/RecordLotCostOfGoodsSold", _input, await SetHeaderService())
      .then((res) => {
        return res;
      });
  }
  async RecalculateProductTemplateByMerchantId(input: any) {
    const _input = {
      merchantId: input.merchantId,
      fromDate: input.fromDate,
      toDate: input.toDate,
      language: input.language,
    };
    return service.peakRealTimeAPI
      .post("/api/Product/RecalculateProductTemplateByMerchantId", _input, await SetHeaderService())
      .then((res) => {
        return res;
      });
  }
  async CreateLogForRecordLotCostOfGoodsSold(request: CreateLogForRecordLotCostOfGoodsSoldRequest) {
    return service.peakAdminAPI
      .post("/api/ActivityLog/CreateLogForRecordLotCostOfGoodsSold", request, await SetHeaderService())
      .then((res) => {
        return res;
      });
  }
  async CreateLogForUpdateProductTemplateForRecalculateCostofGoodsSoldByMerchantId(request: CreateLogForUpdateProductTemplateForRecalculateCostofGoodsSoldByMerchantIdRequest) {
    return service.peakAdminAPI
      .post("/api/ActivityLog/CreateLogForUpdateProductTemplateForRecalculateCostofGoodsSoldByMerchantId", request, await SetHeaderService())
      .then((res) => {
        return res;
      });
  }
}

export default new ValidateProductStockService();