import { SetHeaderService } from "@/helper/merchantHelper";
import service from "../baseHttpService";

class AppConnectService {
  async DoGetConnectionConfig(merchantId: number) {
    return service.peakAdminAPI
      .get(`/api/AppConnect/multishop/config?merchantId=${merchantId}`, await SetHeaderService())
      .then((res) => {
        return res;
      });
  }
  async DoUpdateConnectionConfig(connectionConfig: any) {
    return service.peakAdminAPI
      .post("/api/AppConnect/multishop/config", connectionConfig, await SetHeaderService())
      .then((res) => {
        return res;
      });
  }
}

export default new AppConnectService();
