export function ConvertToPackageTableModelList(data: any) {
  const packageTable: any = {
    tableList: [],
  };
  if (data && data.tables && data.tables.length > 0) {
    data.tables.forEach((tableItem: any) => {
      const packageTableModel: any = {
        ...tableItem,
      };
      packageTable.tableList.push(packageTableModel);
    });
  }
  return packageTable;
}
