import {
  AdminExceptionResponseCode,
  CreatePromoCodeRequestType,
  CreatePromoCodeType,
  CustomerType,
  IsCommercial,
  PackageMonth,
  PackagePeriodType,
  PeakPackage,
  PromoCampaignDiscountType,
  PromoCampaignType,
  PromoCodeCreateLevelType,
  PromoStatus,
  SendPromoRequestType,
  UserRegisterType,
} from "@/enums/enums";
import {
  PromoCampaignCreateTemplateModel,
  PromoCampaignModel,
  PromoCampaignRequest,
  PromoCodeCreateListRequest,
  PromoCodeCreateRequest,
  PromoCodeRequest,
  PromoCodeRequestCreateByCreateModel,
  PromoCodeRequestCreateBySelectModel,
  PromoCodeRequestCreateListModel,
  PromoCodeRequestDetailCode,
  PromoCodeRequestDetailModel,
  PromoCodeRequestDetailPromoCampaign,
  PromoCodeRequestHeaderDetailModel,
  PromoCodeTableDataModel,
  PromoDraftingEditRequest,
  PromoDraftingRequest,
} from "@/model/promoCode/promoCodeModel";
import { GetPackageName } from "./packageHelper";
import { GetDateLengthDiffer, NewObject } from "./shareHelper";

export function SetHeaderPromoCampaignTable() {
  const header = [
    {
      text: "ลำดับที่",
      align: "flex-start",
      filterable: false,
      value: "index",
    },
    {
      text: "ชื่อ Campaign",
      align: "flex-start",
      filterable: false,
      value: "name",
    },
    {
      text: "รายละเอียด",
      align: "flex-start",
      filterable: false,
      value: "description",
    },
    {
      text: "ประเภท Campaign",
      align: "flex-start",
      filterable: false,
      value: "type",
    },
    {
      text: "สิทธิพิเศษ",
      align: "flex-start",
      filterable: false,
      value: "privilege",
    },
    {
      text: "เงื่อนไข",
      align: "flex-start",
      filterable: false,
      value: "condition",
    },
    {
      text: "Campaign Owner",
      align: "flex-start",
      filterable: false,
      value: "reference",
    },
    {
      text: "Customer Type",
      align: "flex-start",
      filterable: false,
      value: "refOne",
    },
    {
      text: "isCommercial",
      align: "flex-start",
      filterable: false,
      value: "refTwo",
    },
  ];
  return header;
}

export function SetHeaderPromoCodeTable() {
  const header = [
    {
      text: "หมายเลข",
      align: "flex-start",
      filterable: false,
      value: "id",
    },
    {
      text: "รหัส",
      align: "flex-start",
      filterable: false,
      value: "code",
    },
    {
      text: "สถานะ",
      align: "flex-start",
      filterable: false,
      value: "status",
    },
    {
      text: "ชื่อ Campaign",
      align: "flex-start",
      filterable: false,
      value: "campaignName",
    },
    {
      text: "วันที่เริ่ม",
      align: "center",
      filterable: false,
      value: "startDateTime",
    },
    {
      text: "วันที่สิ้นสุด",
      align: "center",
      filterable: false,
      value: "expireDateTime",
    },
    {
      text: "User ID",
      align: "flex-start",
      filterable: false,
      value: "userId",
    },
    {
      text: "Merchant ID",
      align: "flex-start",
      filterable: false,
      value: "merchantId",
    },
    {
      text: "Acc. firm",
      align: "flex-start",
      filterable: false,
      value: "AccFirm",
    },
    {
      text: "API.",
      align: "flex-start",
      filterable: false,
      value: "api",
    },
  ];
  return header;
}

export function SetHeaderPromoRequestTable() {
  const header = [
    {
      text: "Request No.",
      align: "left",
      filterable: false,
      value: "requestNo",
    },
    {
      text: "Date",
      align: "left",
      filterable: false,
      value: "requestDateTime",
    },
    {
      text: "Asana Task",
      align: "left",
      filterable: false,
      value: "asanaTask",
    },
    {
      text: "ผู้สร้าง",
      align: "left",
      filterable: false,
      value: "requestUser",
    },
    {
      text: "ผู้อนุมัติ",
      align: "left",
      filterable: false,
      value: "approveUser",
    },
    {
      text: "สถานะ",
      align: "left",
      filterable: false,
      value: "status",
    },
    {
      text: "QO",
      align: "left",
      filterable: false,
      value: "quotationNumber",
    },
    {
      text: "IV",
      align: "left",
      filterable: false,
      value: "invoiceNumber",
    },
  ];
  return header;
}

export function GetPromoCampaignCreateTemplateDefault(): PromoCampaignCreateTemplateModel {
  const promoCampaignCreate = {
    id: 0,
    name: {
      value: "",
      isValidateError: false,
    },
    description: {
      value: "",
      isValidateError: false,
    },
    reference: {
      value: "",
      isValidateError: false,
    },
    refOne: {
      value: "",
      isValidateError: false,
    },
    refTwo: {
      value: "",
      isValidateError: false,
    },
    isUnique: false,
    isUniqueUser: false,
    isUnpaidUser: false,
    isRecurring: false,
    type: PromoCampaignType.Discount,
    discountNumber: {
      value: "0.00",
      isValidateError: false,
    },
    discountPercent: {
      value: "0.00",
      isValidateError: false,
    },
    packageId: 0,
    packagePrice: {
      value: "0.00",
      isValidateError: false,
    },
    packagePeriod: {
      value: "0",
      isValidateError: false,
      isDisable: false,
    },
    packagePeriodDay: {
      value: "0",
      isValidateError: false,
    },
    packageExpiredDate: {
      value: null,
      isValidateError: false,
      isShowModal: false,
    },
    packagePeriodExtra: {
      value: "0",
      isValidateError: false,
    },
    periodType: PackagePeriodType.Month,
    discountType: PromoCampaignDiscountType.Amount,
  };
  return promoCampaignCreate;
}

export function SetHeaderPromoRequestStatusPromoCodeTable() {
  const header = [
    {
      text: "Promo Code",
      align: "left",
      filterable: false,
      value: "promoCode",
    },
    {
      text: "Quota",
      align: "left",
      filterable: false,
      value: "quota",
      width: "83px",
    },
    {
      text: "Start Date",
      align: "left",
      filterable: false,
      value: "startDateTime",
    },
    {
      text: "End Date",
      align: "left",
      filterable: false,
      value: "expireDateTime",
    },
    {
      text: "Remark",
      align: "left",
      filterable: false,
      value: "remark",
    },
    // {
    //   text: "Product Code",
    //   align: "center",
    //   filterable: false,
    //   value: "productCode",
    // },
    // {
    //   text: "Account Code",
    //   align: "center",
    //   filterable: false,
    //   value: "accountCode",
    // },
    // {
    //   text: "Quota",
    //   align: "center",
    //   filterable: false,
    //   value: "quotaTotal",
    // },
    // {
    //   text: "Price/Quota",
    //   align: "center",
    //   filterable: false,
    //   value: "pricePerQuota",
    // },
    // {
    //   text: "Total",
    //   align: "center",
    //   filterable: false,
    //   value: "total",
    // },
    {
      text: "Condition",
      align: "left",
      filterable: false,
      value: "condition",
      width: "187px",
    },
  ];
  return header;
}

export function SetPromoCodeForPromoRequestPromoCodeTable(
  promoCode: PromoCodeRequestDetailCode[],
  campaignDetail: PromoCodeRequestDetailPromoCampaign | PromoCampaignModel
): Array<PromoCodeTableDataModel> {
  const dataRows = promoCode.map((item) => {
    const conditions = {
      fixUserId: item.fixUserId ? `fixUserId: ${item.fixUserId}` : "",
      fixMerchantId: item.fixMerchantId ? `fixMerchantId: ${item.fixMerchantId}` : "",
      fixFirmId: item.fixFirmId ? `fixFirmId: ${item.fixFirmId}` : "",
      fixClientId: item.fixClientId ? `fixClientId: ${item.fixClientId}` : "",
    };
    const conditionsText = Object.values(conditions).every((item) => item == "")
      ? "-"
      : Object.values(conditions).join("\n");
    let quotaTotal: any;
    let pricePerQuota: any;
    const campaignType = GetCampaignTypeFromText(campaignDetail.type);
    switch (campaignType) {
      case PromoCampaignType.Discount:
        quotaTotal = (campaignDetail.discountNumber * item.quota).toFixed(2);
        pricePerQuota = (quotaTotal / item.quota).toFixed(2);
        break;
      default:
        quotaTotal = "-";
        pricePerQuota = "-";
        break;
    }
    return {
      promoCode: item.code,
      quota: item.quota,
      startDate: item.startDateTime.GetDateFormat(),
      endDate: item.expireDateTime.GetDateFormat(),
      productCode: item.productCode == null ? "-" : item.productCode,
      accountCode: item.accountCode == null ? "-" : item.accountCode,
      quotaTotal: quotaTotal,
      pricePerQuota: pricePerQuota,
      remark: item.remark ? item.remark : "-",
      condition: conditionsText,
      total: quotaTotal,
    };
  });

  return dataRows;
}
export function GetPromoCodeCreateTemplateDefault() {
  const promoCodeCreate = {
    campaign: {
      value: {
        id: 0,
      },
      isValidateError: false,
    },
    promoCode: {
      value: "",
      isValidateError: false,
    },
    startDate: {
      value: null,
      isValidateError: false,
    },
    expireDate: {
      value: null,
      isValidateError: false,
    },
    quota: {
      value: 0,
      isValidateError: false,
    },
    fixUserId: {
      isCheck: false,
      id: {
        value: "",
        isValidateError: false,
      },
    },
    fixMerchantId: {
      isCheck: false,
      id: {
        value: "",
        isValidateError: false,
      },
    },
    fixFirmId: {
      isCheck: false,
      id: {
        value: "",
        isValidateError: false,
      },
    },
    fixClientId: {
      isCheck: false,
      id: {
        value: "",
        isValidateError: false,
      },
    },
    registerType: UserRegisterType.PeakEngine,
    amount: {
      value: 1,
      isValidateError: false,
    },
  };
  return promoCodeCreate;
}

export function GetPromoCodeRequestObjectTemplate(): PromoCodeRequest {
  const promoCodeRequest = {
    type: CreatePromoCodeType.Advance,
    promoCode: {
      value: "",
      isValidateError: false,
    },
    startDate: {
      value: "",
      modal: false,
      isValidateError: false,
    },
    expireDate: {
      value: "",
      modal: false,
      isValidateError: false,
    },
    quota: {
      value: "0",
      isValidateError: false,
    },
    fixUserId: {
      isCheck: false,
      id: {
        value: "",
        isValidateError: false,
      },
    },
    fixMerchantId: {
      isCheck: false,
      id: {
        value: "",
        isValidateError: false,
      },
    },
    fixFirmId: {
      isCheck: false,
      id: {
        value: "",
        isValidateError: false,
      },
    },
    fixClientId: {
      isCheck: false,
      id: {
        value: "",
        isValidateError: false,
      },
    },
    registerType: UserRegisterType.PeakAccount,
    amount: {
      value: 1,
      isValidateError: false,
    },
    remark: {
      value: "",
      isValidateError: false,
    },
  };
  return promoCodeRequest;
}

export function GetPromoCodeRequestCreateBySelectTemplate(): PromoCodeRequestCreateBySelectModel {
  const promoCodeRequestCreateBySelectTemplate = GetPromoCodeRequestObjectTemplate();
  const promoCodeCreateBySelect = {
    promoCodeCampaignCreateType: CreatePromoCodeRequestType.Select,
    campaign: {
      value: {
        id: 0,
      },
      isValidateError: false,
    },
    promoCodeList: [...new Array(3).fill(NewObject(promoCodeRequestCreateBySelectTemplate))],
    promoCodeCreateLevel: PromoCodeCreateLevelType.Basic,
  };
  return promoCodeCreateBySelect;
}

export function GetPromoCodeRequestCreateByCreateTemplate(): PromoCodeRequestCreateByCreateModel {
  const promoCodeRequestCreateByCreateTemplate = GetPromoCodeRequestObjectTemplate();
  const promoCodeCreateByCreate = {
    promoCodeCampaignCreateType: CreatePromoCodeRequestType.Create,
    campaignRequest: GetPromoCampaignCreateTemplateDefault(),
    promoCodeList: [...new Array(3).fill(NewObject(promoCodeRequestCreateByCreateTemplate))],
    promoCodeCreateLevel: PromoCodeCreateLevelType.Basic,
  };
  return promoCodeCreateByCreate;
}

export function ConvertToPromoCampaignRequest(promoCampaignCreateTemplate: any) {
  const promoCampaignRequest: PromoCampaignRequest = {
    id: promoCampaignCreateTemplate.id,
    name: promoCampaignCreateTemplate.name.value,
    description: promoCampaignCreateTemplate.description.value,
    reference: promoCampaignCreateTemplate.reference.value.toString(),
    refOne: promoCampaignCreateTemplate.refOne.value.toString(),
    refTwo: promoCampaignCreateTemplate.refTwo.value.toString(),
    isUnique: promoCampaignCreateTemplate.isUnique,
    isUniqueUser: promoCampaignCreateTemplate.isUniqueUser,
    isUnpaidUser: promoCampaignCreateTemplate.isUnpaidUser,
    isRecurring: promoCampaignCreateTemplate.isRecurring,
    type: promoCampaignCreateTemplate.type,
    discountNumber: promoCampaignCreateTemplate.discountNumber.value.ToNumber(),
    discountPercent: promoCampaignCreateTemplate.discountPercent.value.ToNumber(),
    packageId: promoCampaignCreateTemplate.packageId,
    packagePrice: promoCampaignCreateTemplate.packagePrice.value.ToNumber(),
    packagePeriod: promoCampaignCreateTemplate.packagePeriod.value,
    packagePeriodDay: promoCampaignCreateTemplate.packagePeriodDay.value,
    packageExpiredDate: promoCampaignCreateTemplate.packageExpiredDate.value,
    packagePeriodExtra: promoCampaignCreateTemplate.packagePeriodExtra.value,
  };
  return promoCampaignRequest;
}

export function ConvertToPromoCodeCreateRequest(promoCodeCreateTemplate: any) {
  const promoCodeRequest: PromoCodeCreateRequest = {
    code: promoCodeCreateTemplate.promoCode.value,
    campaignId: promoCodeCreateTemplate.campaign.value.id,
    startDateTime: promoCodeCreateTemplate.startDate.value,
    expireDateTime: promoCodeCreateTemplate.expireDate.value,
    quota: promoCodeCreateTemplate.quota.value,
    fixUserId: promoCodeCreateTemplate.fixUserId.isCheck ? promoCodeCreateTemplate.fixUserId.id.value.trim() : 0,
    fixMerchantId: promoCodeCreateTemplate.fixMerchantId.isCheck
      ? promoCodeCreateTemplate.fixMerchantId.id.value.trim()
      : 0,
    fixFirmId: promoCodeCreateTemplate.fixFirmId.isCheck ? promoCodeCreateTemplate.fixFirmId.id.value.trim() : 0,
    fixClientId: promoCodeCreateTemplate.fixClientId.isCheck ? promoCodeCreateTemplate.fixClientId.id.value.trim() : 0,
  };
  return promoCodeRequest;
}

export function ConvertToPromoCodeCreateListRequest(promoCodeCreateTemplate: any) {
  const promoCodeRequest: PromoCodeCreateListRequest = {
    campaignId: promoCodeCreateTemplate.campaign.value.id,
    startDateTime: promoCodeCreateTemplate.startDate.value,
    expireDateTime: promoCodeCreateTemplate.expireDate.value,
    amount: promoCodeCreateTemplate.amount.value,
    fixUserId: promoCodeCreateTemplate.fixUserId.isCheck ? promoCodeCreateTemplate.fixUserId.id.value.trim() : 0,
    fixMerchantId: promoCodeCreateTemplate.fixMerchantId.isCheck
      ? promoCodeCreateTemplate.fixMerchantId.id.value.trim()
      : 0,
    fixFirmId: promoCodeCreateTemplate.fixFirmId.isCheck ? promoCodeCreateTemplate.fixFirmId.id.value.trim() : 0,
    fixClientId: promoCodeCreateTemplate.fixClientId.isCheck ? promoCodeCreateTemplate.fixClientId.id.value.trim() : 0,
  };
  return promoCodeRequest;
}

export function SetPromoCampaignTypeName(type: PromoCampaignType) {
  let promoCampaignTypeName = "-";
  switch (type) {
    case PromoCampaignType.Discount:
      promoCampaignTypeName = "Discount";
      break;
    case PromoCampaignType.Package:
      promoCampaignTypeName = "Package";
      break;
    case PromoCampaignType.Extra:
      promoCampaignTypeName = "Extra";
      break;
  }
  return promoCampaignTypeName;
}

export function SetPromoCampaignPrivilegeText(promoCampaign: PromoCampaignModel) {
  let privilegeText = "-";
  switch (promoCampaign.type) {
    case PromoCampaignType.Discount: {
      if (promoCampaign.packageId > 0 && promoCampaign.packagePeriod > 0) {
        privilegeText = `${GetPackageName(promoCampaign.packageId)} ${promoCampaign.packagePeriod} เดือน ส่วนลด `;
        if (promoCampaign.discountPercent > 0) {
          privilegeText += `${promoCampaign.discountPercent.FormatMoney()}%`;
        } else {
          privilegeText += `${promoCampaign.discountNumber.FormatMoney()} บาท`;
        }
      } else if (promoCampaign.packageId > 0) {
        privilegeText = `${GetPackageName(promoCampaign.packageId)} ส่วนลด `;
        if (promoCampaign.discountPercent > 0) {
          privilegeText += `${promoCampaign.discountPercent.FormatMoney()}%`;
        } else {
          privilegeText += `${promoCampaign.discountNumber.FormatMoney()} บาท`;
        }
      } else if (promoCampaign.packagePeriod > 0) {
        privilegeText = `${promoCampaign.packagePeriod} เดือน ส่วนลด `;
        if (promoCampaign.discountPercent > 0) {
          privilegeText += `${promoCampaign.discountPercent.FormatMoney()}%`;
        } else {
          privilegeText += `${promoCampaign.discountNumber.FormatMoney()} บาท`;
        }
      } else {
        if (promoCampaign.discountPercent > 0) {
          privilegeText = `ส่วนลด ${promoCampaign.discountPercent.FormatMoney()}%`;
        } else {
          privilegeText = `ส่วนลด ${promoCampaign.discountNumber.FormatMoney()} บาท`;
        }
      }
      break;
    }
    case PromoCampaignType.Package: {
      privilegeText = `${GetPackageName(promoCampaign.packageId)} `;
      if (promoCampaign.packageId === 0) {
        privilegeText = "";
      }
      if (promoCampaign.packagePeriodDay > 0) {
        privilegeText += `${promoCampaign.packagePeriodDay} วัน`;
      } else if (promoCampaign.packagePeriod > 0) {
        privilegeText += `${promoCampaign.packagePeriod} เดือน`;
      } else if (new Date(promoCampaign.packageExpiredDate) > new Date("0001-01-01T00:00:00")) {
        privilegeText += `สิ้นสุด ${promoCampaign.packageExpiredDate.GetDateFormat()}`;
      }
      if (promoCampaign.packagePrice === 0) {
        privilegeText += " ฟรี";
      } else {
        privilegeText += ` ราคา ${promoCampaign.packagePrice.FormatMoney()} บาท`;
      }
      break;
    }
    case PromoCampaignType.Extra: {
      privilegeText = `${GetPackageName(promoCampaign.packageId)} ${promoCampaign.packagePeriod} เดือน แถม ${
        promoCampaign.packagePeriodExtra
      } เดือน`;
      break;
    }
  }
  return privilegeText;
}

export function SetCampaignConditionList(promoCampaign: PromoCampaignModel) {
  const conditionList: Array<any> = [];

  if (promoCampaign.isUnique) {
    conditionList.push({
      id: "A",
      description: "Is Unique Merchant (ใช้โค้ดได้ 1 ครั้งต่อกิจการ)",
    });
  }
  if (promoCampaign.isUniqueUser) {
    conditionList.push({
      id: "B",
      description: "Is Unique User (ใช้โค้ดได้ 1 ครั้งต่อผู้ใช้งาน)",
    });
  }
  if (promoCampaign.isUnpaidUser) {
    conditionList.push({
      id: "C",
      description: "Is Unpaid (เฉพาะการชำระเงินครั้งแรก)",
    });
  }
  if (promoCampaign.isRecurring) {
    conditionList.push({
      id: "D",
      description: "Is Subscription (ต่ออายุแบบ Subscription)",
    });
  }

  return conditionList;
}

export function GetCustomerTypeName(refOne: string) {
  let customerTypeName = "-";
  const customerType = refOne.ToNumber();
  switch (customerType) {
    case CustomerType.Organic:
      customerTypeName = "Organic";
      break;
    case CustomerType.AFP:
      customerTypeName = "AFP";
      break;
    case CustomerType.BD:
      customerTypeName = "BD";
      break;
    case CustomerType.CSR:
      customerTypeName = "CSR";
      break;
    case CustomerType.Enterprise:
      customerTypeName = "Enterprise";
      break;
    case CustomerType.PARC:
      customerTypeName = "PARC";
      break;
    case CustomerType.Test:
      customerTypeName = "Test";
      break;
    case CustomerType.AFPFree:
      customerTypeName = "AFPFree";
      break;
    case CustomerType.BDFree:
      customerTypeName = "BDFree";
      break;
    case CustomerType.APIServices:
      customerTypeName = "API Service";
      break;
    case CustomerType.ClientSupports:
      customerTypeName = "Client Supports";
      break;
    case CustomerType.OtherInternalUses:
      customerTypeName = "Other Internal Uses";
      break;
    case CustomerType.Gifts:
      customerTypeName = "Gifts";
      break;
  }
  return customerTypeName;
}

export function GetIsCommercial(refTwo: string) {
  let isCommercialName = "-";
  const isCommercial = refTwo.ToNumber();
  switch (isCommercial) {
    case IsCommercial.No:
      isCommercialName = "No";
      break;
    case IsCommercial.Yes:
      isCommercialName = "Yes";
      break;
  }
  return isCommercialName;
}

export function ValidateCreatePromoCampaign(
  promoCampaignCreateTemplate: any,
  promoCampaignList: Array<PromoCampaignModel> | null = null
) {
  let isError = false;
  if (promoCampaignCreateTemplate.name.value.trim() === "" || promoCampaignCreateTemplate.name.value.length > 100) {
    promoCampaignCreateTemplate.name.isValidateError = true;
    isError = true;
  } else {
    if (promoCampaignList && promoCampaignList.length > 0) {
      const campaignNameDuplicate = promoCampaignList.find(
        (x) => x.name === promoCampaignCreateTemplate.name.value.trim()
      );
      if (campaignNameDuplicate) {
        promoCampaignCreateTemplate.name.isValidateError = true;
        isError = true;
      } else {
        promoCampaignCreateTemplate.name.isValidateError = false;
      }
    }
  }
  if (
    promoCampaignCreateTemplate.description.value.trim() === "" ||
    promoCampaignCreateTemplate.description.value.length > 100
  ) {
    promoCampaignCreateTemplate.description.isValidateError = true;
    isError = true;
  } else {
    promoCampaignCreateTemplate.description.isValidateError = false;
  }
  if (promoCampaignCreateTemplate.reference.value !== "" && promoCampaignCreateTemplate.reference.value.length > 100) {
    promoCampaignCreateTemplate.reference.isValidateError = true;
    isError = true;
  } else {
    promoCampaignCreateTemplate.reference.isValidateError = false;
  }
  switch (promoCampaignCreateTemplate.type) {
    case PromoCampaignType.Discount: {
      switch (promoCampaignCreateTemplate.discountType) {
        case PromoCampaignDiscountType.Amount: {
          if (
            promoCampaignCreateTemplate.discountNumber.value.trim() === "" ||
            promoCampaignCreateTemplate.discountNumber.value.ToNumber() <= 0
          ) {
            promoCampaignCreateTemplate.discountNumber.isValidateError = true;
            isError = true;
          } else {
            promoCampaignCreateTemplate.discountNumber.isValidateError = false;
          }
          break;
        }
        case PromoCampaignDiscountType.Percent: {
          if (
            promoCampaignCreateTemplate.discountPercent.value === "" ||
            isNaN(Number(promoCampaignCreateTemplate.discountPercent.value)) ||
            promoCampaignCreateTemplate.discountPercent.value.ToNumber() <= 0
          ) {
            promoCampaignCreateTemplate.discountPercent.isValidateError = true;
            isError = true;
          } else {
            promoCampaignCreateTemplate.discountPercent.isValidateError = false;
          }
          break;
        }
      }

      break;
    }
    case PromoCampaignType.Package: {
      switch (promoCampaignCreateTemplate.periodType) {
        case PackagePeriodType.Month: {
          if (
            promoCampaignCreateTemplate.packagePeriod.value === "" ||
            promoCampaignCreateTemplate.packagePeriod.value.ToNumber() <= 0
          ) {
            promoCampaignCreateTemplate.packagePeriod.isValidateError = true;
            isError = true;
          } else {
            promoCampaignCreateTemplate.packagePeriod.isValidateError = false;
          }
          break;
        }
        case PackagePeriodType.Day: {
          if (
            promoCampaignCreateTemplate.packagePeriodDay.value === "" ||
            promoCampaignCreateTemplate.packagePeriodDay.value.ToNumber() <= 0
          ) {
            promoCampaignCreateTemplate.packagePeriodDay.isValidateError = true;
            isError = true;
          } else {
            promoCampaignCreateTemplate.packagePeriodDay.isValidateError = false;
          }
          break;
        }
        case PackagePeriodType.ExpiredDate: {
          if (promoCampaignCreateTemplate.packageExpiredDate.value === null) {
            promoCampaignCreateTemplate.packageExpiredDate.isValidateError = true;
            isError = true;
          } else {
            promoCampaignCreateTemplate.packageExpiredDate.isValidateError = false;
          }
          break;
        }
      }
      if (
        promoCampaignCreateTemplate.packagePrice.value.trim() === "" ||
        promoCampaignCreateTemplate.packagePrice.value.ToNumber() < 0
      ) {
        promoCampaignCreateTemplate.packagePrice.isValidateError = true;
        isError = true;
      } else {
        promoCampaignCreateTemplate.packagePrice.isValidateError = false;
      }
      break;
    }
    case PromoCampaignType.Extra: {
      if (
        promoCampaignCreateTemplate.packagePeriodExtra.value === "" ||
        promoCampaignCreateTemplate.packagePeriodExtra.value.ToNumber() <= 0
      ) {
        promoCampaignCreateTemplate.packagePeriodExtra.isValidateError = true;
        isError = true;
      } else {
        promoCampaignCreateTemplate.packagePeriodExtra.isValidateError = false;
      }
      break;
    }
  }
  return isError;
}

export function ValidateCreatePromoCampaignForRequest(
  promoCampaignCreateTemplate: any,
  promoCampaignList: Array<PromoCampaignModel> | null = null
) {
  let isError = false;
  if (promoCampaignCreateTemplate.name.value.trim() === "" || promoCampaignCreateTemplate.name.value.length > 100) {
    promoCampaignCreateTemplate.name.isValidateError = true;
    isError = true;
  } else {
    if (promoCampaignList && promoCampaignList.length > 0) {
      const campaignNameDuplicate = promoCampaignList.find(
        (x) => x.name === promoCampaignCreateTemplate.name.value.trim()
      );
      if (campaignNameDuplicate) {
        promoCampaignCreateTemplate.name.isValidateError = true;
        isError = true;
      } else {
        promoCampaignCreateTemplate.name.isValidateError = false;
      }
    }
  }
  if (
    promoCampaignCreateTemplate.description.value.trim() === "" ||
    promoCampaignCreateTemplate.description.value.length > 100
  ) {
    promoCampaignCreateTemplate.description.isValidateError = true;
    isError = true;
  } else {
    promoCampaignCreateTemplate.description.isValidateError = false;
  }
  if (promoCampaignCreateTemplate.reference.value !== "" && promoCampaignCreateTemplate.reference.value.length > 100) {
    promoCampaignCreateTemplate.reference.isValidateError = true;
    isError = true;
  } else {
    promoCampaignCreateTemplate.reference.isValidateError = false;
  }
  switch (promoCampaignCreateTemplate.type) {
    case PromoCampaignType.Discount: {
      switch (promoCampaignCreateTemplate.discountType) {
        case PromoCampaignDiscountType.Amount: {
          if (
            promoCampaignCreateTemplate.discountNumber.value.trim() === "" ||
            promoCampaignCreateTemplate.discountNumber.value.ToNumber() <= 0
          ) {
            promoCampaignCreateTemplate.discountNumber.isValidateError = true;
            isError = true;
          } else {
            promoCampaignCreateTemplate.discountNumber.isValidateError = false;
          }
          break;
        }
        case PromoCampaignDiscountType.Percent: {
          if (
            promoCampaignCreateTemplate.discountPercent.value === "" ||
            isNaN(Number(promoCampaignCreateTemplate.discountPercent.value)) ||
            promoCampaignCreateTemplate.discountPercent.value.ToNumber() <= 0
          ) {
            promoCampaignCreateTemplate.discountPercent.isValidateError = true;
            isError = true;
          } else {
            promoCampaignCreateTemplate.discountPercent.isValidateError = false;
          }
          break;
        }
      }

      break;
    }
    case PromoCampaignType.Package: {
      switch (promoCampaignCreateTemplate.periodType) {
        case PackagePeriodType.Month: {
          if (ValidateStringNumber(promoCampaignCreateTemplate.packagePeriod.value)) {
            promoCampaignCreateTemplate.packagePeriod.isValidateError = true;
            isError = true;
          } else {
            promoCampaignCreateTemplate.packagePeriod.isValidateError = false;
          }
          break;
        }
        case PackagePeriodType.Day: {
          if (ValidateStringNumber(promoCampaignCreateTemplate.packagePeriodDay.value)) {
            promoCampaignCreateTemplate.packagePeriodDay.isValidateError = true;
            isError = true;
          } else {
            promoCampaignCreateTemplate.packagePeriodDay.isValidateError = false;
          }
          break;
        }
        case PackagePeriodType.ExpiredDate: {
          if (promoCampaignCreateTemplate.packageExpiredDate.value === "") {
            promoCampaignCreateTemplate.packageExpiredDate.isValidateError = true;
            isError = true;
          } else {
            promoCampaignCreateTemplate.packageExpiredDate.isValidateError = false;
          }
          break;
        }
      }
      if (
        promoCampaignCreateTemplate.packagePrice.value.trim() === "" ||
        promoCampaignCreateTemplate.packagePrice.value.ToNumber() <= 0
      ) {
        promoCampaignCreateTemplate.packagePrice.isValidateError = true;
        isError = true;
      } else {
        promoCampaignCreateTemplate.packagePrice.isValidateError = false;
      }
      break;
    }
    case PromoCampaignType.Extra: {
      if (
        promoCampaignCreateTemplate.packagePeriodExtra.value === "" ||
        promoCampaignCreateTemplate.packagePeriodExtra.value.ToNumber() <= 0
      ) {
        promoCampaignCreateTemplate.packagePeriodExtra.isValidateError = true;
        isError = true;
      } else {
        promoCampaignCreateTemplate.packagePeriodExtra.isValidateError = false;
      }
      break;
    }
  }
  return isError;
}

export function ValidateCreatePromoCode(promoCodeCreateTemplate: any, type: CreatePromoCodeType) {
  let isError = false;
  if (promoCodeCreateTemplate.campaign.value.id === 0) {
    promoCodeCreateTemplate.campaign.isValidateError = true;
    isError = true;
  } else {
    promoCodeCreateTemplate.campaign.isValidateError = false;
  }
  if (!promoCodeCreateTemplate.startDate.value) {
    promoCodeCreateTemplate.startDate.isValidateError = true;
    isError = true;
  } else {
    promoCodeCreateTemplate.startDate.isValidateError = false;
  }
  if (!promoCodeCreateTemplate.expireDate.value) {
    promoCodeCreateTemplate.expireDate.isValidateError = true;
    isError = true;
  } else {
    promoCodeCreateTemplate.expireDate.isValidateError = false;
  }
  if (promoCodeCreateTemplate.fixUserId.isCheck) {
    if (promoCodeCreateTemplate.fixUserId.id.value.trim() === "") {
      promoCodeCreateTemplate.fixUserId.id.isValidateError = true;
      isError = true;
    } else {
      promoCodeCreateTemplate.fixUserId.id.isValidateError = false;
    }
  }
  if (promoCodeCreateTemplate.fixMerchantId.isCheck) {
    if (promoCodeCreateTemplate.fixMerchantId.id.value.trim() === "") {
      promoCodeCreateTemplate.fixMerchantId.id.isValidateError = true;
      isError = true;
    } else {
      promoCodeCreateTemplate.fixMerchantId.id.isValidateError = false;
    }
  }
  if (promoCodeCreateTemplate.fixFirmId.isCheck) {
    if (promoCodeCreateTemplate.fixFirmId.id.value.trim() === "") {
      promoCodeCreateTemplate.fixFirmId.id.isValidateError = true;
      isError = true;
    } else {
      promoCodeCreateTemplate.fixFirmId.id.isValidateError = false;
    }
  }
  if (promoCodeCreateTemplate.fixClientId.isCheck) {
    if (promoCodeCreateTemplate.fixClientId.id.value.trim() === "") {
      promoCodeCreateTemplate.fixClientId.id.isValidateError = true;
      isError = true;
    } else {
      promoCodeCreateTemplate.fixClientId.id.isValidateError = false;
    }
  }
  switch (type) {
    case CreatePromoCodeType.Advance: {
      if (promoCodeCreateTemplate.quota.value === "" || promoCodeCreateTemplate.quota.value <= 0) {
        promoCodeCreateTemplate.quota.isValidateError = true;
        isError = true;
      } else {
        promoCodeCreateTemplate.quota.isValidateError = false;
      }
      break;
    }
    case CreatePromoCodeType.List: {
      if (!promoCodeCreateTemplate.amount.value || promoCodeCreateTemplate.amount.value <= 0) {
        promoCodeCreateTemplate.amount.isValidateError = true;
        isError = true;
      } else {
        promoCodeCreateTemplate.amount.isValidateError = false;
      }
      break;
    }
  }
  return isError;
}

function ValidateStringNumber(input: string | number) {
  if (typeof input == "number") {
    return input <= 0;
  } else {
    if (input.trim().IsNumber()) {
      return input.trim().ToNumber() <= 0;
    } else {
      return true;
    }
  }
}

export function ValidateStringNumberCondition(input: string): boolean {
  const inputTrim = input.trim();
  return inputTrim.trim() == "" || !inputTrim.IsNumber() || inputTrim.ToNumber() <= 0 || inputTrim.trim().length > 8;
}

export function ValidatePromoCodeRequest(promoCodeCreateTemplate: PromoCodeRequest) {
  let isError = false;
  if (promoCodeCreateTemplate.promoCode.value.trim() === "") {
    promoCodeCreateTemplate.promoCode.isValidateError = true;
    isError = true;
  }
  if (!promoCodeCreateTemplate.startDate.value) {
    promoCodeCreateTemplate.startDate.isValidateError = true;
    isError = true;
  } else {
    promoCodeCreateTemplate.startDate.isValidateError = false;
  }
  if (!promoCodeCreateTemplate.expireDate.value) {
    promoCodeCreateTemplate.expireDate.isValidateError = true;
    isError = true;
  } else {
    promoCodeCreateTemplate.expireDate.isValidateError = false;
  }
  if (!promoCodeCreateTemplate.expireDate.isValidateError && !promoCodeCreateTemplate.startDate.isValidateError) {
    if (GetDateLengthDiffer(promoCodeCreateTemplate.startDate.value, promoCodeCreateTemplate.expireDate.value) > 0) {
      promoCodeCreateTemplate.startDate.isValidateError = true;
      promoCodeCreateTemplate.expireDate.isValidateError = true;
      isError = true;
    }
  }
  if (promoCodeCreateTemplate.fixUserId.isCheck) {
    if (ValidateStringNumberCondition(promoCodeCreateTemplate.fixUserId.id.value)) {
      promoCodeCreateTemplate.fixUserId.id.isValidateError = true;
      isError = true;
    } else {
      promoCodeCreateTemplate.fixUserId.id.isValidateError = false;
    }
  } else {
    promoCodeCreateTemplate.fixUserId.id.isValidateError = false;
  }
  if (promoCodeCreateTemplate.fixMerchantId.isCheck) {
    if (ValidateStringNumberCondition(promoCodeCreateTemplate.fixMerchantId.id.value)) {
      promoCodeCreateTemplate.fixMerchantId.id.isValidateError = true;
      isError = true;
    } else {
      promoCodeCreateTemplate.fixMerchantId.id.isValidateError = false;
    }
  } else {
    promoCodeCreateTemplate.fixMerchantId.id.isValidateError = false;
  }
  if (promoCodeCreateTemplate.fixFirmId.isCheck) {
    if (ValidateStringNumberCondition(promoCodeCreateTemplate.fixFirmId.id.value)) {
      promoCodeCreateTemplate.fixFirmId.id.isValidateError = true;
      isError = true;
    } else {
      promoCodeCreateTemplate.fixFirmId.id.isValidateError = false;
    }
  } else {
    promoCodeCreateTemplate.fixFirmId.id.isValidateError = false;
  }
  if (promoCodeCreateTemplate.fixClientId.isCheck) {
    if (ValidateStringNumberCondition(promoCodeCreateTemplate.fixClientId.id.value)) {
      promoCodeCreateTemplate.fixClientId.id.isValidateError = true;
      isError = true;
    } else {
      promoCodeCreateTemplate.fixClientId.id.isValidateError = false;
    }
  } else {
    promoCodeCreateTemplate.fixClientId.id.isValidateError = false;
  }
  if (promoCodeCreateTemplate.quota.value === "" || promoCodeCreateTemplate.quota.value.ToNumber() <= 0) {
    promoCodeCreateTemplate.quota.isValidateError = true;
    isError = true;
  } else {
    promoCodeCreateTemplate.quota.isValidateError = false;
  }
  if (promoCodeCreateTemplate.promoCode.value.trim() == "") {
    promoCodeCreateTemplate.promoCode.isValidateError = true;
    isError = true;
  } else {
    promoCodeCreateTemplate.promoCode.isValidateError = false;
  }
  if (promoCodeCreateTemplate.remark.value.trim().length > 256) {
    promoCodeCreateTemplate.remark.isValidateError = true;
    isError = true;
  } else {
    promoCodeCreateTemplate.remark.isValidateError = false;
  }
  return isError;
}

export function ValidateIsPromoCodeDuplicate(campaignList: Record<string, any>[]) {
  let isError = false;
  const allPromoCodeCount: Record<string, any> = {};
  campaignList.forEach((campaign) => {
    campaign.promoCodeList.forEach((promoCode: PromoCodeRequest) => {
      if (promoCode.promoCode.value.trim() != "") {
        if (!allPromoCodeCount[promoCode.promoCode.value]) {
          allPromoCodeCount[promoCode.promoCode.value] = 1;
        } else {
          allPromoCodeCount[promoCode.promoCode.value] += 1;
        }
      }
    });
  });
  const duplicateCode = Object.keys(allPromoCodeCount).filter((item) => allPromoCodeCount[item] > 1);
  if (duplicateCode.length > 0) {
    isError = true;
    alert(`มี Promo Code ซ้ำ:" ${duplicateCode.join(", ")}`);
    duplicateCode.forEach((code: string) => {
      campaignList.forEach((campaign) => {
        campaign.promoCodeList.forEach((promoCode: PromoCodeRequest) => {
          if (promoCode.promoCode.isValidateError || code === promoCode.promoCode.value) {
            promoCode.promoCode.isValidateError = true;
          } else {
            promoCode.promoCode.isValidateError = false;
          }
        });
      });
    });
  }

  return isError;
}

export function ValidateCampaignSelect(selectCampaign: any) {
  let isError = false;
  if (selectCampaign.value.id === 0) {
    selectCampaign.isValidateError = true;
    isError = true;
  } else {
    selectCampaign.isValidateError = false;
  }
  return isError;
}

export function SetPackagePeriodWithPackageId(promoCampaignCreateTemplate: any) {
  if (
    promoCampaignCreateTemplate.packageId == PeakPackage.AFPLedger ||
    promoCampaignCreateTemplate.packageId == PeakPackage.AFPAccountant
  ) {
    promoCampaignCreateTemplate.packagePeriod.value = PackageMonth.TwelveMonth;
    promoCampaignCreateTemplate.packagePeriod.isDisable = true;
  } else {
    promoCampaignCreateTemplate.packagePeriod.isDisable = false;
  }
}

export function GetApprovePromoRequestStatusText(status: PromoStatus) {
  switch (status) {
    case PromoStatus.Draft:
      return "ร่าง";
    case PromoStatus.WaitingApprove:
      return "รออนุมัติ";
    case PromoStatus.Approve:
      return "อนุมัติ";
    case PromoStatus.Void:
      return "Void";
    default:
      return "";
  }
}

export function GetApprovePromoRequestStatusTextEng(status: PromoStatus) {
  switch (status) {
    case PromoStatus.Draft:
      return "Draft";
    case PromoStatus.WaitingApprove:
      return "Wait for Approve";
    case PromoStatus.Approve:
      return "Approved";
    case PromoStatus.Void:
      return "Void";
    default:
      return "";
  }
}

export function GetPromoCodeConditionText(conditionList: any) {
  if (!conditionList) return "";
  const conditionalText = conditionList.reduce((acc: string, cur: Record<string, any>, index: number) => {
    const commaString = index == conditionList.length - 1 ? "" : ", ";
    const text = cur.id + ": " + cur.description + commaString;
    return acc + text;
  }, "");
  return conditionalText;
}

export function GetCampaignTypeFromText(value: any): PromoCampaignType {
  switch (value) {
    case "Discount":
      return PromoCampaignType.Discount;
    case "Extra":
      return PromoCampaignType.Extra;
    case "Package":
      return PromoCampaignType.Package;
    default:
      return PromoCampaignType.Discount;
  }
}

export function ConvertStringToNumberForCampaignCondition(value: string) {
  return value == "" ? 0 : value.ToNumber();
}

export function ValidatePromoCodeHeaderDetail(headerDetail: PromoCodeRequestHeaderDetailModel) {
  let isError = false;
  if (headerDetail.requestNo.value.trim() == "") {
    isError = true;
    headerDetail.requestNo.isError = true;
  } else {
    headerDetail.requestNo.isError = false;
  }
  if (headerDetail.asanaTask.value.trim().length > 256) {
    isError = true;
    headerDetail.asanaTask.isError = true;
  } else {
    headerDetail.asanaTask.isError = false;
  }
  if (!headerDetail.requestDate.value || headerDetail.requestDate.value.trim() == "") {
    isError = true;
    headerDetail.requestDate.isError = true;
  } else {
    headerDetail.requestDate.isError = false;
  }
  return isError;
}

export function GetPromoCodeTotalQuotaAmount(promoCode: Record<string, any>[]): number {
  const total = promoCode.reduce((acc, cur) => {
    return acc + cur.quota;
  }, 0);
  return total;
}

function ConvertStringToNumber(value: string) {
  const result = Number(value);
  return isNaN(result) ? 0 : result;
}

function GetPackageExpiredDateForDraft(value: any) {
  return !value ? null : value;
}

export function CreateCampaignRequest(
  campaignInput: Record<string, any>,
  createRequestType: CreatePromoCodeRequestType
) {
  const baseRequestCampaign = {
    name: createRequestType == CreatePromoCodeRequestType.Create ? campaignInput.name.value : campaignInput.name,
    description:
      createRequestType == CreatePromoCodeRequestType.Create
        ? campaignInput.description.value.trim()
        : campaignInput.description.trim(),
    reference:
      createRequestType == CreatePromoCodeRequestType.Create
        ? campaignInput.reference.value.trim()
        : campaignInput.reference == "-"
        ? null
        : campaignInput.reference.trim(),
    refOne:
      createRequestType == CreatePromoCodeRequestType.Create
        ? String(campaignInput.refOne.value)
        : campaignInput.refOne == "-"
        ? ""
        : String(campaignInput.refOne).trim(),
    refTwo:
      createRequestType == CreatePromoCodeRequestType.Create
        ? String(campaignInput.refTwo.value)
        : campaignInput.refTwo == "-"
        ? ""
        : String(campaignInput.refTwo).trim(),
    isUnique: campaignInput.isUnique,
    isUniqueUser: campaignInput.isUniqueUser,
    isUnpaidUser: campaignInput.isUnpaidUser,
    isRecurring: campaignInput.isRecurring,
    type:
      createRequestType == CreatePromoCodeRequestType.Create
        ? campaignInput.type
        : GetCampaignTypeFromText(campaignInput.type),
    discountNumber:
      createRequestType == CreatePromoCodeRequestType.Create
        ? campaignInput.discountNumber.value.ToNumber()
        : campaignInput.discountNumber,
    discountPercent:
      createRequestType == CreatePromoCodeRequestType.Create
        ? ConvertStringToNumber(campaignInput.discountPercent.value)
        : campaignInput.discountPercent,
    packageId: campaignInput.packageId,
    packagePrice:
      createRequestType == CreatePromoCodeRequestType.Create
        ? ConvertStringToNumber(campaignInput.packagePrice.value)
        : campaignInput.packagePrice,
    packagePeriod:
      createRequestType == CreatePromoCodeRequestType.Create
        ? campaignInput.packagePeriod.value
        : campaignInput.packagePeriod,
    packagePeriodDay:
      createRequestType == CreatePromoCodeRequestType.Create
        ? campaignInput.packagePeriodDay.value
        : campaignInput.packagePeriodDay,
    packageExpiredDate:
      createRequestType == CreatePromoCodeRequestType.Create
        ? GetPackageExpiredDateForDraft(campaignInput.packageExpiredDate.value)
        : campaignInput.packageExpiredDate,
    packagePeriodExtra:
      createRequestType == CreatePromoCodeRequestType.Create
        ? campaignInput.packagePeriodExtra.value.ToNumber()
        : campaignInput.packagePeriodExtra,
    status: createRequestType == CreatePromoCodeRequestType.Select ? PromoStatus.Approve : PromoStatus.WaitingApprove,
    campaignId: createRequestType == CreatePromoCodeRequestType.Create ? null : campaignInput.id,
  };
  return baseRequestCampaign;
}

function DoMatchCampaign(campaignId: number, promoCampaignList: PromoCampaignModel[]) {
  const selectCampaign = promoCampaignList.find((x) => x.id === campaignId);
  return selectCampaign;
}
export function DoMatchAllCampaigns(promoCampaignList: PromoCampaignModel[], allCampaigns: Record<string, any>[]) {
  allCampaigns.forEach((item: any) => {
    const campaignId = item.promoCampaign.campaignId;
    if (campaignId > 0) {
      item.promoCampaign = DoMatchCampaign(campaignId, promoCampaignList);
    } else {
      item.promoCampaign.conditionList = SetCampaignConditionList(item.promoCampaign);
    }
  });
}

function ConvertPromoRequestPromoCodeToPromoRequestCreatePromoCode(
  promoCode: PromoCodeRequestDetailCode[]
): Array<PromoCodeRequest> {
  const promoCodeList = [...new Array(promoCode.length)].map((item: any, index: number) => {
    const promoCodeTemplate = GetPromoCodeRequestObjectTemplate();
    promoCodeTemplate.id = promoCode[index].campaignId;
    promoCodeTemplate.promoCode.value = promoCode[index].code;
    promoCodeTemplate.quota.value = String(promoCode[index].quota);
    promoCodeTemplate.remark.value = promoCode[index].remark || "";
    promoCodeTemplate.startDate.value = promoCode[index].startDateTime.split("T")[0];
    promoCodeTemplate.expireDate.value = promoCode[index].expireDateTime.split("T")[0];
    promoCodeTemplate.fixUserId.isCheck = promoCode[index].fixUserId != 0;
    promoCodeTemplate.fixUserId.id.value = !promoCode[index].fixUserId ? "" : String(promoCode[index].fixUserId);
    promoCodeTemplate.fixFirmId.isCheck = promoCode[index].fixFirmId != 0;
    promoCodeTemplate.fixFirmId.id.value = !promoCode[index].fixFirmId ? "" : String(promoCode[index].fixFirmId);
    promoCodeTemplate.fixClientId.isCheck = promoCode[index].fixClientId != 0;
    promoCodeTemplate.fixClientId.id.value = !promoCode[index].fixClientId ? "" : String(promoCode[index].fixClientId);
    promoCodeTemplate.fixMerchantId.isCheck = promoCode[index].fixMerchantId != 0;
    promoCodeTemplate.fixMerchantId.id.value = !promoCode[index].fixMerchantId
      ? ""
      : String(promoCode[index].fixMerchantId);
    return promoCodeTemplate;
  });
  return promoCodeList;
}

function GetCampaignRequestListValue(value: any): any {
  return value && value != "" ? Number(value) : "";
}

function GetCampaignRequestListTextFieldValue(value: number): string {
  return value && value == 0 ? "" : String(value);
}

function GetCampaignRequestListTextFieldValueFormatMoney(value: number): string {
  const result = GetCampaignRequestListTextFieldValue(value);
  return result != "" ? result.FormatMoney() : result;
}

function ConvertPromoRequestCampaignToPromoRequestCreateCampaign(
  campaign: PromoCodeRequestDetailPromoCampaign
): PromoCampaignCreateTemplateModel {
  const campaignRequestTemplate: PromoCampaignCreateTemplateModel = GetPromoCampaignCreateTemplateDefault();
  campaignRequestTemplate.name.value = campaign.name;
  campaignRequestTemplate.description.value = campaign.description;
  campaignRequestTemplate.refOne.value = GetCampaignRequestListValue(campaign.refOne);
  campaignRequestTemplate.refTwo.value = GetCampaignRequestListValue(campaign.refTwo);
  campaignRequestTemplate.reference.value = String(campaign.reference);
  campaignRequestTemplate.isUnique = campaign.isUnique;
  campaignRequestTemplate.isUniqueUser = campaign.isUniqueUser;
  campaignRequestTemplate.isUnpaidUser = campaign.isUnpaidUser;
  campaignRequestTemplate.isRecurring = campaign.isRecurring;
  campaignRequestTemplate.discountNumber.value = GetCampaignRequestListTextFieldValueFormatMoney(
    campaign.discountNumber
  );
  campaignRequestTemplate.discountPercent.value = GetCampaignRequestListTextFieldValue(campaign.discountPercent);
  campaignRequestTemplate.packageId = campaign.packageId;
  campaignRequestTemplate.packagePrice.value = GetCampaignRequestListTextFieldValueFormatMoney(campaign.packagePrice);
  campaignRequestTemplate.packagePeriod.value = campaign.packagePeriod;
  campaignRequestTemplate.packagePeriodDay.value = campaign.packagePeriodDay.toString();
  campaignRequestTemplate.packageExpiredDate.value = campaign.packageExpiredDate
    ? campaign.packageExpiredDate.split("T")[0]
    : "";
  campaignRequestTemplate.packagePeriodExtra.value = GetCampaignRequestListTextFieldValue(campaign.packagePeriodExtra);

  if (campaign.discountNumber || campaign.discountPercent) {
    campaignRequestTemplate.type = PromoCampaignType.Discount;
    if (campaign.discountNumber) {
      campaignRequestTemplate.discountType = PromoCampaignDiscountType.Amount;
    } else {
      campaignRequestTemplate.discountType = PromoCampaignDiscountType.Percent;
    }
  } else {
    campaignRequestTemplate.discountType = PromoCampaignDiscountType.None;
    if (campaign.packagePrice) {
      campaignRequestTemplate.type = PromoCampaignType.Package;
      if (campaign.packagePeriod) {
        campaignRequestTemplate.periodType = PackagePeriodType.Month;
      } else if (campaign.packagePeriodDay) {
        campaignRequestTemplate.periodType = PackagePeriodType.Day;
      } else {
        campaignRequestTemplate.periodType = PackagePeriodType.ExpiredDate;
      }
    } else {
      campaignRequestTemplate.type = PromoCampaignType.Extra;
    }
  }
  return campaignRequestTemplate;
}

function GetPromoCodeCreateLevelFromPromoRequest(promoCode: PromoCodeRequestDetailCode[]): PromoCodeCreateLevelType {
  const isPromoCodeAdvance = promoCode.some(
    (item) => item.fixClientId || item.fixUserId || item.fixMerchantId || item.fixFirmId
  );
  return isPromoCodeAdvance ? PromoCodeCreateLevelType.Advance : PromoCodeCreateLevelType.Basic;
}

function GetPromoCodeCampaignForEdit(campaignId: number, allCampaignList: Array<PromoCampaignModel>) {
  let campaign: any = allCampaignList.find((item) => item.id == campaignId);
  campaign = { ...campaign, conditionList: SetCampaignConditionList(campaign) };
  return campaign;
}

export function ConvertPromoRequestToPromoRequestCreate(
  promoRequest: PromoCodeRequestDetailModel,
  allCampaignList: Array<PromoCampaignModel> = []
): PromoCodeRequestCreateListModel {
  const campaignList: PromoCodeRequestCreateListModel = [];
  promoRequest.promoRequest.forEach((item) => {
    let requestCampaign: any = {};
    if (item.promoCampaign.campaignId) {
      requestCampaign = {
        promoCodeCampaignCreateType: CreatePromoCodeRequestType.Select,
        campaign: {
          value: GetPromoCodeCampaignForEdit(item.promoCampaign.campaignId, allCampaignList),
          isValidateError: false,
        },
        promoCodeList: ConvertPromoRequestPromoCodeToPromoRequestCreatePromoCode(item.promoCode),
        promoCodeCreateLevel: GetPromoCodeCreateLevelFromPromoRequest(item.promoCode),
      };
    } else {
      requestCampaign = {
        promoCodeCampaignCreateType: CreatePromoCodeRequestType.Create,
        campaignRequest: ConvertPromoRequestCampaignToPromoRequestCreateCampaign(item.promoCampaign),
        promoCodeList: ConvertPromoRequestPromoCodeToPromoRequestCreatePromoCode(item.promoCode),
        promoCodeCreateLevel: GetPromoCodeCreateLevelFromPromoRequest(item.promoCode),
      };
    }
    campaignList.push(requestCampaign);
  });
  return campaignList;
}

function GetDiscountValue(value: any) {
  return Number(value) ? 0 : Number(value);
}

function GetPromoCodePriceForCampaignRequest(campaignInput: PromoCampaignCreateTemplateModel) {
  switch (campaignInput.type) {
    case PromoCampaignType.Discount:
      return campaignInput.discountNumber.value.ToNumber();
    case PromoCampaignType.Package:
      return campaignInput.packagePrice.value.ToNumber();
    case PromoCampaignType.Extra:
      return 0;
  }
}

export function ConvertToPromoDraftRequest(
  campaignList: PromoCodeRequestCreateListModel,
  input: any,
  createType: SendPromoRequestType
): PromoDraftingRequest {
  const promoRequestList: any = campaignList.map((item: Record<string, any>) => {
    const campaignInput =
      item.promoCodeCampaignCreateType == CreatePromoCodeRequestType.Select
        ? item.campaign.value
        : item.campaignRequest;
    const request: any = {
      promoCampaign: CreateCampaignRequest(campaignInput, item.promoCodeCampaignCreateType),
      promoCode: item.promoCodeList.map((promoCodeItem: PromoCodeRequest) => {
        return {
          code: promoCodeItem.promoCode.value.trim(),
          status: input.status,
          quota: parseInt(promoCodeItem.quota.value),
          productCode: null,
          accountCode: null,
          price:
            item.promoCodeCampaignCreateType == CreatePromoCodeRequestType.Select
              ? item.campaign.value.discountNumber
              : GetPromoCodePriceForCampaignRequest(campaignInput),
          netAmount:
            item.promoCodeCampaignCreateType == CreatePromoCodeRequestType.Select
              ? GetDiscountValue(item.campaign.value.discountNumber) * GetDiscountValue(promoCodeItem.quota.value)
              : GetDiscountValue(item.campaignRequest.discountNumber.value) *
                GetDiscountValue(promoCodeItem.quota.value),
          fixUserId: ConvertStringToNumberForCampaignCondition(promoCodeItem.fixUserId.id.value),
          fixMerchantId: ConvertStringToNumberForCampaignCondition(promoCodeItem.fixMerchantId.id.value),
          fixFirmId: ConvertStringToNumberForCampaignCondition(promoCodeItem.fixFirmId.id.value),
          fixClientId: ConvertStringToNumberForCampaignCondition(promoCodeItem.fixClientId.id.value),
          campaignId:
            item.promoCodeCampaignCreateType == CreatePromoCodeRequestType.Select ? item.campaign.value.id : 0,
          startDateTime: new Date(promoCodeItem.startDate.value).toISOString(),
          expireDateTime: new Date(promoCodeItem.expireDate.value).toISOString(),
          remark: promoCodeItem.remark.value.trim(),
          UserRegisterType: null,
          isGenerateInvoice: false,
        };
      }),
    };
    return request;
  });

  let _input: PromoDraftingRequest | PromoDraftingEditRequest = {
    promoRequest: promoRequestList,
    requestNumber: input.requestNumber,
    status: input.status,
    asanaTask: input.asanaTask,
    reference1: input.reference1,
    fileUrl: input.fileUrl,
    referenceQuotation: input.referenceQuotation ? input.referenceQuotation : null,
    date: input.date,
  };

  if (createType == SendPromoRequestType.SendEdit) {
    _input = { ..._input, requestId: input.requestId };
  }
  return _input;
}

export function ConvertPromoRequestToDraftHeaderDetail(promoRequest: any): PromoCodeRequestHeaderDetailModel {
  const headerDetail: PromoCodeRequestHeaderDetailModel = {
    requestDate: { value: promoRequest.requestDateTime.split("T")[0].ConvertToDateFormat(), isError: false },
    requestNo: { value: promoRequest.requestNumber, isError: false },
    asanaTask: { value: promoRequest.asanaTask == null ? "" : promoRequest.asanaTask, isError: false },
    ref1: { value: promoRequest.reference1, isError: false },
  };
  return headerDetail;
}

export function PromoCodeRequestErrorAlert(error: Record<string, any>) {
  let message = "";
  switch (error.adminExceptionCode) {
    case AdminExceptionResponseCode.DuplicatePromoCode:
      message = `พบ Promo Code ซ้ำ ${error.duplicateCode.length} โค้ด: ${error.duplicateCode.join(", ")}`;
      break;
    case AdminExceptionResponseCode.PromoRequestNumberExist:
      message = "เลข Request Number ซ้ำ กรุณากดส่งใหม่อีกครั้ง";
      break;
    case AdminExceptionResponseCode.DuplicatePromoCampaign:
      message = "Promo Campaign ซ้ำ";
      break;
    case AdminExceptionResponseCode.InvalidPromoRequestData:
      message = "ไม่สามารถส่งข้อมูลได้ เนื่องจากข้อมูลไม่ถูกต้อง";
      break;
    default:
      message = "ไม่สามารถส่งข้อมูลได้";
      break;
  }
  alert(message);
}
