import { VerifiedStatus } from "@/enums/enums";
import AuthService from "@/service/authService";

const auth = new AuthService();

export function SetHeaderMerchantTable(tableViewModel: any) {
  const header = [
    {
      text: "",
      align: "flex-start",
      filterable: false,
      value: "index",
    },
    {
      text: "Merchant Id",
      align: "center",
      filterable: false,
      value: "merchantId",
    },
    {
      text: "Merchant Code",
      align: "flex-start",
      filterable: true,
      value: "merchantCode",
    },
    {
      text: "Merchant Name",
      align: "flex-start",
      filterable: true,
      value: "merchantName",
    },
    {
      text: "Current Main Package",
      align: "flex-start",
      filterable: false,
      value: "currentMainPackage",
    },
    {
      text: "Old Package",
      align: "flex-start",
      filterable: false,
      value: "oldPackage",
    },
    {
      text: "Expired DateTime",
      align: "center",
      filterable: false,
      value: "expiredDateTime",
    },
    {
      text: "Create DateTime",
      align: "center",
      filterable: false,
      value: "createDateTime",
    },
    {
      text: "Payment Datetime",
      align: "center",
      filterable: false,
      value: "paymentDatetime",
    },
    {
      text: "Payment PromoCode",
      align: "flex-start",
      filterable: false,
      value: "paymentPromoCode",
    },
    {
      text: "Payment Campaign Name",
      align: "flex-start",
      filterable: false,
      value: "paymentCampaignName",
    },
    {
      text: "Payment Gateway Type",
      align: "flex-start",
      filterable: false,
      value: "paymentGatewayType",
    },
    {
      text: "Payment Username",
      align: "flex-start",
      filterable: false,
      value: "paymentUsername",
    },
    {
      text: "Payment Status",
      align: "flex-start",
      filterable: false,
      value: "paymentStaus",
    },
    {
      text: "WHT",
      align: "flex-start",
      filterable: false,
      value: "wht",
    },
    {
      text: "Actions",
      align: "center",
      value: "actions",
      sortable: false,
    },
  ];
  return header;
}
export function SetHeaderMerchantForVerifyTable(tableViewModel: any) {
  const header = [
    {
      text: "",
      align: "flex-start",
      filterable: false,
      value: "index",
    },
    {
      text: "Merchant ID",
      align: "flex-start",
      filterable: false,
      value: "merchantId",
    },
    {
      text: "Merchant Name",
      align: "flex-start",
      filterable: true,
      value: "merchantName",
    },
    {
      text: "Tax ID",
      align: "center",
      filterable: false,
      value: "taxId",
    },
    {
      text: "Branch Code",
      align: "center",
      filterable: false,
      value: "branchCode",
    },
    {
      text: "Logo",
      align: "center",
      filterable: false,
      value: "logo",
    },
    {
      text: "Verified Status",
      align: "flex-start",
      filterable: false,
      value: "status",
    },
    {
      text: "Actions",
      align: "center",
      value: "actions",
      sortable: false,
    },
  ];
  return header;
}

export function GetVerifiedStatus(verifiedStatus: VerifiedStatus) {
  let status = "";
  let color = "";

  switch (verifiedStatus) {
    case VerifiedStatus.None:
      status = "None";
      color = "none";
      break;
    case VerifiedStatus.Processing:
      status = "Processing";
      color = "processing";
      break;
    case VerifiedStatus.Success:
      status = "Success";
      color = "success";
      break;
  }

  return { status, color };
}

export function GetMerchantConnectionConfigTemplate(connectionConfigModel: any) {
  const connectionConfigTemplate: any = {
    shopeeConnectionConfig: {
      value: connectionConfigModel.shopeeConnectionConfig.maximumConnection
        ? connectionConfigModel.shopeeConnectionConfig.maximumConnection
        : 1,
      validateError: false,
    },
    lazadaConnectionConfig: {
      value: connectionConfigModel.lazadaConnectionConfig.maximumConnection
        ? connectionConfigModel.lazadaConnectionConfig.maximumConnection
        : 1,
      validateError: false,
    },
  };
  return connectionConfigTemplate;
}

export async function SetHeaderService() {
  return {
    headers: {
      Authorization: `Bearer ${await auth.getAccessToken()}`,
    },
  };
}
