import { AdminExceptionResponseCode, LoadingStatusType } from "@/enums/enums";
import {
  PromoCodeRequestErrorAlert,
  SetHeaderPromoCampaignTable,
  SetHeaderPromoCodeTable,
  SetHeaderPromoRequestStatusPromoCodeTable,
  SetHeaderPromoRequestTable,
} from "@/helper/promoCodeHelper";
import {
  PromoCampaignModel,
  PromoCodeCreateListRequest,
  PromoCodeCreatePatternRequest,
  PromoCodeCreateRequest,
  PromoCodeListRequestModel,
  PromoCodeModel,
  PromoCodeReferenceTransactionRequest,
  PromoCodeRequestDetailModel,
  PromoDraftingIndexRequest,
  PromoDraftingRequest,
  PromoRequestElementListRequest,
  PromoRequestTableList,
} from "@/model/promoCode/promoCodeModel";
import API from "@/service/Index";
import {
  ConvertToGeneratePromoCode,
  ConvertToPromoCampaignList,
  ConvertToPromoCodeList,
  ConvertToPromoRequestTableList,
} from "@/service/promoCode/promoCodeFacade";
import store from "@/store";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
@Module({ namespaced: true })
export default class PromoCode extends VuexModule {
  public promoCampaignList: Array<PromoCampaignModel> | null = null;
  public promoCampaignListLoadingStatus: LoadingStatusType = LoadingStatusType.None;
  public headerPromoCampaignTable: Array<any> = [];
  public promoCodeList: Array<PromoCodeModel> | null = null;
  public countPromoCodeItem: number | null = 0;
  public promoCodeListLoadingStatus: LoadingStatusType = LoadingStatusType.None;
  public headerPromoCodeTable: Array<any> = [];
  public createPromoCampaignLoadingStatus: LoadingStatusType = LoadingStatusType.None;
  public createPromoCodeLoadingStatus: LoadingStatusType = LoadingStatusType.None;
  public promoRequestTableListLoadingStatus: LoadingStatusType = LoadingStatusType.None;
  public promoCodeRequestStatus: Record<string, any> = {};
  public promoCodeRequestStatusLoadingStatus: LoadingStatusType = LoadingStatusType.None;
  public promoRequestTableList: Array<any> = [];
  public countPromoRequestTableList: number | null = 0;
  public headerPromoRequestTable: Array<any> = [];
  public promoRequestNumber: any = null;
  public promoRequestNumberLoading: LoadingStatusType = LoadingStatusType.None;
  public generatePromoCodeRequestLoading: LoadingStatusType = LoadingStatusType.None;
  public generatePromoCodeList: Array<any> = [];
  public referenceQuotationLoading: LoadingStatusType = LoadingStatusType.None;
  public draftPromoRequestLoading: LoadingStatusType = LoadingStatusType.None;
  public requestId: string | null = "0";
  public promoRequestLoading: LoadingStatusType = LoadingStatusType.None;
  public promoRequest: PromoCodeRequestDetailModel | null = null;
  public editPromoRequestLoading: LoadingStatusType = LoadingStatusType.None;
  public approvePromoRequestLoading: LoadingStatusType = LoadingStatusType.None;
  public voidPromoRequestLoading: LoadingStatusType = LoadingStatusType.None;
  public promoCampaignTransactionDataLoading: LoadingStatusType = LoadingStatusType.None;
  public promoCampaignTransactionData: any = null;
  public headerPromoRequestStatusPromoCodeTable: Record<string, any>[] = [];
  public approvePromoRequestLoadingStatus!: LoadingStatusType;

  get getPromoCampaignList(): Array<PromoCampaignModel> | null {
    return this.promoCampaignList;
  }

  get getHeaderPromoRequestStatusPromoCodeTable(): Record<string, any>[] {
    return this.headerPromoRequestStatusPromoCodeTable;
  }

  get getPromoCodeRequestStatus(): Record<string, any> {
    return this.promoCodeRequestStatus;
  }

  get getPromoCodeRequestStatusLoadingStatus(): LoadingStatusType {
    return this.promoCodeRequestStatusLoadingStatus;
  }

  get getpromoCampaignTransactionDataLoading(): LoadingStatusType {
    return this.promoCampaignTransactionDataLoading;
  }

  get getPromoCampaignListLoadingStatus(): LoadingStatusType {
    return this.promoCampaignListLoadingStatus;
  }

  get getVoidPromoRequestLoading(): LoadingStatusType {
    return this.voidPromoRequestLoading;
  }

  get getApprovePromoRequestLoading(): LoadingStatusType {
    return this.approvePromoRequestLoading;
  }

  get getEditPromoRequestLoading(): LoadingStatusType {
    return this.editPromoRequestLoading;
  }

  get getPromoRequestLoading(): LoadingStatusType {
    return this.promoRequestLoading;
  }

  get getPromoRequest(): PromoCodeRequestDetailModel | null {
    return this.promoRequest;
  }

  get getPromoCampaignTransactionData(): any {
    return this.promoCampaignTransactionData;
  }

  get getRequestId(): string | null {
    return this.requestId;
  }

  get getDraftPromoRequestLoading(): LoadingStatusType {
    return this.draftPromoRequestLoading;
  }

  get getGeneratePromoCodeRequestLoading(): LoadingStatusType {
    return this.generatePromoCodeRequestLoading;
  }

  get getReferenceQuotationLoading(): LoadingStatusType {
    return this.referenceQuotationLoading;
  }

  get getGeneratePromoCodeList(): Array<any> {
    return this.generatePromoCodeList;
  }

  get getHeaderPromoCampaignTable(): Array<any> {
    return this.headerPromoCampaignTable;
  }

  get getPromoRequestNumber(): any {
    return this.promoRequestNumber;
  }

  get getPromoRequestNumberLoading(): LoadingStatusType {
    return this.promoRequestNumberLoading;
  }

  get getPromoCodeList(): Array<PromoCodeModel> | null {
    return this.promoCodeList;
  }

  get getPromoRequestTableList(): Array<any> {
    return this.promoRequestTableList;
  }

  get getHeaderPromoRequestTable(): Array<any> {
    return this.headerPromoRequestTable;
  }

  get getCountPromoRequestTableList(): number | null {
    return this.countPromoRequestTableList;
  }

  get getCountPromoCodeItem(): number | null {
    return this.countPromoCodeItem;
  }

  get getPromoCodeListLoadingStatus(): LoadingStatusType {
    return this.promoCodeListLoadingStatus;
  }

  get getPromoRequestTableListLoadingStatus(): LoadingStatusType {
    return this.promoRequestTableListLoadingStatus;
  }

  get getHeaderPromoCodeTable(): Array<any> {
    return this.headerPromoCodeTable;
  }

  get getCreatePromoCampaignLoadingStatus(): LoadingStatusType {
    return this.createPromoCampaignLoadingStatus;
  }

  get getCreatePromoCodeLoadingStatus(): LoadingStatusType {
    return this.createPromoCodeLoadingStatus;
  }

  @Mutation
  public SetHeaderPromoRequestStatusPromoCodeTable() {
    this.headerPromoRequestStatusPromoCodeTable = SetHeaderPromoRequestStatusPromoCodeTable();
  }

  @Mutation
  public SetPromoCodeRequestStatusLoadingStatus(value: LoadingStatusType) {
    this.promoCodeRequestStatusLoadingStatus = value;
  }

  @Mutation
  public SetPromoCodeRequestStatus(value: Record<string, any>) {
    this.promoCodeRequestStatus = value;
  }

  @Mutation
  public SetPromoCampaignList(promoCampaignList: Array<PromoCampaignModel>) {
    this.promoCampaignList = promoCampaignList;
  }

  @Mutation
  public SetPromoRequest(promoRequest: PromoCodeRequestDetailModel | null) {
    this.promoRequest = promoRequest;
  }

  @Mutation
  public SetRequestId(requestId: string | null) {
    this.requestId = requestId;
  }

  @Mutation
  public SetPromoCampaignListLoadingStatus(status: LoadingStatusType) {
    this.promoCampaignListLoadingStatus = status;
  }

  @Mutation
  public SetApprovePromoRequestLoading(status: LoadingStatusType) {
    this.approvePromoRequestLoading = status;
  }

  @Mutation
  public SetVoidPromoRequestLoading(status: LoadingStatusType) {
    this.voidPromoRequestLoading = status;
  }

  @Mutation
  public SetPromoCampaignTransactionDataLoading(status: LoadingStatusType) {
    this.promoCampaignTransactionDataLoading = status;
  }

  @Mutation
  public SetpromoCampaignTransactionData(data: any) {
    this.promoCampaignTransactionData = data;
  }

  @Mutation
  public SetEditPromoRequestLoading(status: LoadingStatusType) {
    this.editPromoRequestLoading = status;
  }

  @Mutation
  public SetPromoRequestLoading(status: LoadingStatusType) {
    this.promoRequestLoading = status;
  }

  @Mutation
  public SetDraftPromoRequestLoading(status: LoadingStatusType) {
    this.draftPromoRequestLoading = status;
  }

  @Mutation
  public SetReferenceQuotationLoading(status: LoadingStatusType) {
    this.referenceQuotationLoading = status;
  }

  @Mutation
  public SetGeneratePromoCodeRequestLoading(status: LoadingStatusType) {
    this.generatePromoCodeRequestLoading = status;
  }

  @Mutation
  public SetGeneratePromoCodeList(generatePromoCodeList: Array<any>) {
    this.generatePromoCodeList = generatePromoCodeList;
  }

  @Mutation
  public SetHeaderPromoCampaignTable() {
    this.headerPromoCampaignTable = SetHeaderPromoCampaignTable();
  }

  @Mutation
  public SetPromoCodeList(promoCodeTable: any) {
    this.promoCodeList = promoCodeTable.promoCodeList;
    this.countPromoCodeItem = promoCodeTable.countItem;
  }

  @Mutation
  public SetPromoRequestTableList(input: any) {
    this.promoRequestTableList = input.tableList;
    this.countPromoRequestTableList = input.countItem;
  }

  @Mutation
  public SetPromoCodeListLoadingStatus(status: LoadingStatusType) {
    this.promoCodeListLoadingStatus = status;
  }

  @Mutation
  public SetPromoRequestTableListLoadingStatus(status: LoadingStatusType) {
    this.promoRequestTableListLoadingStatus = status;
  }

  @Mutation
  public SetPromoRequestNumber(requestNumber: any) {
    this.promoRequestNumber = requestNumber;
  }

  @Mutation
  public SetPromoRequestNumberLoading(status: LoadingStatusType) {
    this.promoRequestNumberLoading = status;
  }

  @Mutation
  public SetHeaderPromoCodeTable() {
    this.headerPromoCodeTable = SetHeaderPromoCodeTable();
  }

  @Mutation
  public SetHeaderPromoRequestTable() {
    this.headerPromoRequestTable = SetHeaderPromoRequestTable();
  }

  @Mutation
  public SetCreatePromoCampaignLoadingStatus(status: LoadingStatusType) {
    this.createPromoCampaignLoadingStatus = status;
  }

  @Mutation
  public SetCreatePromoCodeLoadingStatus(status: LoadingStatusType) {
    this.createPromoCodeLoadingStatus = status;
  }

  @Action
  public DoGetPromoCampaignList() {
    if (this.promoCampaignListLoadingStatus !== LoadingStatusType.Loading) {
      this.context.commit("SetPromoCampaignListLoadingStatus", LoadingStatusType.Loading);
      API.PromoCodeService.DoGetPromoCampaignList().then((res) => {
        if (res.data.responseCode.IsSuccess()) {
          const promoCampaignList = ConvertToPromoCampaignList(res.data.promoCampaignList);
          this.context.commit("SetPromoCampaignList", promoCampaignList);
          this.context.commit("SetHeaderPromoCampaignTable");
          this.context.commit("SetPromoCampaignListLoadingStatus", LoadingStatusType.Success);
        }
      });
    }
  }

  @Action
  public DoGetPromoCodeList(input: PromoCodeListRequestModel) {
    if (this.promoCodeListLoadingStatus !== LoadingStatusType.Loading) {
      this.context.commit("SetPromoCodeListLoadingStatus", LoadingStatusType.Loading);
      API.PromoCodeService.DoGetPromoCodeList(input).then((res) => {
        if (res.data.responseCode.IsSuccess()) {
          const promoCodeList = ConvertToPromoCodeList(res.data.promoCodeList, this.promoCampaignList);
          const countItem = res.data.countItem;
          const input = {
            promoCodeList: promoCodeList,
            countItem: countItem,
          };
          this.context.commit("SetPromoCodeList", input);
          this.context.commit("SetHeaderPromoCodeTable");
          this.context.commit("SetPromoCodeListLoadingStatus", LoadingStatusType.Success);
        }
      });
    }
  }

  @Action
  public DoCreatePromoCampaign(input: any) {
    if (this.createPromoCampaignLoadingStatus !== LoadingStatusType.Loading) {
      this.context.commit("SetCreatePromoCampaignLoadingStatus", LoadingStatusType.Loading);
      this.context.dispatch("Loading/IsLoading", true, { root: true });
      API.PromoCodeService.DoCreatePromoCampaign(input).then((res) => {
        let message = "";
        if (res.data.responseCode.IsSuccess()) {
          message = "สร้าง Promo Campaign สำเร็จ";
        } else {
          message = "สร้าง Promo Campaign ไม่สำเร็จ";
        }
        alert(message);
        this.context.commit("SetCreatePromoCampaignLoadingStatus", LoadingStatusType.Success);
        this.context.dispatch("Loading/IsLoading", false, { root: true });
      });
    }
  }

  @Action
  public DoCreatePromoCode(input: PromoCodeCreateRequest) {
    if (this.createPromoCodeLoadingStatus !== LoadingStatusType.Loading) {
      this.context.commit("SetCreatePromoCodeLoadingStatus", LoadingStatusType.Loading);
      this.context.dispatch("Loading/IsLoading", true, { root: true });
      API.PromoCodeService.DoCreatePromoCode(input).then((res) => {
        let message = "";
        if (res.data.responseCode.IsSuccess()) {
          message = "สร้าง Promo Code สำเร็จ";
        } else {
          message = "สร้าง Promo Code ไม่สำเร็จ";
        }
        alert(message);
        this.context.commit("SetCreatePromoCodeLoadingStatus", LoadingStatusType.Success);
        this.context.dispatch("Loading/IsLoading", false, { root: true });
      });
    }
  }

  @Action
  public DoCreateListPromoCode(input: PromoCodeCreateListRequest) {
    if (this.createPromoCodeLoadingStatus !== LoadingStatusType.Loading) {
      this.context.commit("SetCreatePromoCodeLoadingStatus", LoadingStatusType.Loading);
      this.context.dispatch("Loading/IsLoading", true, { root: true });
      API.PromoCodeService.DoCreateListPromoCode(input).then((res) => {
        let message = "";
        if (res.data.responseCode.IsSuccess()) {
          message = "สร้าง Promo Code สำเร็จ";
        } else {
          message = "สร้าง Promo Code ไม่สำเร็จ";
        }
        alert(message);
        this.context.commit("SetCreatePromoCodeLoadingStatus", LoadingStatusType.Success);
        this.context.dispatch("Loading/IsLoading", false, { root: true });
      });
    }
  }

  @Action
  public DoGetPromoRequestTableList(input: PromoRequestTableList) {
    if (this.promoRequestTableListLoadingStatus !== LoadingStatusType.Loading) {
      this.context.commit("SetPromoRequestTableListLoadingStatus", LoadingStatusType.Loading);
      API.PromoCodeService.DoGetPromoRequestTableList(input).then((res) => {
        if (res.data.responseCode.IsSuccess()) {
          const promoCodeTableList = ConvertToPromoRequestTableList(res.data.promoRequest);
          const countItem = res.data.countItem;
          const input = {
            tableList: promoCodeTableList,
            countItem: countItem,
          };
          this.context.commit("SetPromoRequestTableList", input);
          this.context.commit("SetHeaderPromoRequestTable");
          this.context.commit("SetPromoRequestTableListLoadingStatus", LoadingStatusType.Success);
        }
      });
    }
  }

  @Action
  public DoGetPromoRequestNumber() {
    if (this.promoRequestNumberLoading !== LoadingStatusType.Loading) {
      this.context.commit("SetPromoRequestNumberLoading", LoadingStatusType.Loading);
      API.PromoCodeService.DoGetPromoRequestNumber()
        .then((res) => {
          if (res.data.responseCode.IsSuccess()) {
            this.context.commit("SetPromoRequestNumber", res.data.requestNumber);
            this.context.commit("SetPromoRequestNumberLoading", LoadingStatusType.Success);
          }
        })
        .catch((err) => {
          if (err.response.status.IsUnauthorized()) {
            alert("ไม่สามารถโหลด Request Number ได้ กรุณาล็อกอินใหม่");
          } else {
            alert("ไม่สามารถโหลด Request Number ได้");
          }
          this.context.commit("SetPromoRequestNumberLoading", LoadingStatusType.Fail);
        });
    }
  }

  @Action
  public DoGeneratePromoCodeRequest(input: PromoCodeCreatePatternRequest) {
    if (this.generatePromoCodeRequestLoading !== LoadingStatusType.Loading) {
      this.context.commit("SetGeneratePromoCodeRequestLoading", LoadingStatusType.Loading);
      API.PromoCodeService.DoGeneratePromoCodeRequest(input).then((res) => {
        if (res.data.responseCode.IsSuccess()) {
          const promoCodeRequest = ConvertToGeneratePromoCode(res.data.promoRequest);
          this.context.commit("SetGeneratePromoCodeList", promoCodeRequest);
          this.context.commit("SetGeneratePromoCodeRequestLoading", LoadingStatusType.Success);
        }
      });
    }
  }

  @Action
  public DoGetReferenceQuotation(input: PromoCodeReferenceTransactionRequest) {
    if (this.referenceQuotationLoading !== LoadingStatusType.Loading) {
      this.context.commit("SetReferenceQuotationLoading", LoadingStatusType.Loading);
      API.PromoCodeService.DoGetReferenceQuotation(input).then((res) => {
        if (res.data.responseCode.IsSuccess()) {
          const promoCodeRequest = ConvertToGeneratePromoCode(res.data.promoRequest);
          this.context.commit("SetGeneratePromoCodeList", promoCodeRequest);
          this.context.commit("SetReferenceQuotationLoading", LoadingStatusType.Success);
        }
      });
    }
  }

  @Action
  public DoDraftPromoRequest(input: PromoDraftingRequest) {
    if (this.draftPromoRequestLoading !== LoadingStatusType.Loading) {
      this.context.commit("SetDraftPromoRequestLoading", LoadingStatusType.Loading);
      API.PromoCodeService.DoDraftPromoRequest(input)
        .then((res) => {
          if (res.data.responseCode.IsSuccess()) {
            this.context.commit("SetRequestId", res.data.requestId);
            this.context.commit("SetDraftPromoRequestLoading", LoadingStatusType.Success);
          } else {
            PromoCodeRequestErrorAlert(res.data.exception);
            if (res.data.exception.adminExceptionCode == AdminExceptionResponseCode.DuplicatePromoCode) {
              store.dispatch("PromoCodeRequest/SetPromoCodeDuplicateError", res.data.exception.duplicateCode);
            } else if (res.data.exception.adminExceptionCode == AdminExceptionResponseCode.PromoRequestNumberExist) {
              this.context.dispatch("DoGetPromoRequestNumber");
            }
            this.context.commit("SetDraftPromoRequestLoading", LoadingStatusType.Fail);
          }
        })
        .catch((err) => {
          if (err.response.status.IsUnauthorized()) {
            alert("ไม่สามารถส่งข้อมูลได้ กรุณาล็อกอินใหม่");
          } else {
            alert("ไม่สามารถส่งข้อมูลได้");
          }
          this.context.commit("SetDraftPromoRequestLoading", LoadingStatusType.Fail);
        });
    }
  }

  @Action
  public DoGetPromoRequest(input: PromoDraftingIndexRequest) {
    if (this.promoRequestLoading !== LoadingStatusType.Loading) {
      this.context.commit("SetPromoRequestLoading", LoadingStatusType.Loading);
      API.PromoCodeService.DoGetPromoRequest(input).then((res) => {
        if (res.data.responseCode.IsSuccess()) {
          this.context.commit("SetPromoRequest", res.data.promoRequest);
          this.context.commit("SetHeaderPromoRequestStatusPromoCodeTable");
          this.context.commit("SetPromoRequestLoading", LoadingStatusType.Success);
        }
      });
    }
  }

  @Action
  public DoGetPromoRequestForDraft(input: PromoDraftingIndexRequest) {
    if (this.promoRequestLoading !== LoadingStatusType.Loading) {
      this.context.commit("SetPromoRequestLoading", LoadingStatusType.Loading);
      API.PromoCodeService.DoGetPromoRequest(input).then((res) => {
        if (res.data.responseCode.IsSuccess()) {
          this.context.commit("SetPromoRequest", res.data.promoRequest);
          store.dispatch("PromoCodeRequest/DoUpdateCampaignListFromPromoRequest", this.promoRequest);
          this.context.commit("SetPromoRequestLoading", LoadingStatusType.Success);
        }
      });
    }
  }

  @Action
  public DoEditPromoRequest(input: PromoDraftingRequest) {
    if (this.editPromoRequestLoading !== LoadingStatusType.Loading) {
      this.context.commit("SetEditPromoRequestLoading", LoadingStatusType.Loading);
      API.PromoCodeService.DoEditPromoRequest(input)
        .then((res) => {
          if (res.data.responseCode.IsSuccess()) {
            this.context.commit("SetEditPromoRequestLoading", LoadingStatusType.Success);
          } else {
            PromoCodeRequestErrorAlert(res.data.exception);
            if (res.data.exception.adminExceptionCode == AdminExceptionResponseCode.DuplicatePromoCode) {
              store.dispatch("PromoCodeRequest/SetPromoCodeDuplicateError", res.data.exception.duplicateCode);
            }
            this.context.commit("SetEditPromoRequestLoading", LoadingStatusType.Fail);
          }
        })
        .catch((err) => {
          if (err.response.status.IsUnauthorized()) {
            alert("ไม่สามารถส่งข้อมูลได้ กรุณาล็อกอินใหม่");
          } else {
            alert("ไม่สามารถส่งข้อมูลได้");
          }
          this.context.commit("SetEditPromoRequestLoading", LoadingStatusType.Fail);
        });
    }
  }

  @Action
  public DoApprovePromoRequest(input: PromoDraftingIndexRequest) {
    if (this.approvePromoRequestLoading !== LoadingStatusType.Loading) {
      this.context.commit("SetApprovePromoRequestLoading", LoadingStatusType.Loading);
      API.PromoCodeService.DoApprovePromoRequest(input).then((res) => {
        if (res.data.responseCode.IsSuccess()) {
          this.context.commit("SetApprovePromoRequestLoading", LoadingStatusType.Success);
          alert("Approve Promo Code สำเร็จ!");
        } else {
          PromoCodeRequestErrorAlert(res.data.exception);
          this.context.commit("SetApprovePromoRequestLoading", LoadingStatusType.Fail);
        }
      });
    }
  }

  @Action
  public DoVoidPromoRequest(input: PromoDraftingIndexRequest) {
    if (this.voidPromoRequestLoading !== LoadingStatusType.Loading) {
      this.context.commit("SetVoidPromoRequestLoading", LoadingStatusType.Loading);
      API.PromoCodeService.DoVoidPromoRequest(input).then((res) => {
        if (res.data.responseCode.IsSuccess()) {
          this.context.commit("SetVoidPromoRequestLoading", LoadingStatusType.Success);
        }
      });
    }
  }

  @Action
  public DoGetPromoCampaignTransactionData(input: PromoRequestElementListRequest) {
    if (this.promoCampaignTransactionDataLoading !== LoadingStatusType.Loading) {
      this.context.commit("SetPromoCampaignTransactionDataLoading", LoadingStatusType.Loading);
      API.PromoCodeService.DoGetPromoCampaignTransactionData(input).then((res) => {
        if (res.data.responseCode.IsSuccess()) {
          this.context.commit("SetpromoCampaignTransactionData", res.data.promoRequest);
          this.context.commit("SetPromoCampaignTransactionDataLoading", LoadingStatusType.Success);
        }
      });
    }
  }
}
