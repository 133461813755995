import { SetHeaderService } from "@/helper/merchantHelper";
import { AccountingFirm } from "@/model/accountingFrim/accountingFrimModel";
import service from "../baseHttpService";

class AccountingFirmService {
  async DoGetAccountingFirmInternalCode() {
    return service.peakAdminAPI
      .get("/api/AccountingFirm/GetAccountingFirmInternalCode", await SetHeaderService())
      .then((res) => {
        return res;
      });
  }
  async DoGetAccountingFirms() {
    return service.peakAdminAPI.get("/api/AccountingFirm/GetAccountingFirms", await SetHeaderService()).then((res) => {
      return res;
    });
  }
  async DoInsertAccountingFirm(AccountingFirm: AccountingFirm) {
    const formData = new FormData();
    formData.append("name", AccountingFirm.name);
    formData.append("nameEn", AccountingFirm.nameEn);
    formData.append("afpRefCode", AccountingFirm.afpRefCode);
    formData.append("internalCode", AccountingFirm.internalCode);
    formData.append("contactName", AccountingFirm.contactName);
    formData.append("address", AccountingFirm.address);
    formData.append("phoneNumber", AccountingFirm.phoneNumber);
    formData.append("email", AccountingFirm.email);
    formData.append(`logo`, AccountingFirm.logoUrl);
    return service.peakAdminAPIForUpload
      .post("/api/AccountingFirm/InsertAccountingFirm", formData, await SetHeaderService())
      .then((res) => {
        return res;
      });
  }
  async DoUpdateAccountingFirm(AccountingFirm: any) {
    const formData = new FormData();
    formData.append("id", AccountingFirm.id);
    formData.append("name", AccountingFirm.name);
    formData.append("nameEn", AccountingFirm.nameEn);
    formData.append("afpRefCode", AccountingFirm.afpRefCode);
    formData.append("internalCode", AccountingFirm.internalCode);
    formData.append("contactName", AccountingFirm.contactName);
    formData.append("address", AccountingFirm.address);
    formData.append("phoneNumber", AccountingFirm.phoneNumber);
    formData.append("email", AccountingFirm.email);
    formData.append("logoUrl", AccountingFirm.currentLogoUrl);
    formData.append("deleteLogo", AccountingFirm.deleteLogo);
    formData.append(`logo`, AccountingFirm.logoUrl);
    return service.peakAdminAPIForUpload
      .post("/api/AccountingFirm/UpdateAccountingFirm", formData, await SetHeaderService())
      .then((res) => {
        return res;
      });
  }
  async DoGetAccountingFirmTable(input: any) {
    return service.peakAdminAPI
      .get(
        `/api/AccountingFirm/GetAccountingFirmTable?pageSize=${input.pageSize}&pageNo=${input.pageNo}${
          input.name ? `&name=${input.name}` : ""
        }${input.code ? `&internalCode=${input.code}` : ""}${
          input.sortItem ? `&sort=${input.sortItem}:${input.sortBy}` : ""
        }&accountingFirmStatus=${input.accountingFirmStatus}`,
        await SetHeaderService()
      )
      .then((res) => {
        return res;
      });
  }
  async DoDeleteAccountingFirm(input: any) {
    return service.peakAdminAPI
      .post(`/api/AccountingFirm/DeleteAccountingFirm`, input, await SetHeaderService())
      .then((res) => {
        return res;
      });
  }
  async DoGetAccountingFirmDetail(accountingFirmId: string) {
    return service.peakAdminAPI
      .get(`/api/AccountingFirm/GetAccountingFirmById?accountingFirmId=${accountingFirmId}`, await SetHeaderService())
      .then((res) => {
        return res;
      });
  }
}

export default new AccountingFirmService();
