import { SetHeaderService } from "@/helper/merchantHelper";
import { ApprovePackageRequestModel } from "@/model/merchant/approvePackageRequestModel";
import { CancelPeakPaymentConnectByIdRequestModel } from "@/model/merchant/cancelPeakPaymentConnectByIdRequestModel";
import {
  MerchantDetailForVerifyMerchantRequest,
  UpdateVerificationStatusMerchantRequest,
} from "@/model/merchant/merchantModel";
import { MerchantTableViewModel } from "@/model/merchant/merchantTableViewModel";
import { SwapMerchantPeakPackageRequestModel } from "@/model/merchant/swapMerchantPeakPackageRequestModel";
import { TransferPeakPackageRequestModel } from "@/model/merchant/transferPeakPackageRequestModel";
import { ConvertToMerchantDetailListRequest } from "@/service/merchant/merchantFacade";
import service from "../baseHttpService";

class MerchantService {
  async DoGetMerchantTable(merchantTableViewModel: MerchantTableViewModel) {
    const merchantDetailListRequest = ConvertToMerchantDetailListRequest(merchantTableViewModel);
    return service.peakAdminAPI
      .post("/api/Merchant/GetMerchantDetailList", merchantDetailListRequest, await SetHeaderService())
      .then((res) => {
        return res;
      });
  }
  async DoGetMerchantListForVerify(verifyMerchantRequest: MerchantDetailForVerifyMerchantRequest) {
    return service.peakAdminAPI
      .post("/api/Merchant/GetMerchantListForVerifyMerchant", verifyMerchantRequest, await SetHeaderService())
      .then((res) => {
        return res;
      });
  }
  async DoUpdateVerificationStatusMerchant(updateVerificationStatus: UpdateVerificationStatusMerchantRequest) {
    return service.peakAdminAPI
      .post("/api/Merchant/UpdateVerificationStatusMerchant", updateVerificationStatus, await SetHeaderService())
      .then((res) => {
        return res;
      });
  }
  async DoGetMerchantListBySuperUserId(superUserId: number) {
    return service.peakAdminAPI
      .post("/api/Merchant/GetMerchantListByIsSuperUserId", superUserId, await SetHeaderService())
      .then((res) => {
        return res;
      });
  }
  async DoSwapMerchantPackage(swapMerchantPeakPackageRequest: SwapMerchantPeakPackageRequestModel) {
    return service.peakAdminAPI
      .post("/api/Merchant/SwapMerchantPeakPackage", swapMerchantPeakPackageRequest, await SetHeaderService())
      .then((res) => {
        return res;
      });
  }
  async DoTransferPackage(transferPeakPackageRequestModel: TransferPeakPackageRequestModel) {
    return service.peakAdminAPI
      .post("/api/Merchant/TranferMerchantPeakPackage", transferPeakPackageRequestModel, await SetHeaderService())
      .then((res) => {
        return res;
      });
  }
  async DoCancelPeakPackage(cancelPeakPaymentConnectByIdRequest: CancelPeakPaymentConnectByIdRequestModel) {
    return service.peakAdminAPI
      .post("/api/Merchant/CancelPeakPaymentConnectById", cancelPeakPaymentConnectByIdRequest, await SetHeaderService())
      .then((res) => {
        return res;
      });
  }
  async DoApproveUpgradePackage(approvePackageRequestModel: ApprovePackageRequestModel) {
    return service.peakAdminAPI
      .post("/api/Merchant/ApproveUpgradePackage", approvePackageRequestModel, await SetHeaderService())
      .then((res) => {
        return res;
      });
  }
  async DoGetConvertMerchantPackageType(convertMerchantPackage: any) {
    return service.peakAdminAPI
      .post("/api/Merchant/GetConvertMerchantPackageType", convertMerchantPackage, await SetHeaderService())
      .then((res) => {
        return res;
      });
  }

  async DoConvertMerchantToAFP(convertMerchantPackage: any) {
    return service.peakAdminAPI
      .post("/api/Merchant/ConvertMerchantToAFP", convertMerchantPackage, await SetHeaderService())
      .then((res) => {
        return res;
      });
  }
}

export default new MerchantService();
