import { LatestUpdateTableViewModel } from "@/model/latestUpdate/latestUpdateModel";

export function ConvertToLatestUpdateTableRequest(latestUpdateTableViewModel: LatestUpdateTableViewModel) {
  const bannerTableRequest: any = {
    code: latestUpdateTableViewModel.code,
    name: latestUpdateTableViewModel.name,
    pageNumber: latestUpdateTableViewModel.pageNo ?? 1,
    pageSize: latestUpdateTableViewModel.pageSize ?? 10,
    startDatetime: latestUpdateTableViewModel.fromDate,
    endDatetime: latestUpdateTableViewModel.toDate,
    bannerTabStatus: latestUpdateTableViewModel.bannerTabStatus,
  };
  return bannerTableRequest;
}

export function ConvertToLatestUpdateTableModelList(data: any) {
  const bannerTable: any = {
    tableList: [],
  };
  if (data && data.bannerTableDetails && data.bannerTableDetails.length > 0) {
    data.bannerTableDetails.forEach((tableItem: any) => {
      const bannerTableModel: any = {
        order: tableItem.sort,
        code: tableItem.code,
        name: tableItem.name,
        startDatetime: tableItem.startDatetime.ConvertToDateFormat(),
        endDatetime: tableItem.endDatetime.ConvertToDateFormat(),
        createDate: tableItem.startDatetime.ConvertToDateFormat(),
        createUser: tableItem.createBanner.name,
        status: tableItem.bannerStatus,
        audience: tableItem.audience,
      };
      bannerTable.tableList.push(bannerTableModel);
    });
  }
  return bannerTable;
}
