import { EnvironmentType } from "../enums/enums";

export function NewObject(obj: any) {
  if (obj == "") {
    return "";
  } else {
    return JSON.parse(JSON.stringify(obj));
  }
}

export function GetDateLengthDiffer(firstDate: string, secondDate: string): number {
  const date1 = new Date(firstDate);
  const date2 = new Date(secondDate);

  const differenceInDays = Math.round((date1.getTime() - date2.getTime()) / (1000 * 36 * 24));
  return differenceInDays;
}

export function CheckIsExactDevelop() {
  const VUE_APP_TYPE_ENV: EnvironmentType = Number(process.env.VUE_APP_TYPE_ENV);
  return VUE_APP_TYPE_ENV == EnvironmentType.Dev;
}

function DetectTabClosing(e: any) {
  e.preventDefault();
  const confirmationMessage = "";

  (e || window.event).returnValue = confirmationMessage;
  return confirmationMessage;
}

export function AddDetectTabClosing() {
  window.addEventListener("beforeunload", DetectTabClosing);
}
export function RemoveDetectTabClosing() {
  window.removeEventListener("beforeunload", DetectTabClosing);
}
