import {
  GetApprovePromoRequestStatusText,
  GetCustomerTypeName,
  GetIsCommercial,
  SetCampaignConditionList,
  SetPromoCampaignPrivilegeText,
  SetPromoCampaignTypeName,
} from "@/helper/promoCodeHelper";
import {
  PromoCampaignModel,
  PromoCodeModel,
  PromoCodeRequestModel,
  PromoRequestDetail,
} from "@/model/promoCode/promoCodeModel";

export function ConvertToPromoCampaignList(promoCampaignList: Array<PromoCampaignModel>) {
  const promoCampaignListModel: Array<any> = [];

  if (promoCampaignList && promoCampaignList.length > 0) {
    promoCampaignList.forEach((promoCampaign) => {
      promoCampaignListModel.push({
        id: promoCampaign.id,
        name: promoCampaign.name ? promoCampaign.name : "-",
        description: promoCampaign.description ? promoCampaign.description : "-",
        reference: promoCampaign.reference ? promoCampaign.reference : "-",
        refOne: promoCampaign.refOne ? GetCustomerTypeName(promoCampaign.refOne) : "-",
        refTwo: promoCampaign.refTwo ? GetIsCommercial(promoCampaign.refTwo) : "-",
        isUnique: promoCampaign.isUnique,
        isUniqueUser: promoCampaign.isUniqueUser,
        isUnpaidUser: promoCampaign.isUnpaidUser,
        isRecurring: promoCampaign.isRecurring,
        discountNumber: promoCampaign.discountNumber,
        discountPercent: promoCampaign.discountPercent,
        packageId: promoCampaign.packageId,
        packagePrice: promoCampaign.packagePrice,
        packagePeriod: promoCampaign.packagePeriod,
        packagePeriodDay: promoCampaign.packagePeriodDay,
        packageExpiredDate: promoCampaign.packageExpiredDate,
        packagePeriodExtra: promoCampaign.packagePeriodExtra,
        type: SetPromoCampaignTypeName(promoCampaign.type),
        privilege: SetPromoCampaignPrivilegeText(promoCampaign),
        conditionList: SetCampaignConditionList(promoCampaign),
      });
    });
    promoCampaignListModel.sort((a: any, b: any) => b.id - a.id);
  }

  return promoCampaignListModel;
}

export function ConvertToPromoCodeList(promoCodeList: Array<PromoCodeModel>, promoCampaignList: Array<any> | null) {
  const promoCodeListModel: Array<any> = [];

  if (promoCodeList && promoCodeList.length > 0) {
    promoCodeList.forEach((promoCode) => {
      promoCodeListModel.push({
        id: promoCode.id,
        code: promoCode.code,
        campaignId: promoCode.campaignId,
        createDateTime: promoCode.createDateTime,
        expireDateTime: promoCode.expireDateTime,
        startDateTime: promoCode.startDateTime,
        quota: promoCode.quota,
        usedQuota: promoCode.usedQuota,
        fixUserId: promoCode.fixUserId ? promoCode.fixUserId : "-",
        fixMerchantId: promoCode.fixMerchantId ? promoCode.fixMerchantId : "-",
        fixFirmId: promoCode.fixFirmId ? promoCode.fixFirmId : "-",
        fixClientId: promoCode.fixClientId ? promoCode.fixClientId : "-",
        registerType: promoCode.registerType,
        status: `Code ทั้งหมด ${promoCode.quota} คงเหลือ ${promoCode.quota - promoCode.usedQuota}`,
        campaignName: "",
      });
    });
  }

  return promoCodeListModel;
}

export function ConvertToPromoRequestTableList(dataTableList: Array<PromoRequestDetail> | null) {
  const promoRequestTableListModel: Array<any> = [];

  if (dataTableList && dataTableList.length > 0) {
    dataTableList.forEach((data) => {
      promoRequestTableListModel.push({
        requestId: data.requestId,
        requestNo: data.requestNumber,
        requestDateTime: new Date(data.requestDateTime),
        asanaTask: data.asanaTask,
        approveDateTime: data.approveDateTime,
        approveUser: data.approveUser ? data.approveUser : "-",
        status: GetApprovePromoRequestStatusText(data.status),
        quotationNumber: data.referenceQuotation?.transactionCode ? data.referenceQuotation.transactionCode : "-",
        totalAmount: data.totalAmount,
        requestUser: data.requestUser,
        invoiceNumber: data.referenceInvoice?.transactionCode ? data.referenceInvoice.transactionCode : "-",
      });
    });
  }

  return promoRequestTableListModel;
}

export function ConvertToGeneratePromoCode(promoCodeRequest: PromoCodeRequestModel | null) {
  const codeList: Array<any> = [];

  if (promoCodeRequest && promoCodeRequest.code && promoCodeRequest.code.length > 0) {
    promoCodeRequest.code.forEach((data) => {
      codeList.push({
        code: data.code,
        quota: data.quota,
      });
    });
  }

  return codeList;
}
