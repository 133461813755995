





















import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import "@lottiefiles/lottie-player";

const StoreLoading = namespace("Loading");
@Component
export default class IsLoading extends Vue {
  @StoreLoading.Getter
  private getIsLoadingStatus!: boolean;

  @Watch("getIsLoadingStatus")
  getIsLoadingStatusChanged(newVal: boolean) {
    if (newVal) {
      this.toggleOverFlow("hidden");
    } else {
      this.toggleOverFlow("auto");
    }
  }

  public toggleOverFlow(overflowType: string) {
    const el = document.body;
    el.style.overflow = overflowType;
  }
}
