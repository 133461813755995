import { PeakPackage } from "@/enums/enums";

export function GetPackageName(packageId: PeakPackage) {
  let packageName = "";
  switch (packageId) {
    case PeakPackage.Trial:
      packageName = "TRIAL";
      break;
    case PeakPackage.Free:
      packageName = "FREE";
      break;
    case PeakPackage.Basic:
      packageName = "BASIC";
      break;
    case PeakPackage.Pro:
      packageName = "PRO";
      break;
    case PeakPackage.ProPlus:
      packageName = "PRO Plus";
      break;
    case PeakPackage.Premium:
        packageName = "PREMIUM";
        break;  
    case PeakPackage.AFPFree:
      packageName = "AFP FREE";
      break;
    case PeakPackage.AFPLedger:
      packageName = "LEDGER";
      break;
    case PeakPackage.AFPAccountant:
      packageName = "ACCOUNTANT";
      break;
  }
  return packageName;
}

export function GetPackageList(isNotSpecify = false) {
  let packageList = [];

  packageList = [
    { value: PeakPackage.Basic, text: GetPackageName(PeakPackage.Basic) },
    { value: PeakPackage.Pro, text: GetPackageName(PeakPackage.Pro) },
    { value: PeakPackage.ProPlus, text: GetPackageName(PeakPackage.ProPlus) },
    { value: PeakPackage.Premium, text: GetPackageName(PeakPackage.Premium) },
    { value: PeakPackage.AFPAccountant, text: GetPackageName(PeakPackage.AFPAccountant) },
  ];

  if (isNotSpecify) {
    packageList.push({ value: 0, text: "Not specify" });
  }

  return packageList;
}
