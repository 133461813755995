import { SetHeaderService } from "@/helper/merchantHelper";
import { GetContactTemplateByTaxIdRequest, UpdateLogoUrlContactTemplateRequest } from "@/model/contact/contactModel";
import service from "../baseHttpService";

class ContactService {
  async DoGetContactTemplateByTaxId(contactTemplateByTaxIdRequest: GetContactTemplateByTaxIdRequest) {
    return service.peakAdminAPI
      .post("/api/Contact/GetContactTemplateByTaxId", contactTemplateByTaxIdRequest, await SetHeaderService())
      .then((res) => {
        return res;
      });
  }
  async DoUpdateLogoUrlContactTemplate(logoUrlContactTemplateRequest: UpdateLogoUrlContactTemplateRequest) {
    return service.peakAdminAPI
      .post("/api/Contact/UpdateLogoUrlContactTemplate", logoUrlContactTemplateRequest, await SetHeaderService())
      .then((res) => {
        return res;
      });
  }
}

export default new ContactService();
