import { MerchantTableViewModel } from "@/model/merchant//merchantTableViewModel";
import Vue from "vue";
import Vuex from "vuex";
import AccountingFrim from "./modules/accountingFirm";
import ActivityLog from "./modules/activitylog";
import AppConnect from "./modules/appConnect";
import ChangeSuperuser from "./modules/changeSuperuser";
import Contact from "./modules/contact";
import LatestUpdate from "./modules/latestUpdate";
import Loading from "./modules/loading";
import Merchant from "./modules/merchant";
import MerchantDetail from "./modules/merchantDetail";
import Package from "./modules/package";
import PromoCode from "./modules/promoCode";
import PromoCodeRequest from "./modules/promoCodeRequest";
import TestModule from "./modules/test";
import Transaction from "./modules/transaction";
import User from "./modules/user";

Vue.use(Vuex);

export interface State {
  merchantTableView: MerchantTableViewModel;
}

const store = new Vuex.Store({
  modules: {
    Merchant,
    Loading,
    TestModule,
    ActivityLog,
    ChangeSuperuser,
    MerchantDetail,
    PromoCode,
    Contact,
    AccountingFrim,
    Transaction,
    User,
    AppConnect,
    LatestUpdate,
    Package,
    PromoCodeRequest,
  },
  strict: true,
});

export default store;
