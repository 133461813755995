import { LoadingStatusType } from "@/enums/enums";
import {
  ContactTemplateModel,
  GetContactTemplateByTaxIdRequest,
  UpdateLogoUrlContactTemplateRequest,
} from "@/model/contact/contactModel";
import { ConvertToContactTemplateModel } from "@/service/contact/contactFacade";
import API from "@/service/Index";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({ namespaced: true })
export default class Contact extends VuexModule {
  public contactTemplateByTaxIdLoadingStatus: LoadingStatusType = LoadingStatusType.None;
  public contactTemplateByTaxId: ContactTemplateModel | null = null;
  public updateLogoUrlContactLoadingStatus: LoadingStatusType = LoadingStatusType.None;

  get getContactTemplateByTaxIdLoadingStatus(): LoadingStatusType {
    return this.contactTemplateByTaxIdLoadingStatus;
  }

  get getContactTemplateByTaxId(): ContactTemplateModel | null {
    return this.contactTemplateByTaxId;
  }

  get getUpdateLogoUrlContactLoadingStatus(): LoadingStatusType {
    return this.updateLogoUrlContactLoadingStatus;
  }

  @Mutation
  public SetContactTemplateByTaxIdLoadingStatus(status: LoadingStatusType) {
    this.contactTemplateByTaxIdLoadingStatus = status;
  }

  @Mutation
  public SetContactTemplateByTaxId(contactTemplate: ContactTemplateModel) {
    this.contactTemplateByTaxId = contactTemplate;
  }

  @Mutation
  public ReSetContactTemplateByTaxId() {
    this.contactTemplateByTaxId = null;
  }

  @Mutation
  public SetUpdateLogoUrlContactLoadingStatus(status: LoadingStatusType) {
    this.updateLogoUrlContactLoadingStatus = status;
  }

  @Action
  public DoGetContactTemplateByTaxId(contactTemplateByTaxIdRequest: GetContactTemplateByTaxIdRequest) {
    if (this.contactTemplateByTaxIdLoadingStatus !== LoadingStatusType.Loading) {
      this.context.commit("SetContactTemplateByTaxIdLoadingStatus", LoadingStatusType.Loading);
      this.context.dispatch("Loading/IsLoading", true, { root: true });
      API.ContactService.DoGetContactTemplateByTaxId(contactTemplateByTaxIdRequest).then((res) => {
        if (res.data.responseCode.IsSuccess()) {
          const contactTemplateModel = ConvertToContactTemplateModel(res.data.contactTemplateModel);
          this.context.commit("SetContactTemplateByTaxId", contactTemplateModel);
        }
        this.context.commit("SetContactTemplateByTaxIdLoadingStatus", LoadingStatusType.Success);
        this.context.dispatch("Loading/IsLoading", false, { root: true });
      });
    }
  }

  @Action
  public DoUpdateLogoUrlContactTemplate(logoUrlContactTemplateRequest: UpdateLogoUrlContactTemplateRequest) {
    if (this.updateLogoUrlContactLoadingStatus !== LoadingStatusType.Loading) {
      this.context.commit("SetUpdateLogoUrlContactLoadingStatus", LoadingStatusType.Loading);
      this.context.dispatch("Loading/IsLoading", true, { root: true });
      API.ContactService.DoUpdateLogoUrlContactTemplate(logoUrlContactTemplateRequest).then((res) => {
        let message = "";
        if (res.data.responseCode.IsSuccess()) {
          message = "Logo has been updated successfully.";
        } else {
          message = "Logo has been updated failed.";
        }
        alert(message);
        this.context.commit("SetUpdateLogoUrlContactLoadingStatus", LoadingStatusType.Success);
        this.context.dispatch("Loading/IsLoading", false, { root: true });
      });
    }
  }
}
