import { LoadingStatusType } from "@/enums/enums";
import AuthService from "@/service/authService";
import API from "@/service/Index";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import fileDownload from "js-file-download";

const auth = new AuthService();

@Module({ namespaced: true })
export default class Transaction extends VuexModule {
  public duplicateDocumentLoadingStatus: LoadingStatusType = LoadingStatusType.None;

  get getDuplicateDocumentLoadingStatus(): LoadingStatusType {
    return this.duplicateDocumentLoadingStatus;
  }

  @Mutation
  public SetDuplicateDocumentLoadingStatus(status: LoadingStatusType) {
    this.duplicateDocumentLoadingStatus = status;
  }

  @Action
  public async DoGetDuplicateDocumentNumber(input: any) {
    if (this.duplicateDocumentLoadingStatus !== LoadingStatusType.Loading) {
      this.context.commit("SetDuplicateDocumentLoadingStatus", LoadingStatusType.Loading);
      this.context.dispatch("Loading/IsLoading", true, { root: true });
      API.TransactionService.GetDuplicateDocumentNumber(input).then((res) => {
        let message = "";
        if (res.status.IsSuccess()) {
          if (res.data) {
            message = "ดาวน์โหลดไฟล์เลขที่เอกสารซ้ำสำเร็จ";
            fileDownload(res.data, "duplicateTransactionNumber.csv");
          } else {
            message = "ไม่มีเลขที่เอกสารซ้ำ";
          }
        } else {
          message = "ดาวน์โหลดไฟล์เลขที่เอกสารซ้ำไม่สำเร็จ";
        }
        alert(message);
        this.context.commit("SetDuplicateDocumentLoadingStatus", LoadingStatusType.Success);
        this.context.dispatch("Loading/IsLoading", false, { root: true });
      });
    }
  }

  @Action
  public async DoGetDuplicateDocumentData(input: any) {
    if (this.duplicateDocumentLoadingStatus !== LoadingStatusType.Loading) {
      this.context.commit("SetDuplicateDocumentLoadingStatus", LoadingStatusType.Loading);
      this.context.dispatch("Loading/IsLoading", true, { root: true });
      API.TransactionService.GetDuplicateDocumentData(input).then((res) => {
        let message = "";
        if (res.status.IsSuccess()) {
          if (res.data) {
            message = "ดาวน์โหลดไฟล์ข้อมูลเอกสารซ้ำสำเร็จ";
            fileDownload(res.data, "duplicateTransactionData.csv");
          } else {
            message = "ไม่มีข้อมูลเอกสารซ้ำ";
          }
        } else {
          message = "ดาวน์โหลดไฟล์ข้อมูลเอกสารซ้ำไม่สำเร็จ";
        }
        alert(message);
        this.context.commit("SetDuplicateDocumentLoadingStatus", LoadingStatusType.Success);
        this.context.dispatch("Loading/IsLoading", false, { root: true });
      });
    }
  }
}
