import { LoadingStatusType } from "@/enums/enums";
import { AddPeakSupportRequest, DeletePeakSupportRequest, UserTable } from "@/model/user/userDetailModel";
import {
  ConvertToUpdateSuperUserRequestModel,
  ConvertToUserModelList,
} from "@/service/changeSuperuser/changeSuperuserFacade";
import API from "@/service/Index";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({ namespaced: true })
export default class ActivityLog extends VuexModule {
  public userTableLoadingStatus: LoadingStatusType = LoadingStatusType.None;
  public userTableList: Array<UserTable> | null = null;
  public updateSuperUserLoadingStatus: LoadingStatusType = LoadingStatusType.None;
  public countItem: number | null = 0;
  public addPeakSupportLoadingStatus: LoadingStatusType = LoadingStatusType.None;
  public deletePeakSupportLoadingStatus: LoadingStatusType = LoadingStatusType.None;

  get getUserTableLoadingStatus(): LoadingStatusType {
    return this.userTableLoadingStatus;
  }
  get getUserDetailListModel(): Array<UserTable> | null {
    return this.userTableList;
  }
  get getUpdateSuperUserLoadingStatus(): LoadingStatusType {
    return this.updateSuperUserLoadingStatus;
  }
  get getCountItemTable(): number | null {
    return this.countItem;
  }
  get getAddPeakSupportLoadingStatus(): LoadingStatusType {
    return this.addPeakSupportLoadingStatus;
  }
  get getDeletePeakSupportLoadingStatus(): LoadingStatusType {
    return this.deletePeakSupportLoadingStatus;
  }

  @Mutation
  public MutationUserTableLoadingStatus(status: LoadingStatusType) {
    this.userTableLoadingStatus = status;
  }
  @Mutation
  public MutationGetUserDetailListModel(input: any) {
    this.userTableList = input.table;
    this.countItem = input.count;
  }
  @Mutation
  public MutationUpdateSuperUserLoadingStatus(status: LoadingStatusType) {
    this.updateSuperUserLoadingStatus = status;
  }
  @Mutation
  public MutationAddPeakSupportLoadingStatus(status: LoadingStatusType) {
    this.addPeakSupportLoadingStatus = status;
  }
  @Mutation
  public MutationDeletePeakSupportLoadingStatus(status: LoadingStatusType) {
    this.deletePeakSupportLoadingStatus = status;
  }

  @Action
  public DoGetUserTable(activityLogTableViewModel: any) {
    if (this.userTableLoadingStatus !== LoadingStatusType.Loading) {
      this.context.commit("MutationUserTableLoadingStatus", LoadingStatusType.Loading);
      API.ChangeSuperuserService.DoGetUserTable(activityLogTableViewModel).then((res) => {
        if (res.status.IsSuccess()) {
          const table = ConvertToUserModelList(res.data.userTable);
          const alluserCount = res.data.userTable.alluserCount;
          const input = {
            table: table,
            count: alluserCount,
          };
          this.context.commit("MutationGetUserDetailListModel", input);
          this.context.commit("MutationUserTableLoadingStatus", LoadingStatusType.Success);
        }
      });
    }
  }
  @Action
  public async DoUpdateSuperUser(input: any) {
    if (this.updateSuperUserLoadingStatus !== LoadingStatusType.Loading) {
      this.context.commit("MutationUpdateSuperUserLoadingStatus", LoadingStatusType.Loading);
      const merchantId = input.merchantId;
      const oldSuperUser = input.oldSuperUser;
      const newSuperUser = input.newSuperUser;
      const merchantActionOption = input.merchantActionOption;
      const convertToUpdateSuperUserRequestModel = ConvertToUpdateSuperUserRequestModel(input);
      API.ChangeSuperuserService.DoUpdateSuperUser(convertToUpdateSuperUserRequestModel).then((res) => {
        if (res.data.responseCode.IsSuccess()) {
          this.context.commit("MutationUpdateSuperUserLoadingStatus", LoadingStatusType.Success);
        } else {
          let message = "";
          if (res.data.exception != null) {
            message = res.data.exception.code.GetAdminExceptionResponseMessage();
            alert(message);
          }
          this.context.commit("MutationUpdateSuperUserLoadingStatus", LoadingStatusType.None);
        }
      });
    }
  }
  @Action
  public async DoAddPeakSupport(input: AddPeakSupportRequest) {
    this.context.commit("MutationAddPeakSupportLoadingStatus", LoadingStatusType.Loading);
    API.ChangeSuperuserService.DoAddPeakSupport(input).then((res) => {
      if (res.data.responseCode.IsSuccess()) {
        this.context.commit("MutationAddPeakSupportLoadingStatus", LoadingStatusType.Success);
        alert("เพิ่ม PeakSupport สำเร็จ");
      } else {
        let message = "";
        if (res.data.exception != null) {
          message = res.data.exception.code.GetAdminExceptionResponseMessage();
          alert(message);
        }
        this.context.commit("MutationAddPeakSupportLoadingStatus", LoadingStatusType.None);
      }
    });
  }
  @Action
  public async DoDeletePeakSupport(input: DeletePeakSupportRequest) {
    this.context.commit("MutationDeletePeakSupportLoadingStatus", LoadingStatusType.Loading);
    API.ChangeSuperuserService.DoDeletePeakSupport(input).then((res) => {
      if (res.data.responseCode.IsSuccess()) {
        this.context.commit("MutationDeletePeakSupportLoadingStatus", LoadingStatusType.Success);
        alert("ลบผู้ใช้งานสำเร็จ");
      } else {
        let message = "";
        if (res.data.exception != null) {
          message = res.data.exception.code.GetAdminExceptionResponseMessage();
          alert(message);
        }
        this.context.commit("MutationDeletePeakSupportLoadingStatus", LoadingStatusType.None);
      }
    });
  }
}
