import { LoadingStatusType } from "@/enums/enums";
import AuthService from "@/service/authService";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import API from "@/service/Index";
import { ConvertToConnectionConfig } from "@/service/appConnect/appConnectFacade";
import { ConnectionConfigModel } from "@/model/appConnect/appConnectModel";

@Module({ namespaced: true })
export default class AppConnect extends VuexModule {
  private connectionConfig: ConnectionConfigModel | null = null;
  private connectionConfigLoadingStatus: LoadingStatusType = LoadingStatusType.None;
  private updateConnectionConfigLoadingStatus: LoadingStatusType = LoadingStatusType.None;

  get getConnectionConfig(): ConnectionConfigModel | null {
    return this.connectionConfig;
  }

  get getConnectionConfigLoadingStatus(): LoadingStatusType {
    return this.connectionConfigLoadingStatus;
  }

  get getUpdateConnectionConfigLoadingStatus(): LoadingStatusType {
    return this.updateConnectionConfigLoadingStatus;
  }

  @Mutation
  public SetConnectionConfig(connectionConfig: any) {
    this.connectionConfig = connectionConfig;
  }

  @Mutation
  public SetConnectionConfigLoadingStatus(status: LoadingStatusType) {
    this.connectionConfigLoadingStatus = status;
  }

  @Mutation
  public SetUpdateConnectionConfigLoadingStatus(status: LoadingStatusType) {
    this.updateConnectionConfigLoadingStatus = status;
  }

  @Action
  public DoGetConnectionConfig(merchantId: number) {
    if (this.connectionConfigLoadingStatus !== LoadingStatusType.Loading) {
      this.context.commit("SetConnectionConfigLoadingStatus", LoadingStatusType.Loading);
      API.AppConnectService.DoGetConnectionConfig(merchantId).then((res) => {
        if (res.status.IsSuccess()) {
          const connectionConfigModel = ConvertToConnectionConfig(res.data);
          this.context.commit("SetConnectionConfig", connectionConfigModel);
          this.context.commit("SetConnectionConfigLoadingStatus", LoadingStatusType.Success);
        }
      });
    }
  }
  @Action
  public DoUpdateConnectionConfig(connectionConfig: ConnectionConfigModel) {
    if (this.connectionConfigLoadingStatus !== LoadingStatusType.Loading) {
      this.context.commit("SetUpdateConnectionConfigLoadingStatus", LoadingStatusType.Loading);
      API.AppConnectService.DoUpdateConnectionConfig(connectionConfig).then((res) => {
        let message = "";
        if (res.status.IsSuccess()) {
          message = "Connection has been updated successfully.";
          this.context.commit("SetUpdateConnectionConfigLoadingStatus", LoadingStatusType.Success);
        } else {
          message = "Connection has been updated successfully.";
          this.context.commit("SetUpdateConnectionConfigLoadingStatus", LoadingStatusType.Fail);
        }
        alert(message);
      });
    }
  }
}
