import { LoadingStatusType } from "@/enums/enums";
import API from "@/service/Index";
import { ConvertToAccountingFirmTableModelList } from "@/service/accountingFirm/accountingFirmFacade";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({ namespaced: true })
export default class AccountingFrim extends VuexModule {
  public insertAccountingFirmLoadingStatus: LoadingStatusType = LoadingStatusType.None;
  public accountingFirmInternalCodeLoadingStatus: LoadingStatusType = LoadingStatusType.None;
  public accountingFirmInternalCode: any = "";
  public accountingFirmsLoadingStatus: LoadingStatusType = LoadingStatusType.None;
  public accountingFirms: any = "";
  public accountingFirmsTableLoadingStatus: LoadingStatusType = LoadingStatusType.None;
  public accountingFirmsTable: any = "";
  public countItem: number | null = 0;
  public deleteAccountingFirmsLoadingStatus: LoadingStatusType = LoadingStatusType.None;
  public accountingFirmsDetail: any = null;
  public accountingFirmsDetailLoadingStatus: LoadingStatusType = LoadingStatusType.None;
  public updateAccountingFirmsLoadingStatus: LoadingStatusType = LoadingStatusType.None;

  get getAccountingFirmInternalCodeLoadingStatus(): LoadingStatusType {
    return this.accountingFirmInternalCodeLoadingStatus;
  }
  get getAccountingFirmsLoadingStatus(): LoadingStatusType {
    return this.accountingFirmsLoadingStatus;
  }
  get getAccountingFirmInternalCode(): any {
    return this.accountingFirmInternalCode;
  }
  get getAccountingFirms(): any {
    return this.accountingFirms;
  }
  get getAccountingFirmsTableLoadingStatus(): LoadingStatusType {
    return this.accountingFirmsTableLoadingStatus;
  }
  get getAccountingFirmTable(): any {
    return this.accountingFirmsTable;
  }
  get getCountItemTable(): number | null {
    return this.countItem;
  }
  get getDeleteAccountingFirmsLoadingStatus(): LoadingStatusType {
    return this.deleteAccountingFirmsLoadingStatus;
  }
  get getAccountingFirmsDetail(): any {
    return this.accountingFirmsDetail;
  }
  get getAccountingFirmsDetailLoadingStatus(): LoadingStatusType {
    return this.accountingFirmsDetailLoadingStatus;
  }
  get getUpdateAccountingFirmsLoadingStatus(): LoadingStatusType {
    return this.updateAccountingFirmsLoadingStatus;
  }
  get getInsertAccountingFirmLoadingStatus(): LoadingStatusType {
    return this.insertAccountingFirmLoadingStatus;
  }

  @Mutation
  public MutationAccountingFirmInternalCodeLoadingStatus(status: LoadingStatusType) {
    this.accountingFirmInternalCodeLoadingStatus = status;
  }
  @Mutation
  public MutationAccountingFirmsLoadingStatus(status: LoadingStatusType) {
    this.accountingFirmsLoadingStatus = status;
  }
  @Mutation
  public MutationGetAccountingFirmInternalCode(InternalCode: any) {
    this.accountingFirmInternalCode = InternalCode;
  }
  @Mutation
  public MutationGetAccountingFirms(AccountingFirms: any) {
    this.accountingFirms = AccountingFirms;
  }
  @Mutation
  public MutationInsertAccountingFirmLoadingStatus(status: LoadingStatusType) {
    this.insertAccountingFirmLoadingStatus = status;
  }
  @Mutation
  public MutationGetAccountingFirmTable(input: any) {
    this.countItem = input.count;
    this.accountingFirmsTable = input.table.tableList;
  }
  @Mutation
  public MutationGetAccountingFirmsTableLoadingStatus(status: LoadingStatusType) {
    this.accountingFirmsTableLoadingStatus = status;
  }
  @Mutation
  public MutationGetDeleteAccountingFirmsLoadingStatus(status: LoadingStatusType) {
    this.deleteAccountingFirmsLoadingStatus = status;
  }
  @Mutation
  public MutationGetAccountingFirmsDetail(AccountingFirms: any) {
    this.accountingFirmsDetail = AccountingFirms;
  }
  @Mutation
  public MutationGetAccountingFirmsDetailLoadingStatus(status: LoadingStatusType) {
    this.accountingFirmsDetailLoadingStatus = status;
  }
  @Mutation
  public MutationGetUpdateAccountingFirmsLoadingStatus(status: LoadingStatusType) {
    this.updateAccountingFirmsLoadingStatus = status;
  }

  @Action
  public DoGetAccountingFirmInternalCode() {
    if (this.accountingFirmInternalCodeLoadingStatus !== LoadingStatusType.Loading) {
      this.context.commit("MutationAccountingFirmInternalCodeLoadingStatus", LoadingStatusType.Loading);
      API.AccountingFirmService.DoGetAccountingFirmInternalCode().then((res: any) => {
        if (res.status.IsSuccess()) {
          const InternalCode = res.data.accountingFirmInternalCode;
          this.context.commit("MutationGetAccountingFirmInternalCode", InternalCode);
          this.context.commit("MutationAccountingFirmInternalCodeLoadingStatus", LoadingStatusType.Success);
        }
      });
    }
  }
  @Action
  public DoGetAccountingFirms() {
    if (this.accountingFirmsLoadingStatus !== LoadingStatusType.Loading) {
      this.context.commit("MutationAccountingFirmsLoadingStatus", LoadingStatusType.Loading);
      API.AccountingFirmService.DoGetAccountingFirms().then((res: any) => {
        if (res.status.IsSuccess()) {
          const AccountingFirms = res.data.accountFirmDetails;
          this.context.commit("MutationGetAccountingFirms", AccountingFirms);
          this.context.commit("MutationAccountingFirmsLoadingStatus", LoadingStatusType.Success);
        }
      });
    }
  }
  @Action
  public async DoInsertAccountingFirm(input: any) {
    if (this.insertAccountingFirmLoadingStatus !== LoadingStatusType.Loading) {
      this.context.commit("MutationInsertAccountingFirmLoadingStatus", LoadingStatusType.Loading);
      API.AccountingFirmService.DoInsertAccountingFirm(input).then((res) => {
        if (res.data.responseCode.IsSuccess()) {
          this.context.commit("MutationGetAccountingFirmsDetail", res.data.accountingFirmDetail);
          this.context.commit("MutationInsertAccountingFirmLoadingStatus", LoadingStatusType.Success);
        } else {
          let message = "";
          if (res.data.exception != null) {
            message = res.data.exception.code.GetAdminExceptionResponseMessage();
            alert(message);
          }
          this.context.commit("MutationInsertAccountingFirmLoadingStatus", LoadingStatusType.None);
        }
      });
    }
  }

  @Action
  public DoGetAccountingFirmTable(_input: any) {
    if (this.accountingFirmsTableLoadingStatus !== LoadingStatusType.Loading) {
      this.context.commit("MutationGetAccountingFirmsTableLoadingStatus", LoadingStatusType.Loading);
      API.AccountingFirmService.DoGetAccountingFirmTable(_input).then((res) => {
        if (res.status.IsSuccess()) {
          const table = ConvertToAccountingFirmTableModelList(res.data.accountingFirmTable);
          const countItem = res.data.accountingFirmTable.count;
          const input = {
            tableViewModel: _input,
            table: table,
            count: countItem,
          };
          this.context.commit("MutationGetAccountingFirmTable", input);
          this.context.commit("MutationGetAccountingFirmsTableLoadingStatus", LoadingStatusType.Success);
        } else {
          let message = "";
          if (res.data.exception != null) {
            message = res.data.exception.code.GetAdminExceptionResponseMessage();
            alert(message);
          }
          this.context.commit("MutationGetAccountingFirmsTableLoadingStatus", LoadingStatusType.None);
        }
      });
    }
  }

  @Action
  public DoDeleteAccountingFirm(input: any) {
    if (this.deleteAccountingFirmsLoadingStatus !== LoadingStatusType.Loading) {
      this.context.commit("MutationGetDeleteAccountingFirmsLoadingStatus", LoadingStatusType.Loading);
      API.AccountingFirmService.DoDeleteAccountingFirm(input).then((res) => {
        if (res.data.responseCode.IsSuccess()) {
          this.context.commit("MutationGetDeleteAccountingFirmsLoadingStatus", LoadingStatusType.Success);
        } else {
          let message = "";
          if (res.data.exception != null) {
            message = res.data.exception.code.GetAdminExceptionResponseMessage();
            alert(message);
          }
          this.context.commit("MutationGetDeleteAccountingFirmsLoadingStatus", LoadingStatusType.Fail);
        }
      });
    }
  }

  @Action
  public DoGetAccountingFirmDetail(id: string) {
    if (this.accountingFirmsDetailLoadingStatus !== LoadingStatusType.Loading) {
      this.context.commit("MutationGetAccountingFirmsDetailLoadingStatus", LoadingStatusType.Loading);
      API.AccountingFirmService.DoGetAccountingFirmDetail(id).then((res) => {
        if (res.status.IsSuccess()) {
          this.context.commit("MutationGetAccountingFirmsDetail", res.data.accountFirmDetail);
          this.context.commit("MutationGetAccountingFirmsDetailLoadingStatus", LoadingStatusType.Success);
        } else {
          this.context.commit("MutationGetAccountingFirmsDetailLoadingStatus", LoadingStatusType.None);
        }
      });
    }
  }
  @Action
  public DoUpdateAccountingFirm(input: any) {
    if (this.updateAccountingFirmsLoadingStatus !== LoadingStatusType.Loading) {
      this.context.commit("MutationGetUpdateAccountingFirmsLoadingStatus", LoadingStatusType.Loading);
      API.AccountingFirmService.DoUpdateAccountingFirm(input).then((res) => {
        if (res.data.responseCode.IsSuccess()) {
          this.context.commit("MutationGetUpdateAccountingFirmsLoadingStatus", LoadingStatusType.Success);
        } else {
          let message = "";
          if (res.data.exception != null) {
            message = res.data.exception.code.GetAdminExceptionResponseMessage();
            alert(message);
          }
          this.context.commit("MutationGetUpdateAccountingFirmsLoadingStatus", LoadingStatusType.None);
        }
      });
    }
  }
}
