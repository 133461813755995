import { PeakPackage } from "@/enums/enums";
import { GetVerifiedStatus } from "@/helper/merchantHelper";
import { ApprovePackageRequestModel } from "@/model/merchant/approvePackageRequestModel";
import { CancelPeakPaymentConnectByIdRequestModel } from "@/model/merchant/cancelPeakPaymentConnectByIdRequestModel";
import { MerchantDetailModel } from "@/model/merchant/merchantDetailModel";
import { MerchantDropdownModel } from "@/model/merchant/merchantDropdownModel";
import { MerchantForVerifyList } from "@/model/merchant/merchantModel";
import { MerchantPeakPackageModel } from "@/model/merchant/merchantPeakPackageModel";
import { MerchantTableViewModel } from "@/model/merchant/merchantTableViewModel";

export function ConvertToMerchantDetailModelList(data: any) {
  const merchantTable: any = {
    tableList: [],
  };
  if (data !== null && data.length > 0) {
    data.forEach((tableItem: any) => {
      const merchantTableModel: MerchantDetailModel = {
        merchantId: tableItem.merchantId,
        merchantName: tableItem.merchantName,
        currentMainPackage: tableItem.currentMainPackage,
        currentMainPackageName:
          tableItem.currentMainPackage == PeakPackage.Free
            ? tableItem.createDateTime.Add30Days().IsExpired()
              ? tableItem.currentMainPackage.GetPeakPackageName()
              : "Trial"
            : tableItem.currentMainPackage.GetPeakPackageName(),
        oldPackage: tableItem.oldPackage.GetPeakPackageName(),
        expiredDateTime: tableItem.expiredDateTime.ConvertToDateFormat(),
        createDateTime: tableItem.createDateTime.ConvertToDateFormat(),
        peakPaymentConnectV2Id: tableItem.peakPaymentConnectV2Id,
        paymentPromoCode: tableItem.paymentPromoCode,
        paymentCampaignName: tableItem.paymentCampaignName,
        paymentGatewayType: tableItem.paymentGatewayType.GetPaymentGatewayNameByPaymentGatewayTypeId(),
        paymentUsername: tableItem.paymentUsername,
        paymentUserId: tableItem.paymentUserId,
        superUserId: tableItem.superUserId,
        paymentStatus: tableItem.paymentStatus,
        isExpired:
          (tableItem.currentMainPackage != PeakPackage.Free && tableItem.expiredDateTime.IsExpired()) ||
          (tableItem.currentMainPackage == PeakPackage.Free && tableItem.createDateTime.Add30Days().IsExpired()),
        paymentDatetime: tableItem.paymentDatetime.ConvertToDateFormat(),
        puunReceiptId: tableItem.puunReceiptId,
        whtId: tableItem.whtId,
        whtNumber: tableItem.whtNumber,
        whtReferenceTransactionNumber: tableItem.whtReferenceTransactionNumber,
        merchantCode: tableItem.merchantCode,
      };
      merchantTable.tableList.push(merchantTableModel);
    });
  }
  return merchantTable;
}

export function ConvertToMerchantListForVerify(data: any) {
  const merchantForVerifyTable: any = {
    tableList: [],
  };
  if (data && data.length > 0) {
    data.forEach((tableItem: any) => {
      const merchantTableModel: MerchantForVerifyList = {
        merchantId: tableItem.merchantId,
        merchantName: tableItem.merchantName,
        taxId: tableItem.taxId,
        branchCode: tableItem.branchCode,
        logoUrl: tableItem.logoUrl
          ? process.env.VUE_APP_PeakBlobStorage + "peakengine" + tableItem.logoUrl + "?" + new Date().getTime()
          : "",
        verifiedStatus: tableItem.verifiedStatus,
        statusText: GetVerifiedStatus(tableItem.verifiedStatus).status,
        statusColor: GetVerifiedStatus(tableItem.verifiedStatus).color,
      };
      merchantForVerifyTable.tableList.push(merchantTableModel);
    });
  }
  return merchantForVerifyTable;
}

export function ConvertToMerchantDetailListRequest(merchantTableViewModel: MerchantTableViewModel) {
  const merchantDetailListRequest: any = {
    merchantId: merchantTableViewModel.searchText?.IsNumber() ? merchantTableViewModel.searchText.ToNumber() : 0,
    merchantName: !merchantTableViewModel.searchText?.IsNumber() ? merchantTableViewModel.searchText : "",
    merchantCode: !merchantTableViewModel.codeSearch?.IsNumber() ? merchantTableViewModel.codeSearch : "",
    pageSize: merchantTableViewModel.pageSize,
    pageNo: merchantTableViewModel.pageNo,
    promoCode: merchantTableViewModel.promotionCodeSearch ?? "",
  };
  return merchantDetailListRequest;
}

export function ConvertToMerchantDropdownModelList(data: any) {
  const merchantDropdownModelList: Array<MerchantDropdownModel> = [];
  if (data !== null && data.length > 0) {
    data.forEach((item: any) => {
      const merchantDropdownModel: MerchantDropdownModel = {
        merchantId: item.merchantId,
        merchantName: item.merchantName,
        textDisplay:
          item.merchantId + " - " + item.merchantName + " - " + item.currentMainPackage?.GetPeakPackageName(),
        currentMainPackage: item.currentMainPackage?.GetPeakPackageName(),
        oldPackage: item.oldPackage?.GetPeakPackageName(),
        expiredDateTime: item.expiredDateTime?.ConvertToDateFormat(),
        createDateTime: item.createDateTime?.ConvertToDateFormat(),
        peakPaymentConnectV2Id: item.peakPaymentConnectV2Id,
      };
      merchantDropdownModelList.push(merchantDropdownModel);
    });
  }
  return merchantDropdownModelList;
}

export function ConvertToMerchantPeakPackageModel(data: any) {
  const merchantPeakPackageModel: MerchantPeakPackageModel = {
    merchantId: data.merchantId,
    peakPaymentConnectV2Id: data.peakPaymentConnectV2Id,
  };
  return merchantPeakPackageModel;
}

export function ConvertToCancelPeakPaymentConnectByIdRequestModel(data: any) {
  const merchantActionOption = { asanaNumber: data.asana, remark: data.remark };
  const cancelPeakPaymentConnectByIdRequestModel: CancelPeakPaymentConnectByIdRequestModel = {
    merchantId: data.merchantId,
    peakPaymentConnectV2Id: data.peakPaymentConnectV2Id,
    merchantActionOption: merchantActionOption,
  };
  return cancelPeakPaymentConnectByIdRequestModel;
}
export function ConvertToApprovePackageRequestModel(data: any) {
  const approvePackageRequestModel: ApprovePackageRequestModel = {
    merchantId: data.merchantId,
    peakPaymentConnectV2Id: data.peakPaymentConnectV2Id,
  };
  return approvePackageRequestModel;
}
